.partners-page {
	overflow: hidden;
	.map {
		display: table;
		width: 100%;
		padding: 140px 0 40px 0;
		background: #fff;
		position: relative;
		z-index: 80;
		overflow-x: hidden!important;
		@include breakpoint($bp-bobby) {
			padding: 43px 0 190px 0;
		}
		.container {
			max-width: calc(1110px + 160px);
			width: 100%;
			z-index: 2;
			position: relative;
		}
		.home-map {
			.wrapper {
				width: 100%;
				padding-top: 20px;
				max-width: 480px;
				position: relative;
				z-index: 6;
				@include breakpoint($bp-bobby) {
					padding-top: 275px;
				}
			}
			.innermap {
				background-image: url('img/bluemap.svg.png');
				background-size: contain;
				position: absolute;
				right: -480px;
				top: 30px;
				height: 730px;
				width: 710px;
				float: left;
				//background-image: url('img/map.svg');
				background-repeat: no-repeat!important;
				background-position: top left;
				background-size: 100%;
				@include transform(scale(0.85));
				display: none;
				@include breakpoint($bp-bobby) {
					display: block;
					width: 1122px;
				}
				@include breakpoint(1145px) {
					@include transform(scale(1));
					right: -415px;
				}
				.overlay {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 85%;
					background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
					background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
					background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
					opacity: 0.95;
					z-index: 1;
				}
			}
		}
	}
	.our-impact {
		float: left;
		width: 100%;
		padding: 85px 0;
		background-size: cover!important;
		background-position: 50% 50%!important;
		padding: $mobile-section-padding 0;
		background:$pale-grey;
		//display: table; 
		width: 100%;
		position: relative;
		overflow: hidden;
		@include breakpoint($bp-bobby) {
			padding: 130px 0 275px;
		}
		.credit {
			max-width: calc(1110px + 120px);
			width: 100%;
			left: 0;
			right: 0;
			margin: 0 auto;
			bottom: 120px;
			top: auto;
			z-index: 10;
			text-align: right;
			position: absolute;
			padding: 0 40px;
		}
		.container {
			max-width: calc(1110px + 160px);
			width: 100%;
			position: relative;
			z-index: 3;
			h4 {
				color: #fff;
				text-align: center;
				margin-bottom: 30px;
			}
			.description {
				p {
					&:last-of-type {
						margin: 0;
					}
				}
			}
		}
		.image {
			position: absolute;
			height: 120%;
			width: 985px;
			background-size: cover!important;
			background-position: -50px top;
			background-repeat: no-repeat;
			bottom: 0;
			right: auto;
			left: -340px;
			-webkit-clip-path: polygon(50% 0%, 0 100%, 100% 100%);
			clip-path: polygon(50% 0%, 0 100%, 100% 100%);
			display: none;
			@include breakpoint($bp-delta) {
				left: 50%;
			}
			@include breakpoint($bp-bobby) {
				display: block;
			}
			.overlay-n {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 60%;
				background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
				background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
				background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
				opacity: 0.65;
			}
		}
		.wrapper {
			max-width: 480px;
		}

	}
	.impact-grid {
		float: left;
		width: 100%;
		position: relative;
		z-index: 50;
		padding: 50px 0 50px;
		@include breakpoint($bp-bobby) {
			margin-top: -240px;
			padding: 0 0 0px;
		}
		.container {
			max-width: calc(1090px + 160px);
			width: 100%;
			position: relative;
			.single-impact {
				.wrapper {
					padding: 25px 30px 25px;
					@include breakpoint($bp-bobby) {
						top: 50%;
						bottom: auto;
						@include transform(translateY(-50%));
						padding: 0px;
					}
					.title {
						margin: 10px 0px 0px;
					}
				}
			}
		}
	}
	.partners-layout {
		background: #fff;
		padding: 0 0 $mobile-section-padding;
		float: left;
		width: 100%;
		@include breakpoint($bp-charlie) {
			padding: 150px 0 120px 0;
		}
		.container {
			max-width: calc(1110px + 160px);
			width: 100%;
		}
		.headline {
			margin-bottom: 55px;
			h4 {
				text-align: center;
				margin: 0;
			}
		}
		.filters {
			float: left;
			width: 100%;
			ul {
				width: 100%;
				margin: 0 auto 15px auto;
				display: table;
				padding: 0;
				text-align: center;
				@include breakpoint($bp-charlie) {
					margin: 0 auto 65px auto;
					width: 90%;
				}
				li {
					width: 100%;
					max-width: 380px;
					margin: 0 auto 0;
					float: none;
					display: inline-block;
					@include breakpoint($bp-charlie) {
						width: calc(91%/3);
						margin: 0 1%;
						float: left;	
					}
					label {
						float: left;
						width: 100%;
					}
					select {
						background: $primary;
						color: $body;
						width: 100%;
					}
				}
			}
		}
		.show-all {
			float: left; 
			width: 100%;
			list-style: none;
			overflow: hidden;
			.single-image {
				width: calc(100%/2)!important;
				height: 165px!important;
				position: relative;
				float: left;
				@include breakpoint(648px) {
					width: calc(100%/3)!important;
				}
				@include breakpoint($bp-charlie) {
					width: calc(100%/5)!important;
				}
				a {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					outline: none;
					pointer-events: all;
					z-index: 99;
				}
				img {
					max-width: 100px;
					position: absolute;
					top: 50%;
					@include transform(translateY(-50%));
					left: 0;
					right: 0;
					margin: 0 auto;
					-webkit-filter: grayscale(100%);
	    			filter: grayscale(100%);
	    			transition: transform 0.3s ease, filter 0.25s;
	    			@include breakpoint($bp-charlie) {
	    				max-width: 51%;
	    				max-height: 95px;
	    			}
				}
				&:hover {
					img {
						-webkit-filter: grayscale(0);
	    				filter: grayscale(0);
						@include transform(translateY(-50%) scale(1.1));
					}
				}
			}
		}
	}
}

.single-partners {
	.description {
		h6 {
			text-align: center;
		}
		.logo {
			text-align: center;
		}
		.bottom-button {
			margin: 50px 0;
			text-align: center;
			display: table;
			width: 100%;
			a.btn {
				display: inline-block;
				padding: 17px 30px;
				max-width: 100% !important;
				outline: none;
				min-width: 275px;
				svg {
					width: 16px;
					height: 16px;
					margin-left: 5px;
					transform: translateY(-2px);
				}
			}
		}
	}

	.commitment-types {
		margin-bottom: 110px;
		text-align: center;
		@include breakpoint($bp-charlie) {
			margin-top: 40px;
		}
		.col-md-9 {
			margin: 0 auto;
			float: none;
		}
		span {
			display: block;
			color: $charcoal;
			padding-bottom: 1em;
			font-weight: 700;
		}
		a {
			display: inline-block;
			color: $charcoal;
			border: 2px solid $primary;
			font-family: $headline-font;
			font-weight: 700;
			text-transform: uppercase;
			padding: 13px 1.5px 12px;
			min-width: 165px;
			font-size: 11px;
			line-height: 11px;
			margin-right: 5px;
			margin-bottom: 5px;

			&:hover {
				background: $primary;
			}
		}
	}
}


.modalBoxOverlay {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	background: #fff;
	position: relative;
	display: none;
	.fancybox-close-small {
		display: none!important;
		opacity: 0!important;
	} 
	.strip {
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 45px;
		background: red;
		background: url('img/tent-pattern-border-blue.png');
		background-size: cover!important;
		@include breakpoint($bp-bobby) {
			height: 77px;
		}
	}
	.close-modal {
		cursor: pointer;
		width: 30px;
		width: 30px;
		top: 20px;
		right: 20px;
		@include hover-fade();
		position: absolute;
		z-index: 9999;
		@include breakpoint($bp-bobby) {
			top: 35px;
			right: 35px;
		}
		svg {
			width: 100%;
			pointer-events: none!important;
			.st0 {
				fill: #999999;
				@include hover-fade();
				pointer-events: none!important;
			}
		}
		&:hover {
			@include transform(scale(1.15));
			svg .st0 {
				fill: $secondary;
			}
		}
	}
	.wrapper {
		max-width: 1110px;
		width: 100%;
		margin: 65px auto 0;
		padding: 0 20px 25px;
		position: relative;
		height: calc(100% - 120px);
		overflow: scroll;
		@include breakpoint($bp-bobby) {
			height: auto;
			max-width: 1110px;
			width: 100%;
			padding: 80px 40px calc(80px + 75px);
			position: relative;
			margin: 0 auto;
			overflow: visible;
		}
		&.center {
			position: absolute;
			height: calc(100% - 120px);
			margin: 65px auto 0;
			padding: 0 20px 25px;
			overflow: hidden;
			max-width: calc(1110px + 40px);
			max-height: 100%;
			overflow: scroll;
			@include breakpoint($bp-bobby) {
				top: 47%;
				padding: 0 20px;
				@include transform(translateY(-50%));
				left: 0;
				right: 0;
				margin: 0 auto;
				height: auto;
			}
		}
		&.people {
			position: absolute;
			height: calc(100% - 55px);
			padding: 65px 20px 0;
			overflow: hidden;
			max-width: calc(1110px + 40px);
			max-height: 100%;
			@include breakpoint($bp-bobby) {
				height: calc(100% - 235px);
				max-height: 620px;
				@include transform(translateY(-50%));
				top: 48%;
				left: 0;
				padding: 0 20px;
				right: 0;
				margin: 0 auto;
			}
			.image {
				float: left;
				width: 465px;
				height: 100%;
				position: relative;
				background-position: top center!important;
				background-size: cover!important;
				display: none;
				@include breakpoint($bp-bobby) {
					display: block;
				}
			}
			.p-content {
				float: right;
				width: 100%;
				height: 100%;
				overflow: scroll;
				padding-right: 20px;
				text-align: left;
				@include breakpoint($bp-bobby) {
					width: calc(100% - 465px - 50px);
				}
				.meta {
					font-size: 14px;
					font-family: $headline-font;
					color: #999999;
					letter-spacing: 0.08em;
					margin-bottom: 10px;
					font-weight: 700;
					text-transform: uppercase;
				}
				.meta, h2, p {
					text-align: left;
				}
			}
		}
		h2 {
			text-align: center;
			margin-bottom: 28px;
		}
		.joined {
			margin-bottom: 30px;
			font-size: 13px;
			font-weight: 700;
			font-family: $headline-font;
			text-align: center;
			color: #999999;
		}
		.tabs-wrapper {
			display: table;
			max-width: 850px;
			width: 100%;
			padding: 50px 25px 0;
			margin: 0 auto;
			overflow: hidden;
			.tabs-title {
				h5 {
					font-size: 16px;
					margin-bottom: 12px;
					font-weight: 600;
					letter-spacing: 0.025em;
					color: #999999;
					font-family: $primary-font;
				}
			}
		}
		ul.tabs {
			width: 100%;
			float: left;
			margin: 0 0 10px 0;
			text-align: left;
			@include breakpoint($bp-bobby) {
				margin: 0 0 10px 0;
			}
			li {
				width: auto;
				display: inline-block;
				margin: 0;
				left: 0;
				list-style: none;
				@include breakpoint($bp-bobby) {
					width: auto;
				}
			}
			li a {
				margin: 0 0 5px 0;
				padding: 0;
				width: auto;
				display: inline-block;
				font-size: 700px;
				letter-spacing: 0.08em;
				color: $charcoal;
				text-transform: uppercase;
				font-family: $headline-font;
				font-size: 11px;
				list-style: none;
				background:#fff;
				padding: 4px 18px;
				text-align: center;
				@include hover-fade();
				border: 2px solid #43fffd;
				cursor: pointer;
				z-index: 999;
				@include breakpoint($bp-bobby) {
					padding: 4px 18px;
					width: auto;
				}
				&.current {
					background: #43fffd;
					border: 2px solid $primary;
					color: $charcoal;
				}
				&:hover {
					background: $primary;
					color: $charcoal;
					border: 2px solid $primary;
				}
			}
		}
		.tabs-section {
			display: table;
			width: 100%;
			max-width: 785px;
			margin: 0 auto;
			.tab-content{
				display: none;
			}
			.tab-content.current{
				display: inherit;
			}
		}
		.description {
			p {
				text-align: center;
				color: $charcoal;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
		.meta {
			width: 100%;
			float: left;
			margin-bottom: 20px;
			text-align: center;
			@include breakpoint($bp-bobby) {
				margin-bottom: 30px;
			}
			span {
				display: inline-block;
				font-family: $headline-font;
				text-transform: uppercase;
				letter-spacing: 0.08em;
				color: $secondary;
				font-weight: 700;
				font-size: 13px;
				background: transparent!important;
				margin: 5px 10px;
				&.purple {
					color: $purple;
					background: transparent!important;
				}
			}
		}
		.bottom-button {
			margin-top: 50px;
			text-align: center;
			display: table;
			width: 100%;
			a.btn {
				display: inline-block;
				padding: 17px 30px;
				max-width: 100%!important;
				outline: none;
				color: $body;
				border: 2px solid $body;
				min-width: 275px;
				&:hover {
					background: $body;
					color: #fff;
				}

        svg {
          width: 16px;
          height: 16px;
          margin-left: 5px;
          transform: translateY(-2px);
        }
			}
		}
	}
}
