.top-introduction {
	float: left;
	width: 100%;
	background: #fff;
	margin-top: 35px;
	@include breakpoint($bp-charlie) {
		margin-top: 55px;
	}
	.col-md-8, .col-md-9 {
		float: none;
		display: table;
		margin: 0 auto;
	}
	h1, h2, h3, h4, h5 {
		text-align: center;
	}
}


.issues-page {
	float: left;
	width: 100%;
	font-size: 0px;
	.container {
		max-width: 1230px;
		width: 100%;
	}
	&.inpage {
		h3, p {
			text-align: center;
		}
		p {
			margin-bottom: 0px;
		}
	}
	&.person {
		.issues-grid {
			padding: 0px;
		}
		.single-issue {
			background: white;
			.grid-image-container {
				height: 45%;
				background: $secondary;
				.grid-image {
					opacity: 1;
				}
			}
			.wrapper {
				top: auto;
				transform: none;
				bottom: 0;
				padding: 35px;
				z-index: 99;
				h6, p {
					padding: 0px;
					text-align: left;
				}
				h6 {
					margin: 0 0 5px;
				}
				.text-container {
					display: block;
					float: left;
					@include hover-fade();
				}
			}
			&:hover {
				.grid-image-container {
					.grid-image {
						opacity: .8;
					}
				}
				.wrapper {
					.text-container {
						margin-bottom: 45px;
						&.no-social {
							margin-bottom: 0px;
						}
					}
				}
			}
			@include breakpoint($bp-charlie) {
				width: calc(50% - 3%);
				height: 350px;
				margin: 1.5%;
			}
			@include breakpoint($bp-bobby) {
				width: calc(25% - 1.5%);
				height: 380px;
				margin: .75%;
			}
		}
	}
}

.issues-grid {
	width: 100%;
	display: table;
	&.has-intro {
		padding-top: 25px;
		@include breakpoint($bp-charlie) {
			padding-top: 40px;
		}
		@include breakpoint($bp-bobby) {
			padding-top: 60px;
		}
	}
	&.has-buttons {
		padding-bottom: 20px;
		@include breakpoint($bp-charlie) {
			padding-bottom: 35px;
		}
		@include breakpoint($bp-bobby) {
			padding-bottom: 55px;
		}
	}
	@include breakpoint($bp-bobby) {
		text-align: center;
	}
	.single-issue {
		float: none;
		width: 100%;
		max-width: 385px;
		margin: 6px auto;
		background: $primary;
		border-radius: 4px;
		@include box-shadow(0px, 6px, 30px, 0px, rgba(0, 0, 0, 0.15));
		height: 275px;
		position: relative;
		@include hover-fade();
		overflow: hidden;
		@include breakpoint($bp-charlie) {
			width: calc(50% - 3%);
			height: 325px;
			float: none;
			display: inline-block;
			margin: 1.5%;
		}
		@include breakpoint($bp-bobby) {
			width: calc(33.33% - 3%);
			height: 350px;
		}
		&:hover {
			background: $secondary;
			@include transform(translateY(-6px));
			@include box-shadow(6px, 9px, 12px, rgba(0,0,0,0.12));
			.grid-image-container {
				background: $secondary;
				.grid-image {
					opacity: .1;
				}
			}
			.wrapper {
				h5, h6, p {
					color: #fff;
				}
				h5 {
					margin: 0 0 20px;
				}
				p {
					&.teaser {
						opacity: 1;
						position: relative;
						@include transform(translateY(0%));
					}
				}
				.social.links {
					@include transform(translateY(0%));
					opacity: 1;
				}
			}
		}
		a {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 99;
		}
		&.has-image {
			.wrapper {
				h5, p {
					color: white;
				}
			}
		}
		.grid-image-container {
			height: 100%;
			background: #000;
			.grid-image {
				background-size: cover !important;
				background-position: 50% 50% !important;
				height: 100%;
				width: 100%;
				opacity: .6;
				@include hover-fade();
			}
		}
		.wrapper {
			position: absolute;
			top: 50%;
			@include transform(translateY(-50%)); 
			z-index: 98;
			left: 0;
			right: 0;
			h5, h6 {
				text-align: center;
				margin: 0px;
				@include hover-fade();
			}
			h5 {
				padding: 0px 60px;
			}
			h5, p.teaser {
				color: white;
			}
			p {
				text-align: center;
				margin: 0;
				@include hover-fade();
				&.teaser {
					position: absolute;
					@include transform(translateY(20%));
					width: 100%;
					left: 0;
					right: 0;
					padding: 0px 60px;
					opacity: 0;
					line-height: 130%;
				}
			}
			.social.links {
				width: auto;
				position: absolute;
				@include transform(translateY(50%));
				opacity: 0;
				@include hover-fade();
				bottom: 35px;
				z-index: 99;
				ul {
					position: relative;
					width: 100%;
					top: 8px;
					li {
						display: inline-block;
						float: left;
						font-size: 18px;
						.fa-facebook {
							font-size: 17px;
						}
						a {
							position: relative;
							padding: 2px 7px 0px;
							color: white;
							&:hover {
								color: $body;
							}
						}
						&:first-child {
							a {
								padding-left: 0px;
							}
						}
					}
				}
			}
		}
	}
}
