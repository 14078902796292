// Settings
// ==================================================
$hamburger-padding-x                       : 0px !default;
$hamburger-padding-y                       : 0px !default;
$hamburger-layer-width                     : 33px !default;
$hamburger-layer-height                    : 2px !default;
$hamburger-layer-spacing                   : 6px !default;
$hamburger-layer-color                     : $charcoal !default;
$hamburger-layer-border-radius             : 0px !default;
$hamburger-hover-opacity                   : 1 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter    : opacity(50%) !default;

// Types (Remove or comment out what you don’t need)
// ==================================================
$hamburger-types: (
  3dx,
  3dx-r,
  3dy,
  3dy-r,
  arrow,
  arrow-r,
  arrowalt,
  arrowalt-r,
  boring,
  collapse,
  collapse-r,
  elastic,
  elastic-r,
  emphatic,
  emphatic-r,
  slider,
  slider-r,
  spring,
  spring-r,
  stand,
  stand-r,
  spin,
  spin-r,
  squeeze,
  vortex,
  vortex-r
) !default;