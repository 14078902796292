// Alert Bart
.alert-bar {
	float: left;
	width: 100%;
	padding: 9px 25px;
	background: $primary;
	position: relative;
	z-index: 9;
	@include transition(all 0.3s);
	p {
		padding: 0px;
		margin: 0px;
		text-align: center;
		color: $charcoal;
		font-family: $headline-font;
		font-weight: 600;
		line-height: 125%;
		font-size: 15px;
		span {
			text-transform: uppercase;
			margin-right: 10px;
			font-size: 15px;
		}
	}
	a {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.close-icon {
		position: absolute;
		top: 50%;
		@include transform(translateY(-50%));
		right: 15px;
		width: 21px;
		height: 21px;
		border-radius: 100%;
		background: rgba(255,255,255,0.25);
		@include hover-fade();
		svg {
			position: absolute;
			top: 5px;
			left: 5px;
			width: 11px;
			height: 11px;
			fill: #0b181e;
		}
		&:hover {
			background: rgba(#fff,1);
			svg {
				fill : $secondary;
			}
		}
	}
	&.has-link {
		&:hover {
			background: #f4a74e;
		}
		.close-icon .fa:hover {
			color: $body;
		}
	}
}

// Header
.top {
	float: left;
	width: 100%;
	position: absolute;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
    top: 0;
	z-index: 9998;
	@include breakpoint($bp-header) {
		top: 44px;
	}
}

.admin-bar {
	.top {
		top: 46px;
		@include breakpoint($bp-charlie) {
			top: 32px;
		}
	}
	&.sticky {
		.top {
			@include breakpoint($bp-charlie) {
				top: 32px;
			}
		}
	}
}

.sticky {
	.top {
		position: fixed;
		top: 0;
		box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
	}
	.hamburger {
		margin: 18px 0 0;
		.hamburger-inner{
			background-color: #000;
			&:before, &:after {
				background-color: #000;
			}
		}
	}
	header {
		padding: 8px 0;
		background: #fff;
		height: 55px;
		@include breakpoint($bp-header) {
			height: 75px;
			padding: 15px 0 15px;
		}
		.logo svg {
			margin: 0 0 0;
			width: 43px;
			@include breakpoint($bp-header) {
				margin: -8px 0 0;
				width: 46px;
			}
			.logo-text {
				opacity: 0;
				@include breakpoint($bp-header) {
					opacity: 1;
				}
			}
		}
		.control {
			margin-top: -2px;
		}
		nav.primary a {
			color: $charcoal;
			&:hover {
				fill: $primary;
			}
		}
		nav.secondary ul li a {
			background: $teal;
			color: $sec-text-color-hover;
			&:hover {
				background: $charcoal;
				color: $light-blue;
				border: 2px solid $charcoal;
			}
		}
		.search-icon {
			svg .st0 {
				fill: $charcoal;
				&:hover {
					fill: $primary;
				}
			}
		}
	}
}

.pusher {
	height: 0px;
	@include transition(all 0.3s);
}

body.search-open {
    .pusher {
        margin-top: 0px;
    }
    .top {
        top: 61px;
    }
    .top-search {
        top: 0;
    }
}

.top-search {
	height: 61px;
	background: #eee;
	padding: 5px 0;
	position: fixed;
	top: 0;
	width: 100%;
	top: -61px;
	@include transition(all 0.3s);
	z-index: 9999;
	.container {
		padding: 0;
	}
	form {
		position: relative;
	}
	.container {
		width: 100%;
		max-width: 1195px;
	}
	input[type="text"] {
		width: 100%;
		height: 52px;
		background: #eee;
		color: $body;
		font-family: $headline-font;
		font-weight: 400;
		font-size: 29px;
		letter-spacing: 0;
		opacity: 1;
		padding: 6px 60px 10px 10px;
		border: none!important;
		@include transition(all 0.3s);
		box-shadow: none!important;
		&::-webkit-input-placeholder {
		  color: $body;
		}
		&::-moz-placeholder {
		  color: $body;
		}
		&:-ms-input-placeholder {
		  color: $body;
		}
		&:-moz-placeholder { 
		  color: $body;
		}
	}
	input[type="submit"] {
		position: absolute;
		right: 22px;
		top: 11px;
		width: 30px;
		height: 30px;
		-webkit-mask: url('img/arrow.svg') no-repeat 100% 100%;
		mask: url('img/arrow.svg#mask');
		-webkit-mask-size: cover;
		mask-size: cover;
		background-color: $body;
		@include hover-fade();
		z-index: 25px;
		text-indent: -9999px;
		cursor: pointer;
		opacity: 1;
		&:hover {
			background: $secondary;
		}
	}
}

.above-header {
	background: $light-blue;
	background-size: cover!important;
	height: 44px;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 81;
	display: none;
	@include breakpoint($bp-header) {
		display: block;
	}
	.container-navigation {
		padding: 0 20px;
		width: 100%;
		max-width: 1210px;
		margin: 0 auto;
	}
	ul.menu {
		margin-top: 9px;
		float: right;
		li {
			float: left;
			list-style: none;
			font-size: 11px;
			letter-spacing: 0.08em;
			margin-right: 15px;
			text-transform: uppercase;
			font-family: $headline-font;
			font-weight: 700;
			a {
				color: #fff;
				&:hover {
					color: $secondary;
				}
			}
		}
	}
	.social {
		float: right;
		margin-right: 15px;
		margin-top: 9px;
		&.links {
			ul {
				li {
					a {
						padding: 0px 8px;
					    float: left;
					    color: $navy;
					    font-size: 15px;
					    &:hover {
					    	color: $charcoal;
					    }
					}
				}
			}
		}
	}
}

//for no toppers and templates that don't have a topper
.page-no-topper:not(.sticky), .post-type-archive-partners:not(.sticky) {
	.above-header {
		box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.25);
	}
	.hamburger {
		.hamburger-inner{
			background-color: #000;
			&:before, &:after {
				background-color: #000;
			}
		}
	}
	header {
		.logo svg {
			.st0 {
				fill: #090909;
			}
		}
		nav.primary > a {
			color: $charcoal;
			&:hover {
				fill: $primary;
			}
		}
		nav.secondary ul li a {
			color: $sec-text-color;
			&:hover {
				color: $sec-text-color-hover;
			}
		}
		.search-icon {
			svg .st0 {
				fill: $charcoal;
				&:hover {
					fill: $teal;
				}
			}
		}
	}
}

header {
	float: left;
	width: 100%;
	height: 60px;
	padding: 10px 0;
	background: $white;
	@include transition(all 0.3s ease-in-out);
	position: relative;
	//padding: 20px 0 15px; not sure why padding is uneven
	padding: 10px 0;
	opacity: 0;
	@include breakpoint($bp-header) {
		height: 85px;
		padding: 20px 0 15px;
	}
	.container-navigation {
		padding: 0 20px;
		width: 100%;
		max-width: 1210px;
		margin: 0 auto;
	}
	.search-icon {
		float: right;
		padding: 9px 10px;
		margin-left: 15px;
		margin-right: 40px;
		cursor: pointer;
		@include hover-fade();
		@include breakpoint($bp-header) {
			margin-left: 15px;
			margin-right: 0;
		}
		svg {
			width: 25px;
			height: 25px;
			.st0 {
				fill: #fff;
				@include hover-fade();
			}
		}
		&:hover {
			svg .st0 {
				fill: $primary;
			}
		}
	}
	.logo {
		img, svg {
			float: left;
			width: 43px;
			height: auto;
			//margin: 3px 0 0 0;
			//margin-top: -5px;
			@include transition(all 0.3s ease-in-out);
			@include breakpoint($bp-header) {
				width: 50px;
				margin-top: -12px;
			}
		}
		svg {
			.logo-text {
				opacity: 0;
				@include breakpoint($bp-header) {
					opacity: 1;
				}
			}
			// .st0 {
			// 	//fill: #fff;
			// }
			// .st1 {
			// 	fill: $primary;
			// }
		}
		a {
			&:hover {
				svg {
					@include transform(scale(1.1));
				}
			}
		}
	}
	.control {
		float: right;
	}
	.social {
		&.links {
			display: none;
			@include breakpoint($bp-echo) {
				display: block;
				float: right;
				margin: 6px 20px 0px 0;
				@include hover-fade();
				color: #fff;
			}
			ul li {
				i {
					font-size: 17px;
				}
				a {
					color: $socialColor;
					padding: 0 11px;
					&:hover {
						color: $socialHover;
					}
				}
			}
		}
	}
	nav {
		float: right;
		&.primary {
			a {
				color: $navColor;
				font-size: $h6;
				&:hover {
					color: $navColorHover;
				}
			}
		}
	}
}

// Navigation
nav {
	&.primary {
		display: none;

		@include breakpoint($bp-header) {
			display: block;
		}

		@include breakpoint($bp-charlie) {
			float: right;
			margin: -4px 0 0 0;
			@include hover-fade();
			ul {
				margin: 0 0 0px 0;
				display: table;
				li {
					margin: 0 15px 0 0;
					padding: 15px 8px;
					position: relative;
					font-family: $headline-font;
					font-size: 16px;
					font-weight: 700;
					text-transform: uppercase;
					letter-spacing: 0.08em;
					@include breakpoint($bp-echo) {
						padding: 15px 10px;
					}
					&.home {
						display: none;
					}
					a:hover {
						color: $navColorHover;
					}
					&.menu-item-has-children {
						&:hover {
							.sub-menu {
								display: block;
								visibility: visible;
								margin-top: 0;
								opacity: 1;
								li {
									-webkit-animation: fadeIn 0.5s ease-in-out; 
									-webkit-animation-fill-mode: both;
									animation: fadeIn 0.5s ease-in-out; 
									animation-fill-mode: both;
								}
							}
						}
					}
					&:first-child {
						border-left: 0px;
					}
					.sub-menu {
						width: 270px;
						position: absolute;
						top: 62px;
						left: 58%;
						-webkit-transform: translateX(-50%);
						-ms-transform: translateX(-50%);
						transform: translateX(-50%);
						background: $charcoal;
						padding: 25px;
						visibility: hidden;
						opacity: 0;
						margin-top: 20px;
						@include hover-fade();
						@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
						&:before {
							content: '';
							width: 0; 
							height: 0; 
							border-left: 11px solid transparent;
							border-right: 11px solid transparent;
							border-bottom: 12px solid $charcoal;
							position: absolute;
							top: -12px;
							left: 0;
							right: 0;
							margin: 0 auto;
						}
						&:after {
							content: '';
							height: 22px;
							width: 100%;
							position: absolute;
							top: -22px;
							left: 0;
							background:transparent;
						}
						li {
							width: 100%;
							margin: 0 0 8px 0;
							padding: 0;
							border: 0!important;
							line-height: 140%;
							font-family: $primary-font;
							font-size: 14px;
							letter-spacing: 0;
							text-transform: none;
							margin-bottom: 10px;
							&:last-child {
								margin-bottom: 0!important;
							}
							a {
								color: #fff;
								margin: 0;
								opacity: 1;
								font-size: 15px;
								&:hover {
									color: $pink;
								}
							}
							span {
								color: #fff;
								margin: 0;
								opacity: 1;
								font-size: 15px;
								display: block;
							}
							 &.menu-item-has-children {
								 &:hover .sub-menu-indent, &:focus .sub-menu-indent {
									display: table;
									 visibility: visible;
									 opacity: 1;
								}
							 }
						}
					}
					.sub-menu-indent {
						border-left: 2px solid #00C7C4;
						margin: 1rem 0;
						padding-left: 1rem;
						display: none;
						visibility: visible;
						opacity: 1;
						transition: .35s;
					}
				}
			}
		}
		
	}
	&.secondary {
		display: none;
		ul {
			li {
				a {
					display: block;
					float: left;
					text-align: center;
					background: transparent;
					border-radius: 50px;
					border: 2px solid $teal;
					color: $sec-text-color;
					height: 45px;
					line-height: 43px;
					width: 230px;
					font-size: 13px;
					font-weight: 700;
					font-family: $headline-font;
					text-transform: uppercase;
					letter-spacing: 0.08em;
					span {
						position: relative;
						svg {
							position: relative;
							top: -1px;
							margin-left: 7px;
							width: 10px;
							height: 10px;
						}
					}
					&:hover {
						background: $teal;
						color: $sec-text-color-hover;
					}
					@include breakpoint($bp-echo) {
						width: 260px;
					}
				}
			}
		}
		@include breakpoint($bp-header) {
			display: block;
			float: right;
			@include hover-fade();
			ul {
				li {
					&:last-child {
						margin: 0 0 0 10px;
					}
				}
			}
		}
	}
	@include breakpoint($bp-charlie) {
		ul {
			li {
				float: left;
				list-style-type: none;
			}
		}
	}
}

// Mobile Nav Icon
.hamburger {
	float: right;
	margin: 22px 0 0 0;
	z-index: 11;
	position: absolute;
	top: 0;
	right: 20px;
	z-index: 9998;
	@include hover-fade();
	@include breakpoint($bp-header) {
		margin: 32px 0 0 0;
	}
	@include breakpoint($bp-header) {
		display: none;
	}
	.hamburger-inner {
		&, &:before, &:after {
			@include hover-fade();
		}
	}
	&:hover {
		.hamburger-inner {
			background-color: $primary;
			width: 33px;
			&:after, &:before {
				background-color: $primary;
				width: 33px;
			}
		}
	}
	&.is-active {
		.hamburger-inner {
			&, &:after {
				background-color: white;
				width: 33px;
			}
		}
	}
}

// Mobile Nav
.mobile-nav {
	position: fixed;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	background: rgba($body,1);
	z-index: 10;
	height: 100%;
	display: none;
	nav {
		float: left;
		width: 100%;
		&.mobile {
			@include vertical-align();
			ul {
				margin: 0px;
				padding: 0px;
				float: left;
				width: 100%;
				li {
					float: left;
					width: 100%;
					text-transform: uppercase;
					text-align: center;
					font-size: 30px;
					margin: 0 0 10px;
				}
			}
		}
	}
}

/* Menu */
.menu-wrap {
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: -1;
	top: 0;
	right: 0;
	background: transparent;
	font-weight: 600;
	opacity: 0;
	@include hover-fade();
	-webkit-transition-timing-function: cubic-bezier(0.5,0,0.4,0.5);
	transition-timing-function: cubic-bezier(0.5,0,0.4,0.5);
	left: 100%;
	.align-middle {
		padding: 0 30px;
		width: 100%;
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.menu {
		float: left;
		width: 100%;
		overflow: hidden;
		list-style: none;
		li {
			width: 100%;
			float: left;
			text-align: center;
			font-size: 25px;
			font-weight: 600;
			font-family: $headline-font;
			margin-bottom: 0px;
			a {
				color: #fff;
				padding: 5px 0px;
				&:hover {
					color: $secondary;
				}
			}
		}
	}
	.secondary {
		margin: 20px 0 15px 0;
		float: left;
		width: 100%;
		overflow: hidden;
		list-style: none;
		display: block;
		li {
			display: table;
			margin: 0 auto 10px auto!important;
			text-align: center;
			a {
				float: none;
				display: table;
				margin: 0 auto;
				width: 100%;
				height: 52px;
				max-width: 325px;
				font-size: 18px;
			}
		}
	}
	.social-mobile {
		float: left; 
		width: auto;
		width: 100%;
		.social {
			display: table;
			margin: 0 auto;
			float: none;
			width: auto;
			ul {
				li {
					a {
						color: #999999;
						&:hover {
							color: $secondary
						}
						i {
							font-size: 32px;
						}
					}
				}
			}
		}
	}
}

.menu-top {
	line-height: 58px;
}

.menu-top .profile {
	display: inline-block;
	padding: 8px 10px;
	line-height: 42px;
}

.menu-top .profile,
.menu-side {
	width: 300px;
}

.menu-top .profile img {
	float: left;
	margin-right: 1em;
}

.icon-list { 
	display: inline-block;
	font-size: 1.25em;
}

.icon-list a {
	margin: 0 1em 0 0;
	padding: 0;
}

@media screen and (max-width: 32em) {
	.icon-list {
		padding-left: 1em;
	}
}

.menu-side a {
	display: block;
	padding: 1.2em;
	border-bottom: 1px solid rgba(0,0,0,0.1);
}

.menu-side a:first-child {
	border-top: 1px solid rgba(0,0,0,0.1);
}

.top {
	opacity: 1;
	@include transition(opacity 0.3s);
}

#container-wrap {
	float: left;
	width: 100%;
}

/* Shown menu */
.show-menu {
	.top {
		opacity: 0;
	}
	#container-wrap {
		left: -100%;
		//opacity: 0;
		-webkit-transform: translate3d(-100%,0,0);
		transform: translate3d(-100%,0,0);
		@include hover-fade();
		-webkit-transition-timing-function: cubic-bezier(0.2,0,0.3,0.4);
		transition-timing-function: cubic-bezier(0.2,0,0.3,0.4);
		
	}
	.menu-wrap {
		opacity: 1;
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
		@include hover-fade();
		-webkit-transition-timing-function: cubic-bezier(0.5,0,0.4,0.5);
		transition-timing-function: cubic-bezier(0.5,0,0.4,0.5);
		left: 0;
	}
}
