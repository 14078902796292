.become-page {
	.introduction {
		float: left;
		width: 100%;
		position: relative;
		z-index: 10;
		.container {
			max-width: calc(1090px + 160px);
			width: 100%;
		}
		.intro-text {
			max-width: 700px;
			width: 100%;
			margin: 0 auto;
			padding: 65px 0 15px;
			text-align: center;
			&.no-steps {
				padding: 65px 0 85px;
			}
			@include breakpoint($bp-bobby) {
				padding: 100px 0;
				&.no-steps {
					padding: 100px 0 120px;
				}
			}
			h3 {
				margin: 0;
				padding: 0;
				text-align: center;
				font-size: 19px;
				@include breakpoint($bp-charlie) {
					font-size: 24px;
				}
				@include breakpoint($bp-bobby) {
					font-size: 28px;
				}
			}
			hr {
				max-width: 38px;
				height: 2px;
				background: $hrColorShort;
				margin: 30px auto;
			}
			.description {
				max-width: 90%;
				margin: 0 auto;
				p {
					&:last-of-type {
						margin: 0;
					}
				}
			}
		}
		.steps {
			float: left;
			width: 100%;
			margin-bottom: 0;
			padding: 50px 0;
			@include breakpoint($bp-bobby) {
				margin-bottom: -50px;
				padding: 0 ;
			}
			.single-step {
				max-width: 450px;
				width: 100%;
				float: none;
				height: auto;
				background: $white;
				border: 15px solid $teal;
				background: $white;
				box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
				position: relative;
				margin: 0 auto 25px;
				@include breakpoint($bp-bobby) {
					width: calc(97%/3);
					height: 370px;
					max-width: 100%;
					float: left;
					display: block;
					margin: 0;
				}
				.wrapper {
					width: 100%;
					position: relative;
					margin: 0 auto;
					text-align: center;
					padding: 35px;
					@include breakpoint($bp-bobby) {
						position: absolute;
						max-width: 230px;
						width: 80%;
						top: 50%;
						@include transform(translateY(-50%));
						left: 0;
						right: 0;
						padding: 0;
					}
					.number {
						font-size: 55px;
						font-family: $headline-font;
						letter-spacing: -0.01em;
						color: $teal;
						margin: 0;
						line-height: 100%;
						font-weight: 700;
						@include breakpoint($bp-bobby) {
							font-size: 75px;
						}
					}
					.title {
						font-size: 17px;
						font-family: $headline-font;
						letter-spacing: -0.01em;
						color: $charcoal;
						margin: 15px 0 0 0;
						font-weight: 700;
						@include breakpoint($bp-bobby) {
							font-size: 20px;
						}
					}
					.description {
						font-size: 16px;
						color: $charcoal;
						margin: 15px 0 0 0;
						@include breakpoint($bp-bobby) {
							font-size: 18px;
						}
					}
				}
				&:nth-of-type(2) {
					border: 15px solid $purple;
					@include breakpoint($bp-bobby) {
						margin-top: -30px!important;
						margin: 0 1.5%;
					}
					.wrapper .number {
						color: $purple;
					}
				}
				&:nth-of-type(3) {
					border: 15px solid $orange;
					.wrapper .number {
						color: $orange;
					}
				}
			}
		}
	}
	.become-form {
		float: left;
		width: 100%;
		background-size: cover!important;
		background-position: 50% 50%!important;
		padding: 65px 0;
		position: relative;
		@include breakpoint($bp-charlie) {
			padding: 100px 0;
		}
		@include breakpoint($bp-bobby) {
			padding: calc(120px + 50px) 0 120px;
		}
		.credit {
			position: absolute;
			bottom: -75px;
			right: -200px;
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			background: $charcoal;
			opacity: 0.25;
			width: 100%;
			height: 100%;
		}
		.container {
			position: relative;
			z-index: 2;
			max-width: 680px;
			margin: 0 auto;
			width: 100%;
		}
		h2 {
			color: $white;
			text-align: center;
		}
		.description {
			text-align: center;
			margin-bottom: 30px;
			p {
				color: $white;
				margin: 0;
			}
		}
		.in-form {
			width: 100%;
			margin: 0 auto;
			@include breakpoint($bp-charlie-sm) {
				margin-left: 8px;
			}
			@include breakpoint($bp-charlie) {
				margin-left: 16px;
			}
			.gfield_label {
				display: none!important;
			}
			li:not(.split) {
				@include breakpoint($bp-charlie) {
					padding-right: 32px !important;
				}
			}
			.split {
    			@include breakpoint($bp-charlie) {
					display: inline-block;
    				float: none !important;
    				width: calc(50% - 8px) !important;
    				width: calc(50% - 8px) !important;
    			}
			}
			.radio {
				.gfield_label {
					display: block!important;
					color: #fff;
					font-size: 14px;
				}
			}
		}
	}
}