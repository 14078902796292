.member-login-link {
  height: 27px;
  display: flex;
  align-items: center;
  align-content: center;
  float:right;
  margin-right: 15px;
  margin-top: 9px;
  margin-left: 14px;
  font-family: aktiv-grotesk,sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0.1em;
  text-align: left;
  color: #05426B;
  text-transform: uppercase;
  position: relative;
  top:1px;

  a {
    color: #05426B;
    display: flex;
    align-items: center;
    align-content: center;
    &:hover{
      color: #05426B;
    }
  }

  svg {
    fill: #05426B;
    position: relative;
    top:-1px;
    margin-left: 6px;
    width: 11px;
    height: 13px;
    path {
      fill: #05426B;
    }
  }
}


.topper-spacer {
  background: #fff;
  height: 33px;
  @include breakpoint($bp-charlie) {
    height: 115px;
  }
}

.topper-posts.topper-member-resources .container{
  .position-bottom{
    @media (min-width: 768px){
      padding-top: 166px;
    }
    @media (min-width: 960px){
      padding-top: 200px;
    }
  }
}


.member-portal__login-form-section {
  padding: 50px 0 50px;
  @include breakpoint($bp-charlie) {
    padding: 108px 0 88px;
    max-width: 557px;
    margin-left: auto;
    margin-right: auto;
  }
}

.member-portal__login-page {
  position: relative;
  @include breakpoint($bp-charlie) {
    &:before {
      content:'';
      height: 100%;
      width: calc(100% - 80px);
      margin-left:40px;
      box-shadow: 15px 15px 50px rgba(0, 0, 0, 0.1);
      position: absolute;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.member-portal__login-btn {
  width: 100%;
  max-width: none;
  display: flex;
  padding: 18px 15px;
  justify-content: space-between;

  background: #00C7C4;
  color: #0A2129;

  &:hover {
    color:#73CCE0;
    svg {
      fill: #73CCE0;
      path {
        fill: #73CCE0;
      }
    }
    background: #0A2129;
    border-color: #0A2129;
  }

  svg {
    width: 11px;
    height:13px;
    position: relative;
    left:-4px;
  }

  span {
    flex: 1 0 auto;
    text-align:center;
  }
}

.wide-portal-container{
  @include breakpoint($bp-charlie) {
    max-width: 1250px;
  }
}

.member-portal__login-form {
  input {
    border: 2px solid #9EA6A8;
    color: #3D4D54;
    padding: 10px 24px;
    font-size: 20px;
    line-height: 31px;
    height: auto;
  }
  label.email-address {
    display: none;
  }
  .error-field {
    border-color: #EB0037;
  }
  .error-msg {
    color: #EB0037;
    font-family: aktiv-grotesk,sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;

  }
  [aria-hidden="true"]{
    display: none;
    max-height: 0;
    overflow:hidden;
    opacity: 0;
    transition: .35s;
  }
  [aria-hidden="false"]{
    display: block;
    max-height: 400px;
    opacity: 1;
  }

  &.processing {
    opacity: 0.8;
    pointer-events:none;
  }
}

.member-portal__disclaimer, .member-portal__disclaimer p {
  font-family: aktiv-grotesk,sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #0A2129;

  a {
    font-weight: bold;
  }
}

.member-portal__input {
  margin-top: 30px;
}

.member-portal__error-section {
  margin: 8px 0 10px;
}

.member-portal__submit {
  margin:15px 0 20px;
}

.member-portal__category-listings {
  margin: 80px 0 60px;
  @include breakpoint($bp-charlie) {
    margin: 80px 0 159px;
  }
}


.member-portal__category-listings-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -30px;
  @include breakpoint($bp-charlie) {
    margin-bottom: -60px;
  }
}

.member-portal__category-listing {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 8px 20px 35px 0;
  border-bottom: 2px solid #9EA6A8;
}

.member-portal__category-resources-topper{

  h6 {
    color: #3D4D54;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-align: center;
    margin-bottom: 4px;
    margin-top: -20px;
    a {
      color: #3D4D54;
    }
  }
  .category-listing__description {
    margin-top:-2px;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    flex: 1 0 auto;
    p:first-child{
      margin-top: 0;
    }
    p:last-child{
      margin-bottom: 0;
    }
  }

}

.member-portal__category-listing-col {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  @include breakpoint($bp-charlie) {
    margin-bottom: 60px;
  }
}

.portal-listing__title {
  font-family: aktiv-grotesk,sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  align-items: center;

  svg {
    margin-left: 12px;
    display: inline-block;
    vertical-align: middle;
    height: 16px;
    width: 9px;
    transition: .35s;
  }
  &:hover {
    svg path {
      fill: #00C7C4;
    }
  }
}


.portal-listing__description {

  font-family: aktiv-grotesk,sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #0A2129;
  flex: 1 0 auto;

  padding-right: 54px;
  p:first-child{
    margin-top: 0;
  }
  p:last-child{
    margin-bottom: 0;
  }
}

.member-resources-grid {
  display: flex;

  .single-grid {
    img {
      display: none;
    }
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    @media (min-width: 768px){
      max-width: calc(50% - 36px);
    }

  }
  .meta {
    min-height: 18px;
  }
  .post-details {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;

    h5 {
      flex: 0 0 auto;
    }
    .links {
      flex: 0 0 auto;
    }
  }
}


.member-resources-post-listings, .no-member-portal-posts--msg {
  min-height: 200px;
}

.member-resources-post-listings{
  @include breakpoint($bp-charlie) {
    padding-bottom: 108px;
  }
}
.no-member-portal-posts--msg {
  display: flex;
  flex-direction: column;
  justify-content: center;
}