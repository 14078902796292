// $white: #fff; now defined in variables

$bar-background: $charcoal;//$headlineColor;
$bar-background-hover: $charcoal;
$text-color: $pale-grey;
// Alert Bart
.alert-bar-footer {
  float: left;
  width: 100%;
  padding: 18px 0;
  background: $bar-background;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0px;
  z-index: 9;
  text-align:center;
  margin-bottom: -50px;
  opacity: 0;

  @include hover-fade();
  transform: none !important;
  p {
    padding: 0px;
    margin: 0px;
    text-align: center;
    color: $text-color;
    font-family: $headline-font;
    font-weight: 600;
    line-height: 125%;
    font-size: 15px;
    span {
      text-transform: uppercase;
      margin-right: 10px;
      font-size: 15px;
    }
    @include breakpoint($bp-charlie) {
      text-align: left;
    }
  }
  .container{
    padding: 0 30px;
    width: 100%;
    max-width: 1210px;
    position: static;
    @include breakpoint($bp-charlie) {
      padding: 0 40px;
    }
  }
  .row{
    margin:0;
    position: static;
  }
  .footer-area-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
  .col-xs-12 {
    position: static;
    @include breakpoint($bp-charlie) {
      padding: 0px;
      padding-right: 20px;
    }
  }
  .close-icon {
    position: absolute;
    top: 50%;
    z-index: 999;
    @include transform(translateY(-50%));
    right: 5px;
    padding: 10px;
    svg {
      width: 28px;
      padding: 8px;
      background: $bar-background-hover;
      border-radius: 50px;
      fill: $text-color;
      @include hover-fade();
      cursor: pointer;
      &:hover {
        fill: $bar-background;
        background: $text-color;
      }
    }
  }
  &.has-link {
    &:hover {
      background: $bar-background-hover;
    }
    .close-icon .fa:hover {
      color: $body;
    }
  }
  &.appear {
    margin-bottom: 0px;
    z-index: 999;
    opacity: 1;
  }
  &.showed-alert {
    display: none;
  }
}