.module {
	float: left;
    width: 100%;
    position: relative;
	margin-top: 40px;
	@include breakpoint($bp-charlie) {
		margin-top: 55px;
	}
	@include breakpoint($bp-bobby) {
		margin-top: 60px;
	}
	a {
		color: $dark-teal;
		&:hover {
			color: $secondary;
		}
	}
	&.last-module {
		&.full-content, &.buttons {
			margin-bottom: 40px;
			@include breakpoint($bp-charlie) {
				margin-bottom: 70px;
			}
			@include breakpoint($bp-bobby) {
				margin-bottom: 90px;
			}
		}
	}
	.container, .row {
		height: 100%;
	}
    img.size-full {
    	width: 100%;
        max-width: 100%;
        height: auto;
    }
    .wp-caption {
    	width: 100%!important;
    	margin: 25px 0 25px;
    	img {
    		height: auto!important;
    		width: 100%!important;
    		@include breakpoint($bp-bravo) {
    			height: auto!important;
    			width: auto!important;
    		}
    	}
    	@include breakpoint($bp-bravo) {
    		width: auto!important;
    		margin: 25px 0 25px;
    	}
    }
    .wp-caption-text {
    	margin: 10px 0px;
        font-size: 14px;
        line-height: 125%;
        text-align: center;
        font-family: $primary-font;
        font-weight: 400;
        color: $body;
        width: 100%!important;
        @include breakpoint($bp-bravo) {
			font-size: 16px;
			width: auto;
        }
    }
    .alignleft {
        float: none;
        margin: 25px 0 25px;
        max-width: 100%;
        width: 100%;
        height: auto;
        @include breakpoint($bp-bravo) {
            float: left;
            width: auto;
            max-width: auto;
            position: relative;
            margin: 10px 35px 10px 0;
        }
    }
    .aligncenter {
        float: none;
        display: table;
        margin: 0 auto;
        width: 100%;
        height: auto;
        margin: 25px 0 25px;
        @include breakpoint($bp-bravo) {
            float: none;
            width: auto;
            position: relative;
            margin: 10px auto;
        }
    }
    .alignright {
        float: none;
        margin: 25px 0 25px;
        max-width: 100%;
        width: 100%;
        height: auto;
        @include breakpoint($bp-bravo) {
            float: right;
            max-width: auto;
            width: auto;
            position: relative;
            margin: 10px 0 10px 35px;
        }
    }
	&.action-menu {
		margin: 0px;
		position: fixed;
		bottom: 0px;
		z-index: 10;
		@include breakpoint($bp-charlie) {
			position: relative;
		}
		nav {
			ul {
				@for $i from 1 through 8 {
		  			li:first-child:nth-last-child(#{$i}),
		  			li:first-child:nth-last-child(#{$i}) ~ li {
		    			width: 100% / $i 
		    		} 
		    	} 
			    li {
			    	text-align: center;
			    	border-right: 1px solid $primary;
			    	float: left;
					.sub-menu {
						display: none;
					}
			    	&:last-child {
			    		margin: 0px;
			    		border: 0px;
			    	}
			    	a {
			    		display: block;
			    		float: left;
			    		width: 100%;
			    		background: $secondary;
						color: #fff;
			    		text-transform: uppercase;
			    		padding: 20px 0;
			    		@include breakpoint($bp-charlie) {
			    			padding: 60px 0;
			    		}
			    	}
			    }
			}
		}
	}
	&.full-cta {
		float: left;
		width: 100%;
		background-color: $primary;
		color: #fff;
		background-size: cover !important;
		@include breakpoint($bp-charlie) {
			height: 650px; 
		}
		.btn {
			float: left;
			width: 100%;
			@include breakpoint($bp-charlie) {
				margin: 0 auto;
				display: table;
				float: none;
				width: auto;
			}
		}
		p {
			color: #fff;
			max-width: 750px;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
		}
	}
	&.intro {
		float: left;
		width: 100%;

		.col-sm-8 {
			margin: 0 auto;
			float: none;
			position: relative;
		}
		hr {
			margin: 30px auto;
			width: 38px;
			height: 2px;
			background: $hrColorShort;
		}
		h5 {
			text-align: center;
			font-size: 23px;
			line-height: 150%;
			color: #000000;
			margin: 0;
			font-family: $primary-font;
		}
		.description {
			margin: 0;
			p {
				text-align: center;
				font-size: 18px;
				@include breakpoint($bp-bobby) {
					font-size: 21px;
				}
				&:last-of-type {
					margin: 0;
					text-align: center;
				}
			}
		}

		&.drop-cap {
			.description {
				p:first-child:first-letter {
					font-family: $headline-font;
					font-size: 100px;
					color: $teal;
					float: left;
					padding: 8px 25px 5px 0;
				}
			}
		}

		&.drop-cap, &.left-align {
			hr {
				margin-left: 0;
			}

			h5 {
				text-align: left;
			}

			.description {
				p, p:last-of-type {
					text-align: left;
				}
			}
		}
	}
	&.scroller-wrap {
		width: 100%!important;
		position: relative;
		float: left;
		.impact-stories {
			display: block;
			float: left;
			width: 100%;
			outline: none;
			.slick-list {
				overflow: hidden;
			}
		}
		.nav-wrap {
			position: absolute;
			top: 455px;
			left: 0;
			width: 100%;
			z-index: 1000!important;
			padding: 0 20px;
			margin: 0;
			display: table;
			text-align: center;
			@include breakpoint($bp-bobby) {
				position: absolute;
				bottom: 100px;
				left: 0;
				top: auto;
				width: calc(50% - 40px);
				z-index: 99;
				padding: 0 20px;
			}
			.arrow {
				width: 21px;
				display: block;
				padding: 0;
				cursor: pointer;
				float: left;
				&.next {
					float: right;
				}
				@include breakpoint($bp-bobby) {
					float: none;
					padding: 0 25px;
					display: inline-block;
					&.next {
						float: none;
					}
				}
				svg {
					width: 21px;
					.st0{
						@include hover-fade();
						fill:none;
						stroke:$body;
						stroke-width:2;
						stroke-miterlimit:10;
					}
				}
				&:hover svg .st0 {
					stroke: $secondary;
				}
			}
			ul {
				display: inline-block;
				margin: 0;
				padding: 0 12px 0 28px;
			}
			li {
				margin: 0;
				padding: 0 10px;
				float: none;
				list-style: none;
				cursor: pointer;
				width: auto!important;
				font-family: $headline-font;
				font-weight: 700;
				font-size: 11px;
				color: #444444;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				@include hover-fade();
				display: none;
				@include breakpoint(1224px) {
					padding: 0 25px;
				}
				@include breakpoint($bp-bobby) {
					display: inline-block;
				}
				&:hover, &.active {
					color: $secondary;
				}
			}
		}

		.impact-stories {
			background: $pale-grey;
			@include breakpoint($bp-charlie) {
				// margin-top: 75px;
				// margin-bottom: 45px;
			}
			@include breakpoint($bp-bobby) {
				// margin-top: 100px;
				// margin-bottom: 65px;
			}
			.single-story.image-right .image {
				@include breakpoint($bp-bobby) {
					//transform: scale(1.075);
					top: 0;
				}
			}
			position: relative;
			//overflow: hidden;
			width: 100%!important;
			height: auto;
			@include breakpoint($bp-bobby) {
				height: 620px;
			}
			.slick-arrow {
				display: none!important;
			}
			.single {
				float: left;
				width: 100%;
				height: auto;
				@include breakpoint($bp-bobby) {
					height: 620px;
				}
				position: relative;
				z-index: 50!important;
			}
			.slick-list {
				//overflow: hidden!important;
			}
			.single-story {
				position: relative;
				float: left;
				width: 100%;
				height: auto;
				padding: 50px 20px 20px;
				@include breakpoint($bp-bobby) {
					height: 620px;
					padding: 50px 0;
				}
				z-index: 10;
				outline: none!important;
				.image {
					top: 0!important;
				}
				.wrapper {
					padding: 0;
					height: 100%;
					padding-top: 15px;
					margin: 15px auto 0;
					@include breakpoint($bp-bobby) {
						width: 50%;
					}
					.description {
					p {
							// font-family: $primary-font;
							// font-weight: 400;
							//font-size: 15px;
							//line-height: 150%;
							&:last-of-type {
								margin-bottom: 0;
							}
						}
					}
					.inner-wrap {
						position: relative;
						top: auto;
						max-width: 430px;
						@include transform(translateY(0));
						right: auto;
						left: auto;
						img {
							max-height: 95px;
							max-width: 190px;
						}
					}
				}
			}
		}
	}
	&.donation {
		float: left;
		width: 100%;
		background-color: $body;
		color: #fff;
		background-size: cover !important;
		margin-bottom: 25px;
		padding: 85px 0;
		@include breakpoint($bp-charlie) {
			padding: 190px 0;
		}
		.btn {
			float: left;
			width: 100%;
			@include breakpoint($bp-charlie) {
				margin: 0 auto;
				display: table;
				float: none;
				width: auto;
			}
		}
		p {
			color: #fff;
			max-width: 750px;
			margin-left: auto;
			margin-right: auto;
			text-align: center;
		}
		.inner-form {
			max-width: 300px;
			width: 100%;
			display: table;
			margin: 0 auto;
			input {
				width: 100%;
				margin-bottom: 4px;
			}
			input[name="zip"] {
				margin-bottom: 13px;
			}
			.btn {
				width: 100%!important;
			}
		}
	}
	&.news-feed {
		margin: 0;
		padding: $mobile-section-padding 0;
		@include breakpoint($bp-charlie) {
			padding: 85px 40px;
		}
		@include breakpoint($bp-bobby) {
			padding: 100px 40px;
		}
		h3 {
			text-align: center;
			margin: 0 0 35px 0;
			@include breakpoint($bp-charlie) {
				margin: 0 0 45px 0;
			}
			@include breakpoint($bp-bobby) {
				margin: 0 0 55px 0;
			}
		}
		.container {			
			width: 100%;
			max-width: 1110px;
			@include breakpoint ($bp-charlie) {
				padding: 0;
			}
		}
		.col-md-8 {
			float: none;
			display: table;
			margin: 0 auto;
		}
		.headlines {
			margin: 0 auto 15px;
			@include breakpoint($bp-charlie) {
				margin: 0 auto 25px;
			}
			@include breakpoint($bp-bobby) {
				margin: 0 auto 25px;
			}
			h2 {
				text-align: center;
				margin: 0 0 25px;
			}
			h6 {
				margin-bottom: 10px;
				text-align: center;
				color: $secondary;
			}
			hr {
				margin: 0 auto;
				width: 35px;
				height: 2px;
				background: $secondary;
			}

		}
		.news-items {
			float: left;
			width: 100%;
			margin: 0;
			padding: 0 20px;
			@include breakpoint(590px) {
				padding: 0;
			}
			.news-item {
				float: none;
				width: 100%;
				margin: 25px auto;
				background: white;
				border-radius: 0;
				//box-shadow: 0px 8px 60px 0px rgba(0, 0, 0, 0.15);
				height: 400px;
				position: relative;
				@include hover-fade();
				overflow: hidden;
				@include breakpoint(590px) {
					max-width: 350px;
				}
				@include breakpoint($bp-charlie) {
					width: calc( ( 100% - ( 25px * 2 ) ) / 3);
					height: 475px;
					float: left;
					margin-right: 25px;
					max-width: 100%;
				}
				@include breakpoint($bp-bobby) {
					height: 555px;
					max-width: 100%;
				}
				h3 {
					a {
						color: $charcoal;
						&:hover {
							color: $purple;
						}
					}
				}
				&:hover {
					//background: $secondary;
					//@include transform(translateY(-6px));
					//@include box-shadow(0px, 6px, 30px, 0px, rgba(0,0,0,0.15));
					&.facebook {
						&.image-in {
							h5, .small {
								opacity: 1;
							}
							.news-image {
								opacity: .5;
							}
							.news-content {
								.text-container {
									//@include transform(translateY(0%));
								}
							}
						}
					}
					h5, .small {
						color: #fff!important;
					}
					.icon i {
						color: #fff!important;
					}
					.news-image .image {
						opacity: 0.5;
					}
					.icon svg .st0 {
						fill: #fff!important;
					}
				}
				a {
					position: absolute;
					left: 0px;
					top: 0px;
					right: 0px;
					bottom: 0px;
					z-index: 199!important;
				}
				.news-image {
					position: absolute;
					top: 0px;
					left: 0px;
					height: 50%;
					width: 100%;
					z-index: 1;
					background: #000;
					background-position: top center !important;
					background-size: cover!important;
					@include hover-fade();
					.image {
						position: absolute;
						top: 0px;
						left: 0px;
						right: 0px;
						height: 100%;
						width: 100%;
						background-size: cover !important;
						background-position: 50% 50% !important;
						@include hover-fade();
					}
					.overlay {
						opacity: 0;
					}
				}
				.message {
					@include hover-fade();
					margin: 15px 0;
					word-wrap: break-word;
					font-size: 19px;
					line-height: 140%;
					color: #ffffff;
					font-family: $primary-font;
					text-align: center;
					@include breakpoint($bp-charlie) {
						margin: 20px 0;
						font-size: 23px;
					}
					@include breakpoint($bp-bobby) {
						margin: 30px 0;
						font-size: 26px;
					}
				}
				.icon {
					position: absolute;
					right: 0;
					bottom: 0;
					padding: 30px;
					i {
						color: #fff;
						font-size: 31px;
						@include hover-fade();
						@include breakpoint($bp-charlie) {
							font-size: 35px;
						}
						&.fa-facebook {
							font-size: 24px;
							@include breakpoint($bp-charlie) {
								font-size: 28px;
							}
						}
					}
					svg {
						width: 30px;
						height: auto;
						.st0{
							@include hover-fade();
							fill: white;
						}
					}
				}
				.news-content {
					position: absolute;
					height: 100%;
					top: 0px;
					left: 0px;
					right: 0px;
					padding: 25px 0;
					z-index: 2;
					border-bottom: $border-height solid $border-color;
						@include breakpoint($bp-bobby) {
						border-top: $border-height solid $border-color;
					}
					.text-container {
						position: absolute;
						bottom: 0;
						left: 0;
						padding: 0 20px;
						width: 100%;
						@include breakpoint($bp-bravo) {
							padding: 0;
						}
						@include breakpoint($bp-delta) {
							padding: 0 30px;
						}
					}
				}
				.small {
					@include hover-fade();
					margin-bottom: 0px;
					font-size: 12px;
					font-family: $headline-font;
					letter-spacing: 0.350em;
					color: #ffffff;
					text-transform: uppercase;
					font-weight: 700;
					text-align: center;
				}
				&.image-in {
					h5, .small {
						color: white;
					}
					h5 {
						line-height: 140%;
						font-size: 17px;
						@include breakpoint($bp-charlie) {
							margin: 20px 0;
							font-size: 20px;
						}
						@include breakpoint($bp-bobby) {
							margin: 30px 0;
							font-size: 23px;
						}
					}
					.icon {
						i {
							color: white;
						}
					}
				}
				&.no-image {
					.news-content {
						.text-container {
							height: auto;
							padding: 0 40px;
							bottom: auto;
							top: 50%;
							@include transform(translateY(-50%));
							@include breakpoint($bp-delta) {
				    			padding: 0 40px;
				    		}
						}
						.icon {
							left: 0;
							right: 0;
							margin: 0 auto;
							text-align: center;
						}
					}
				}
				&.connect {
					background-image: url('img/bg_connect.png');
				    background-position: 50% 50%;
				    background-size: cover!important;
				    .news-content {
				    	.text-container {
				    		bottom: auto;
				    		top: 50%;
				    		@include transform(translateY(-50%));
				    		@include breakpoint($bp-delta) {
				    			padding: 0 40px;
				    		}
				    		
				    		.small {
				    			color: $charcoal;
				    		}
				    		.social.links {
				    			position: relative;
				    			width: 100%;
				    			display: table;
				    			margin-top: 45px;
				    			ul {
				    				width: 100%;
				    				display: table;
				    				position: relative;
				    				text-align: center;
				    				li {
					    				display: inline-block;
					    				float: none;
					    				a {
					    					position: relative;
					    					left: auto;
					    					top: auto;
					    					right: auto;
					    					bottom: auto;
					    					font-size: 27px;
					    					padding: 6px 8px;
					    					@include breakpoint($bp-charlie) {
					    						font-size: 32px;
					    						padding: 6px 15px;
					    					}
					    				}
					    			}
				    			}
				    		}
				    	}
				    }
				    &:hover {
				    	.news-content .text-container .small {
				    		color: #0b181e!important;
				    	}
				    }
				}
				&.instagram {
				 	.news-image {
				 		height: 100%;
				 		.overlay {
				 			opacity: 0.3;
				 		}
				 	}
				 	.news-content {
				 		height: 100%;
				 		padding: 0 40px;
				 		.icon {
				 			left: 0;
				 			right: 0;
				 			margin: 0 auto;
				 			text-align: center;
				 		}
				 		.text-container {
				 			position: absolute;
				 			bottom: auto;
				 			top: 50%;
				 			@include transform(translateY(-45%));
				 			opacity: 0;
				 			@include hover-fade();
				 		}
				 	}
				 	&:hover {
				 		.news-content {
				 			.text-container {
				 				opacity: 1;
				 				@include transform(translateY(-50%));
				 			}
				 		}
				 	}
				}
				&.twitter {
					background: $purple;
					&.image-in {
						h5, .small {
							color: #fff;
						}
					}
				}
				&.facebook {
					background: #afcb64;
					&.image-in {
						background: #afcb64;
						h5, .small {
							@include hover-fade();
						}
						.news-content {
							.text-container {
								@include hover-fade();
							}
						}
					}
				}

			}
		}
		.btn {
			margin: 0 auto;
			display: table;
			float: none;
			width: auto;
		}
	}
	&.featured-content {
		float: left;
		width: 100%;
		overflow: hidden;
		margin-bottom: 50px;
		hr {
			max-width: 690px;
			width: 85%;
			height: 2px;
			background: $hrColor;
			border-radius: 0;
			margin: 30px auto 0;
			padding: 0 35px;
		}
		.slider-nav {
			max-width: 1145px;
			width: 100%;
			margin: 0 auto;
			position: relative;
			z-index: 9997;
			.slick-arrow {
				position: absolute;
				top: 0;
				right: 0;
				width: 60px;
				height: 60px;
				background: $white;
				@include breakpoint($bp-charlie) {
					width: 75px;
					height: 75px;
				}
				&:hover {
					background: $purple;
					transition: 0.35s;
					cursor: pointer !important;
					.arrow svg {
						fill: $white;
						stroke: $white;
					}
				}
				.arrow {
					width: 14px;
					height: 14px;
					@include center;
					position: absolute;
					@include breakpoint($bp-charlie) {
						width: 17px;
						height: 17px;
					}
					svg {
						width: 14px;
						height: 14px;
						fill: $middle-grey;
						stroke: $middle-grey;
						stroke-width: 3px;
						position: absolute;
						top: 0;
						right: 0;
						@include breakpoint($bp-charlie) {
							width: 17px;
							height: 17px;
						}
					}
				}
				&.prev {
					right: 62px;
					@include breakpoint($bp-charlie) {
						right: 77px;
					}
					svg {
						transform: rotate(180deg);
					}
				}
			}
		}
		.image-slider {
			float: left;
			width: 100%;
			position: relative;
			padding: 0;
			overflow: hidden;

			// .slick-prev {
			// 	position: absolute;
			// 	top: 37%;
			// 	@include transform(translateY(-50%));
			// 	left: 25px;
			// 	width: 25px;
			// 	height: 25px;
			// 	-webkit-mask: url('img/next.svg') no-repeat 100% 100%;
			// 	mask: url('img/next.svg') no-repeat 100% 100%;
			// 	-webkit-mask-size: cover;
			// 	mask-size: cover;
			// 	background-color: #333;
			// 	@include hover-fade();
			// 	text-indent: -9999px;
			// 	cursor: pointer;
			// 	@include transform(rotate(180deg));
			// 	z-index: 25;
			// 	opacity: 0;
			// 	display: none!important;
			// 	@include breakpoint($bp-charlie) {
			// 		left: 100px;
			// 		background-color: #c8c8c8;
			// 		top: 42%;
			// 		width: 70px;
			// 		height: 70px;
			// 	}
			// 	&:hover {
			// 		background: #333;
			// 		// opacity: 1;
			// 	}
			// }
			// .slick-next {
			// 	position: absolute;
			// 	top: 42%;
			// 	@include transform(translateY(-50%));
			// 	right: 25px;
			// 	width: 25px;
			// 	z-index: 25;
			// 	height: 25px;
			// 	-webkit-mask: url('img/next.svg') no-repeat 100% 100%;
			// 	mask: url('img/next.svg') no-repeat 100% 100%;
			// 	-webkit-mask-size: cover;
			// 	mask-size: cover;
			// 	background-color: #333;
			// 	@include hover-fade();
			// 	text-indent: -9999px;
			// 	opacity: 0;
			// 	cursor: pointer;
			// 	display: none!important;	
			// 	@include breakpoint($bp-charlie) {
			// 		right: 100px;
			// 		background-color: #c8c8c8;
			// 		top: 47%;
			// 		width: 70px;
			// 		height: 70px;
			// 	}
			// 	&:hover {
			// 		background: #333;
			// 		opacity: 0;
			// 	}
			// }
			.featured-image {
				height: auto;
				width: 100%;
				float: left;
				outline: none;
				background-repeat: no-repeat;
				background-position: center 0;
				background-size: cover!important;
				@include breakpoint($bp-charlie) {
					height: auto;
				}
				@include breakpoint($bp-delta) {
					height: auto;
				}
			}
			.featured-image{ 
				margin-top: 0;
			}
		}
		.featured-image {
			text-align: center;
			img {
				width: 100%;
				max-width: 1145px;
				height: auto;
			}
			&.video-share {
				margin-top: 0;
				.videoWrapper {
					width: 100%;
					max-width: 1145px;
					height: auto;
					float: none;
					margin: 0 auto;
				}
			}
		}
		.slick-dots {
			display: none !important;
			@include breakpoint($bp-charlie) {
				display: block !important;
				max-width: 1145px;
				position: relative;
				bottom: 0px;
				right: 10px;
				margin: 0 auto;
				z-index: 999;
			}
			@include breakpoint($bp-nina) {
				right: 0;
			}
		}
		// .slick-dots {
		// 	float: left;
		// 	width: 100%;
		// 	text-align: center;
		// 	margin: 35px 0 0 0;
		// 	padding: 0 0 20px 0;
		// 	display: table;
		// 	list-style: none;
		// 	position: relative;
		// 	&:before {
		// 		content: '';
		// 		position: absolute;
		// 		bottom: 0;
		// 		left: 0;
		// 		right: 0;
		// 		width: 90%;
		// 		max-width: 620px;
		// 		height: 2px;
		// 		margin: 0 auto;
		// 		background: #cccccc;
		// 	}
		// 	li {
		// 		display: inline-block;
		// 		&.slick-active {
		// 			a {
		// 				color: $secondary;
		// 			}
		// 		}
		// 		a {
		// 			float: left;
		// 			font-family: $headline-font;
		// 			font-size: 11px;
		// 			line-height: 100%;
		// 			padding: 8px;
		// 			margin: 0 10px;
		// 			text-transform: uppercase;
		// 			color: #444444;
		// 			cursor: pointer;
		// 			&:hover {
		// 				color: $secondary;
		// 			}
		// 		}
		// 	}
		// }
		.col-md-8,
		.col-md-10 {
			margin: 0 auto;
			display: table;
			float: none;
			text-align: center;
			margin-top: 30px;
			p {
				margin: 0;
				font-size: 14px;
				color: $body;
				font-weight: 400;
				font-style: italic;
				@include breakpoint($bp-charlie) {
					font-size: 16px;
				}
			}
		}
	}
	&.full-content {
		blockquote {
			text-align: left;
			margin: 35px 0;
			position: relative;
			padding-left: 20px;
			color: $body;
			font-size: 22px;
			line-height: 145%;
			font-family: $primary-font;
			font-weight: 400;
			font-style: italic;
			@include breakpoint($bp-charlie) {
				font-size: 25px;
			}
			@include breakpoint($bp-bobby) {
				font-size: 28px;
				padding-left: 0;
				margin: 55px 0;
			}
			&:before {
				content: '';
				position: absolute;
				width: 2px;
				height: calc(100% - 6px);
				background: $hrColorShort;
				border-radius: 5px;
				margin: 0 auto;
				top: 4px;
				left: 0;
				@include breakpoint($bp-delta) {
					left: -40px;
				}
			}
			p { 
				color: $middle-grey;
				font-size: 19px;
				line-height: 140%;
				font-family: $primary-font;
				font-weight: 400;
				font-style: italic;
				margin-bottom: 25px;
				@include breakpoint($bp-charlie) {
					font-size: 20px;
				}
				@include breakpoint($bp-bobby) {
					font-size: 21px;
				}
				&:last-child {
					padding: 0px;
					margin: 0px;
				}
			}
			h5 {
				font-style: normal;
				font-size: 16px;
			}
		}
		.col-md-8 {
			margin: 0 auto;
			float: none;
		}
		.container {
			//max-width: 1140px;
		}
		img {
			width: 100%;
			@include breakpoint($bp-bravo) {
				width: auto;
			}
		}
	}
	&.issues-page {
		margin-top: 30px;
		@include breakpoint($bp-charlie) {
			margin-top: 45px;
		}
		@include breakpoint($bp-bobby) {
			margin-top: 50px;
		}
	}
	&.columns {
		.row {
			.indiv-column {
				float: left;
				width: 100%;
				@include breakpoint($bp-charlie) {
					padding: 0 15px;
				}
			}
			@include breakpoint($bp-charlie) {
				@for $i from 1 through 8 {
		  			.indiv-column:first-child:nth-last-child(#{$i}),
		  			.indiv-column:first-child:nth-last-child(#{$i}) ~ .indiv-column {
		    			width: 100% / $i 
		    		} 
		    	}
		    }
		}
	}
	&.video {
		.videoWrapper {
			@include breakpoint($bp-charlie) {
				width: calc(100% + 150px);
				margin: 0 -75px;
			}
		}
		.col-md-8 {
			margin: 0 auto;
			display: table;
			float: none;
		}
	}
	&.block-quote {
		padding: 90px 0;
		background: $navy;
		text-align: center;
		background-size: cover!important;
		background-position: 50% 50%;
		position: relative;
		@include breakpoint($bp-charlie) {
			padding: 160px 0;
		}
		@include breakpoint($bp-bobby) {
			padding: 210px 0;
		}
		.credit {
			position: absolute;
			bottom: 26px;
			right: 23px;

			a {
				font-family: $headline-font;
				font-style: normal;
				font-size: 12px;
				color: rgba(#fff, 0.5);

				&:hover {
					color: #fff;
				}
			}
		}
		// .strip {
		// 	position: absolute;
		// 	bottom: 0;
		// 	width: 100%;
		// 	height: 74px;
		// 	left: 0;
		// 	background: url('img/tent-pattern-border-red.png');
		// 	background-size: cover!important;
		// }
		.col-sm-9 {
			margin: 0 auto;
			display: table;
			float: none;
		}
		.overlay {
			background: $charcoal;
			opacity: 0.55;
		}
		hr {
			width: 76px;
			height: 2px;
			background: $pink;
			margin-bottom: 30px;
		}
		h5 {
			margin-top: 30px;
			margin-bottom: 0;
			color: $pastel-teal;
			font-size: 16px;
		}
		p {
			color: $white;
			font-size: 19px;
			line-height: 145%;
			font-family: $primary-font;
			font-weight: 400;
			//font-style: italic;
			margin-bottom: 0;
			@include breakpoint($bp-charlie) {
				font-size: 21px;
			}
			@include breakpoint($bp-bobby) {
				font-size: 24px;
			}
			&:last-of-type {
				margin-bottom: 0;
			}
			&.credit {
				font-size: 12px;
			}
		}	
	}
	&.accordion-m {
		float: left;
		width: 100%;
		.col-sm-7 {
			margin: 0 auto 25px auto;
			float: none;
		}
		.accordion {
		   dt, dd {
		      padding: 0;
		      &:last-of-type {
		      }
		      a {
		        display: block;
		        color: black;
		        font-weight: bold;
		      }
		   }
		   dd {
		   	padding-bottom: 10px; 
		   	p a {
		   		display: inline-block;
		   	}
		   }
		  dt {
		  	border-top: 2px solid $grey;
		  	position: relative;
		  	.icon {
		  		position: absolute;
		  		width: 40px;
		  		right: 0;
		  		top: 37px;
		  		svg {
		  			width: 100%;
		  			.st0{fill: $teal;}
		  			.st1{fill: $charcoal;}
		  			.plus {
		  				@include transition(all 0.2s ease);
		  			}
		  		}
		  	}
		  	a {
		  		padding: 35px 0;
		  		&.active {
		  			.icon svg .plus {
		  				opacity: 0;
		  			}
		  		}
		  		h3 {
		  			max-width: 85%;
		  			margin: 0;
		  			padding: 0;
		  		}
		  	}
		  }
		}
	}
	&.buttons {
		.all-buttons {
			float: left;
			width: 100%;
			text-align: center;
			@include breakpoint($bp-charlie) {
				float: none;
				margin: 0 auto;
				display: table;
				width: auto;
			}
			.btn {
				margin: 5px 0;
				@include breakpoint($bp-charlie) {
					margin: 5px 10px;
				}
				display: inline-block;
				float: none;
			}
		}
		.btn {
			float: none;
			width: 100%;
			display: table;
			margin: 0 auto 8px auto;
			color: $btn-text-color !important;
			&:hover {
				color: $btn-text-hover-color !important;
			}
			@include breakpoint($bp-charlie) {
				margin: 0 0 0 10px;
				width: auto;
				display: block;
				float: left;
			}
		}
	}
	&.signup {
	    @include breakpoint($bp-delta) {
	        padding: 200px 0;
	    }
	    input[type="text"], input[type="email"] {
	        color: #333;
	    }
	    p {
	        text-align: center;
	        @include breakpoint($bp-delta) {
	            width: 70%;
	            margin: 0 auto;
	            display: table;
	        }
	    }
	    h6 {
	        text-align: center;
	    }
	}
	&.image-slider {
		width: 100%;
		position: relative;
		padding: 0;
		overflow: hidden;
		margin-bottom: 25px;
		@include breakpoint($bp-charlie) {
			margin-bottom: 50px;
			padding: 0;
		}
		.full-widht-slider-image {
			height: 300px;
			width: 100%;
			float: left;
			outline: none;
			background-repeat: no-repeat;
			background-position: center 0;
			background-size: cover!important;
			@include breakpoint($bp-charlie) {
				height: 450px;
			}
			@include breakpoint($bp-delta) {
				height: 650px;
			}
		}
		.slick-prev {
			position: absolute;
			top: 46%;
			@include transform(translateY(-50%));
			left: 25px;
			width: 25px;
			height: 25px;
			-webkit-mask: url('img/arrow.svg') no-repeat 100% 100%;
			mask: url('img/arrow.svg') no-repeat 100% 100%;
			-webkit-mask-size: cover;
			mask-size: cover;
			background-color: #fff;
			@include hover-fade();
			text-indent: -9999px;
			cursor: pointer;
			@include transform(rotate(180deg));
			z-index: 25;
			opacity: 0.5;
			@include breakpoint($bp-charlie) {
				width: 45px;
				height: 45px;
			}
			&:hover {
				background: #fff;
				opacity: 1;
			}
		}
		.slick-next {
			position: absolute;
			top: 50%;
			@include transform(translateY(-50%));
			right: 25px;
			width: 25px;
			z-index: 25;
			height: 25px;
			-webkit-mask: url('img/arrow.svg') no-repeat 100% 100%;
			mask: url('img/arrow.svg') no-repeat 100% 100%;
			-webkit-mask-size: cover;
			mask-size: cover;
			background-color: #fff;
			@include hover-fade();
			text-indent: -9999px;
			opacity: 0.5;
			cursor: pointer;
			@include breakpoint($bp-charlie) {
				width: 45px;
				height: 45px;
			}
			&:hover {
				background: #fff;
				opacity: 1;
			}
		}
	}

	&.partners-layout {
		background:$pale-grey;
		padding: 0 0 $mobile-section-padding;
		float: left;
		width: 100%;
		@include breakpoint($bp-charlie) {
			padding: 150px 0 120px 0;
		}
		.container {
			background-color: #fff;
			padding-top: 2.5rem;
			max-width: calc(1110px + 160px);
			width: 100%;
		}
		.intro {
			margin: 0 auto;
			float: none;
			position: relative;
		}
		.headline {
			margin-bottom: 36px;
			h4 {
				text-align: center;
				margin: 0;
			}
		}

		.description {
			font-size: 21px;
			font-weight: 400;
			line-height: 155%;
			margin-bottom: 40px;
			text-align: center;
		}

		.filters {
			width: 100%;
			text-align: center;
			list-style: none;
			padding: 0;
			margin-top: 3rem;
			li {
				letter-spacing: .1em;
				margin-bottom: 0;
				color: $charcoal;
				font-family: $headline-font;
				font-weight: 600;
				text-transform: uppercase;
				font-size: 12px;
				position: relative;
				display: inline-block;
				cursor: pointer;
				margin: 0 1.5rem;
				&.active:after {
					content:"";
					display: block;
					position: relative;
					margin: 15px auto;
					width: 38px;
					height: 2px;
					background:$teal;
				}
			}
		}
		.show-all {
			float: left;
			width: 100%;
			list-style: none;
			overflow: hidden;
			display: none;
			text-align: center;
			&.active {
				display: block;
			}
			.single-image {
				width: calc(100%/2)!important;
				height: 165px!important;
				position: relative;
				float: left;
				&.more {
					display: none;
				}
				@include breakpoint(648px) {
					width: calc(100%/3)!important;
				}
				@include breakpoint($bp-charlie) {
					width: calc(100%/5)!important;
				}
				a {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					outline: none;
					pointer-events: all;
					z-index: 99;
				}
				img {
					max-width: 100px;
					position: absolute;
					top: 50%;
					@include transform(translateY(-50%));
					left: 0;
					right: 0;
					margin: 0 auto;
					-webkit-filter: grayscale(100%);
					filter: grayscale(100%);
					transition: transform 0.3s ease, filter 0.25s;
					@include breakpoint($bp-charlie) {
						max-width: 51%;
						max-height: 95px;
					}
				}
				&:hover {
					img {
						-webkit-filter: grayscale(0);
						filter: grayscale(0);
						@include transform(translateY(-50%) scale(1.1));
					}
				}
			}
			.read-more {
				display: inline-block;
				margin: 2rem 0 3rem;
				width: 80%;
				@include breakpoint($bp-charlie) {
					width: 300px;
				}
			}
		}
	}

	&.org-highlight {
		background:$pale-grey;

		.module-col {
			width: 100%;

			@include breakpoint($bp-charlie) {
				width: calc(100% / 2);
			}

			&.highlight-image-container {
				float: left;
				height: 635px;

				.highlight-image {
					object-fit: cover;
					height: 100%;
					width: 100%;
				}

			}

			&.highlight-content-container {
				align-items: center;
				display: flex;
				height: 635px;
				text-align: center;
				padding: 30px 30px;

				.container-center {
					margin-left: auto;
					margin-right: auto;
					max-width: 500px;
				}

				// min width: 768 for breakpoint
			}
		}

		.container {
			background-color: #fff;
			max-width: calc(1110px + 160px);
			padding: unset;
			width: 100%;
		}

		.description {
			font-family: 'aktiv-grotesk',sans-serif;
			font-size: 21px;
			font-weight: 400;
			line-height: 155%;
			color: $charcoal;
		}

		.heading {
			margin-bottom: 15px;
		}

		.org-name {
			font-weight: 800;
			margin-bottom: 15px;
			font-family: aktiv-grotesk,sans-serif;

		}

		.logo-wrapper {
			margin-bottom: 15px;

			.partner-logo {
				width: 60px;
			}


		}



	}
}

.donation {
    ul {
        margin: 0px;
        padding: 0px;
        float: left;
        width: 100%;
        li {
            float: left;
            width: 100%;
            list-style-type: none;
            margin: 0 0 5px;
            @include breakpoint($bp-charlie) {
                width: 48%;
                margin: 0 0 10px;
                &:nth-child(2n+2) {
                    float: right;
                }
            }
            a {
                color: #fff;
                text-align: center;
                text-decoration: none;
                display: block;
                float: left;
                width: 100%;
                font-size: 18px;
                padding: 8px 5px 10px;
                background: $primary;
                font-family: $headline-font;
                border: 2px solid $primary;
                border-radius: 3px;
                line-height: 30px;
                box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
                &:hover {
                	background: $secondary;
                	border: 2px solid $secondary;
                }
                @include breakpoint($bp-charlie) {
                    font-size: 21px;
                }
            }
            &:last-child {
                a {
                	background: transparent;
                	border: 2px solid #fff;
                    font-size: 14px;
                    padding: 7px 5px;
                    @include breakpoint($bp-charlie) {
                        height: 51px;
                    }
                    &:hover {
	                	background: $secondary;
	                	border: 2px solid $secondary;
	                }
                }
            }
        }
    }
    .all-columns {
        margin-top: 10px;
        .text-col {
            &:last-child {
                padding: 0px;
                margin-top: 10px;
                @include breakpoint($bp-charlie) {
                    margin: 0px;
                }
            }
        }
    }
    .col-lg-10, .col-lg-8 {
        float: none;
        margin: 0 auto;
        display: table;
    }
    .donation-title {
	    float: left;
	    width: 100%;
	    text-align: center;
	    color: #fff;
	    margin-bottom: 30px;
	    @include breakpoint($bp-charlie) {
	        width: 100%;
	    }
	    h6 {
	    	margin-bottom: 15px;
	    	color: #fff;
	    }
	    h2  {
	    	margin-bottom: 25px;
	    	color: #fff;
	    }
	}
}

.intro__side {
	max-width: 400px;
	margin: 30px auto;

	@include breakpoint($bp-echo) {
		width: 200px;
		margin: 0;
		position: absolute;
		z-index: 2;
		top: 0;
		right: -240px;
	}
}

.intro__side-image {
	max-width: 125px;
	height: auto;
	display: block;
	margin-bottom: 10px;
}

.intro__side-text {
	font-family: $headline-font;
	font-size: 12px;
	opacity: 0.5;
	padding-bottom: 20px;
	border-bottom: 2px solid rgba(#000, 0.5);
}

.intro__side-button.btn {
	font-size: 9px;
	color: rgba(#000, 0.7);
	min-width: 100%;
	max-width: 100%;
	width: 100%;
	padding: 10px;

	i {
		font-size: 10px;
	}

	&:hover {
		color: rgba(#000, 0.7);
	}
}

.tent__stats {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;

	@include breakpoint($bp-charlie) {
		margin-top: 40px;
	}

	@include breakpoint($bp-bobby) {
		margin-top: 40px;
	}
}

.tent__stat {
	text-align: center;
	width: 100%;
	max-width: 270px;
	margin: 10px 30px;
	position: relative;

	&:after {
		content: '';
		background-color: $grey;
		width: 65px;
		height: 2px;
		position: absolute;
		bottom: 0;
		left: 50%;
		@include transform(translate(-50%, 3px));

		@include breakpoint($bp-charlie) {
			@include transform(translate(-50%, 10px));
		}

		@include breakpoint($bp-bobby) {
			@include transform(translate(-50%, 15px));
		}
	}
}

.tent__stat-number {
	color: $cyan;
	margin: 0 0 10px 0;

	@include breakpoint($bp-charlie) {
		font-size: 80px;
	}

	.tent__stat:nth-child(2) & {
		color: $purple;
	}

	.tent__stat:nth-child(3) & {
		color: $orange;
	}
}

.tent__stat-description {
	font-size: 16px;
}

.quick-link__container {
	display: flex;
	flex-direction: row;
	//flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding-top: 25px;
	padding-bottom: 25px;
	border-top: 1px solid $border-color;
	border-bottom: 1px solid $border-color;
	text-align: left;
	margin-bottom: -1px; // makes borders overlap if more than one is displayed
	.quick-link__headline {
		letter-spacing: 0.1em;
		margin-bottom: 0;
		color: $charcoal;
	}
	.quick-link {
		display: inline-flex;
		align-items: center;
		font-size: 15px;
		margin-bottom: 0;
		margin-left: 10px;
		text-decoration: underline;
		svg {
			width: 14px;
			height: 14px;
			position: relative;
			top: -1px;
			margin-left: 12px;
		}
	}
}
