.resources-page {
	.sub-nav {
		background: #fff;
	}

	.topper {
		.container {
			text-align: center;
			.credit {
				right: 25px;
				text-align: right;
			}
			.position-bottom {
				left: 0;
				right: 0;
				margin: 0 auto;
				max-width: 620px;
				width: 100%;
			}
			h1, p, h6 {
				margin-right: 0;
				padding-right: 0;
				padding-left: 0;
				margin-left: 0;
				width: 100%;
				max-width: 100%;
				min-width: 100%;
			}
			.deck {
				margin-bottom: 0;
				margin-top: 10px;
				p {
					font-size: 18px;
					color: $white;
					margin: 0;
					@include breakpoint($bp-charlie) {
						font-size: 19px;
					}
					@include breakpoint($bp-bobby) {
						font-size: 23px;
					}
				}
			}
		}
	}
	.intro {
		float: left;
		width: 100%;
		margin-top: 55px;
		margin-bottom: 5px;
		.container {
			max-width: 930px;
			width: 100%;
			margin: 0 auto;
			float: none;
			text-align: center;
			p {
				font-size: 18px;
				letter-spacing: -0.01em;
				color: $body;
				@include breakpoint($bp-charlie) {
					font-size: 21px;
				}
				text-align: center;
				&:last-of-type {
					margin: 0;
				}
			}
			hr {
				width: 36px;
				height: 2px;
				background: $hrColorShort;
			}
		}
	}
	.grid-res {
		float: left;
		width: 100%;
		background: $white;
		padding: 50px 0 25px;
		.container {
			max-width: 930px;
			width: 100%;
			display: flex;
			flex-flow: row wrap;
			margin: 0 auto;
			//padding: 0;
			@include breakpoint($bp-charlie) {
				padding: 0;
			}
			@include breakpoint($bp-bobby) {

				// max-width: calc(1100px + 160px);
			}
		}
		.single-grid {
			width: 100%;
			margin: 0 0 20px;
			// padding: 20px 0 40px;
			@include hover-fade();
			height: auto;
			position: relative;
			overflow: hidden;
			@include breakpoint($bp-charlie) {
				margin: 0 0 60px;
				width: calc(50% - 36px);

				&:nth-child(2n+1) {
					margin-right: 70px;
				}
			}
			@include breakpoint($bp-bobby) {
				float: left;
				// padding: 4% 4% 8%;
				// width: calc(96%/2);
				// height: 500px;
				overflow: visible;
			}
			@include breakpoint($bp-bobby) {
				&:hover {
					//box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
					.more a {
						color: $secondary;
					}
					&:after {
						opacity: 0;
					}
					.overlay-share {
						//right: 7%;
						right: 0;
						opacity: 1;
					}
				}
			}
			.post-details{
				padding: 25px 0;
				position: relative;
				border-bottom: $border-height solid $border-color;
				@include breakpoint($bp-charlie) {
					padding: 35px 0;
				}
				@include breakpoint($bp-bobby){
					padding: 40px 25px;
				}
			}
			.overlay-share {
				width: 110px;
				overflow: hidden;
				position: absolute;
				bottom: 25px;
				right: 0;
				@include hover-fade();
				opacity: 0;
				small {
					text-transform: uppercase;
					font-size: 11px;
					color: $grey;
					letter-spacing: 0.08em;
					text-align: right;
					padding-right: 8px;
					padding-top: 5px;
				}
				.social {
					float: left;
					width: 100%;
					margin: 0;
					padding: 0;
					ul {
						margin: 0;
						padding: 0;
						float: left;
						width: 100%;
						li {
							width: calc(100%/3);
							float: left;
							a {
								padding: 0;
								text-align: center;
								float: left;
								width: 100%;
								color: $primary;
								&:hover {
									color: $orange;
								}
							}
						}
					}
				}
				small {
					float: left;
					width: 100%;
				}
			}
			img{
				width: 100%;
				height: auto;
				max-height: 230px;
				object-fit: cover;

				@media screen and (max-width: 767px) {
					max-height: 390px;
				}
			}
			.meta {
				// keep these styles global
				// font-family: $headline-font;
				// text-transform: uppercase;
				// //color: $middle-grey;
				// //font-size: 14px;
				// //font-weight: 700;
				// margin-bottom: 20px;
				span {
					font-weight: 700;
					margin-right: 15px;
					&.term_25 {
						color: $secondary;
					}
					&.term_24 {
						color: $purple;
					}
					&.term_23 {
						color: #f4a74e;
					}
					&.term_42 {
						color: #6bd3e7;
					}
				}
			}
			h5 {
				margin-bottom: 20px;
			}
			p {
				margin-bottom: 20px;
			}
			.more {
				// float: left;
				display: inline-block;
				margin-right: 30px;
				a {
					font-family: $headline-font;
					font-weight: 700;
					font-size: 15px;
					color: $dark-teal;
					display: inline-block;

					svg {
						width: 12px;
						height: 12px;
						margin-left: 3px;
						transform: translateY(-1px);
					}
				}
			}
		}
	}
	.research-reports{
		background: $pale-grey;
		box-shadow: 20px 8px 60px 20px rgba(0,0,0,.15);
		float: left;
		width: calc(100%);
		position: relative;
		overflow: hidden;
		margin-top: 40px;
		max-width: 1360px;

		@include breakpoint($bp-charlie){
			margin: 40px;
			width: calc(100% - 80px);
		}

		@include breakpoint($bp-bobby){
			height: 595px;
		}

		@include breakpoint(1441px){
			float: none;
			margin: 40px auto;
			position: relative;
			top: 40px;
		}

		.reports-slider{
			.slick-arrow{
				display: none !important;
			}
			.research-report{
				background: $pale-grey;
				float: left;
				width: 100%;
				position: relative;
				z-index: 85;
				display: block;
				
				@include breakpoint($bp-bobby){
					height: 595px;
					display: flex;
				}
				
				.col-md-6{
					float: none;
				}

				.report-image{
					padding: 0;
					position: relative;
					min-height: 220px;
					max-height: 220px;

					@include breakpoint($bp-charlie){
						min-height: 300px;
						max-height: 300px;
					}

					@include breakpoint($bp-bobby){
						min-height: 595px;
						max-height: 595px;
					}
					.featured-image{
						background-size: cover;
						position: absolute;
						height: 100%;
						width: 100%;
					}
				}

				.post-details {
					padding: 20px 20px 60px;

					@include breakpoint($bp-bravo){
						padding: 35px 35px 60px;
					}

					@include breakpoint ($bp-charlie){
						padding: 35px;
					}
					@include breakpoint ($bp-bobby){
						
					}
					@include breakpoint ($bp-echo){
						padding: 100px 70px;
					}
					display: flex;
					// align-items: center;

					.post-meta,
					.post-meta a {
						font-family: $headline-font;
						text-transform: uppercase;
						color: $middle-grey;
						//font-size: 14px; keep this a global font size
						font-weight: 700;
						margin-bottom: 1em;
					}

					.post-meta span{
						display: block;
						@include breakpoint($bp-charlie){
							display: inline-block;
						}
					}

					h3{
						line-height: 130%;
						color: $headlineColor;
						margin-bottom: 0.5em;
						font-size: 20px;

						@include breakpoint($bp-charlie){
							font-size: 26px;
						}

						@include breakpoint($bp-bobby){
							font-size: 28px;
						}

						@include breakpoint($bp-echo){
							font-size: 32px;
						}

						a{
							color: $charcoal;
						}
					}

					.excerpt{
						margin-bottom: 1em;
					}

					.category {
						text-transform: uppercase;
						@include breakpoint($bp-charlie){
							padding-right: 1.5em;
						}
					}

					.read-more {
						margin: 0;
						font-weight: 700;
						font-size: 16px;

						a{
							font-family: $headline-font;
							color: $cyan-alt;
							font-weight: bold;

							svg {
								width: 12px;
								height: 12px;
								margin-left: 6px;
								transform: translateY(-1px);
							}

							&:hover{
								color: $secondary;
							}
						}
					}
				}
			}
		}

		.reports-nav-wrap {
			display: flex;
			position: absolute;
			bottom: 20px;
			right: 10px;
			width: 100%;
			z-index: 2!important;
			padding: 0 20px;
			margin: 0;
			text-align: center;
			align-items: center;
			justify-content: flex-end;
			@include breakpoint($bp-bobby) {
				position: absolute;
				bottom: 45px;
				right: 0;
				top: auto;
				width: 50%;
				z-index: 2;
				padding: 0 20px;
				justify-content: center;
			}
			.arrow {
				width: 21px;
				display: block;
				padding: 0;
				cursor: pointer;
				float: left;
				&.next {
					float: right;
				}
				@include breakpoint($bp-bobby) {
					float: none;
					padding: 0 25px;
					display: inline-block;
					&.next {
						float: none;
					}
				}
				svg {
					width: 21px;
					.st0{
						@include hover-fade();
						fill: $middle-grey;
						stroke: $middle-grey;
						stroke-width: 2;
						stroke-miterlimit: 10;
					}
				}
				&:hover svg .st0 {
					stroke: $secondary;
				}
			}
			ul {
				display: flex;
				margin: 0;
				padding: 0 12px 0 28px;
			}
			li {
				margin: 0;
				margin: 0 10px;
				float: none;
				list-style: none;
				cursor: pointer;
				width: 12px;
				height: 12px;
				background-color: $grey;
				@include hover-fade();
				display: none;
				border-radius: 100%;
				@include breakpoint(1224px) {
					margin: 0 25px;
				}
				@include breakpoint($bp-bobby) {
					display: block;
				}
				&:hover, &.active {
					background-color: $primary;
				}
			}
		}
	}
}
