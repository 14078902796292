::-moz-selection { background: $secondary; color:#fff; }
::selection { background: $secondary; color:#fff; }

body, html {
	background-color: $body!important;
    color: $body;
    @include transition(all 0.3s ease-in-out);
	-webkit-font-smoothing: antialiased;
    height: 100%;
    main {
        background: #fff;
    }
    &.search-open {
       // margin-top: 61px;
        header {
        }
        .topper, .topper-home {
            //top: 61px;
        }
        .top-search {
            top: 0;
            input[type="text"] {
                opacity: 1;
            }
        }
    }
}

a {
	color: $primary;
	@include hover-fade();
	&:hover {
		color: $secondary;
	}
}

.credit {
    font-size: 12px;
    color: rgba(#f6f6f7, 0.4)!important;
    font-family: $headline-font;
    font-weight: 500; 
    margin: 0;
    padding: 0;
    display: none;
    @include breakpoint($bp-bobby) {
        display: block;
    }
    a {
        color: rgba(#f6f6f7, 0.4);
        &:hover {
            color: rgba($secondary, 0.95);
        }
    }
}

.overlay {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: #000;
    // don't use rgba so we use animations on it
    opacity: 0.15;
}



.pagination {
    list-style: none;
    margin: 0 auto;
    text-align: center;
    float: left;
    width: 100%;
    position: relative;
    ul {
        margin: 0!important;
        padding: 25px 15px;
        @include breakpoint($bp-charlie) {
            padding: 50px 15px;
        }
    }
    li {
        margin: 0px !important;
        display: inline-block;
        float: none;
        text-align: center;
        font-family: $headline-font;
        font-size: 14px;
        font-weight: 700;
        color: #999999;
        position: relative;
        padding: 0;
    }
    a, span {
        line-height: 1.6em;
        margin: 0px;
        padding: 5px 2px;
        display: inline-block;
        font-size: 14px;
        text-decoration: none;
        position: relative;
        color: #999999;
        border: 2px solid #fff;
        padding: 6px 14px;
        @include breakpoint($bp-charlie) {
            padding: 11px 20px;   
        }
        svg {
            margin-bottom: 3px;
            width: 18px;
            height: 17px;
            fill: #000;
            @include transition(all 0.2s ease-in-out);
            .st0{fill:none;stroke:#000;stroke-width:2;stroke-miterlimit:10;@include transition(all 0.2s ease-in-out);}
            &:hover {
                .st0{fill:none;stroke:$secondary;stroke-width:2;stroke-miterlimit:10;}
            }
        }
    }
    .prev {
        @include transform(rotate(-180deg));
    }
    span.current {
        color: #000;
        border: 2px solid $primary;
    }
    a {
        &:hover {
            color: $secondary;
        }
    }
}