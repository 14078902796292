.read-more {
	.btn {
		color: $navy;
	}
}

.btn {
	display: block;
	color: $sec-text-color;
	width: auto;
	@include hover-fade();
	cursor: pointer;
	background: transparent;
	border: 2px solid $teal;
	padding: 18px 15px;
	border-radius: 50px;
	line-height: 110%;		
	min-height: 50px; 
	width: 100%;
	font-size: 13px;
	text-transform: uppercase;
	letter-spacing: 0.08em;
	font-weight: 700;
	font-family: $headline-font;
	text-align: center;
	@include breakpoint($bp-bravo) {
		//padding: 17px 15px;		
		//max-width: 350px;
	}
	@include breakpoint($bp-charlie) {
		padding: 17px 15px;
		max-width: 350px;
		min-width: 300px;
	}
	@include breakpoint($bp-delta) {
			
	}
	&:hover {
		background: $teal;
		color: $sec-text-color-hover;
	}
	&.alt {
		border: 2px solid $pastel-teal;
		color: $charcoal;
		&:hover {
			color: $light-blue;
			border: 2px solid $charcoal;
			background: $charcoal;
		}
	}
}

.video-button,
.play-button {
	.arrow {
		fill: $play-triangle-color;
		transition: 0.35s;

	}
	.circle{
		fill: $play-circle-color;
		transition: 0.35s;

	}
	&:hover {
		.arrow {
			fill: $play-triangle-hover-color;
		}
		.circle {
			fill: $play-circle-hover-color;
		}
	}

}

.video-button {
	position: relative;
	width: 55px;
	height: 55px;
	cursor: pointer;
	margin: 25px auto 0;
	//transition: transform 0.2s;
	@include breakpoint($bp-bobby) {
  		width: 75px;
 		height: 75px;
		position: absolute;
		bottom: 10px;
		right: 25px;
		margin: 0;
	}
	.btn {
		border: 0;
		max-width: auto;
		min-width: auto;
		width: 55px;
		height: 55px;
		background: $play-circle-color;
		position: relative;
		outline: none;
		@include breakpoint($bp-bobby) {
			width: 80px;
			height: 80px;
		}
		&:hover {
			@include transform(scale(1.17));
			background: $play-circle-hover-color;
			.arrow {
				border-left-color: $play-triangle-hover-color;
			}
		}
		.arrow {
			position: absolute;
			width: 0; 
			height: 0; 
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;
			border-left: 17px solid $play-triangle-color;
			left: 6px;
			right: 0;
			margin: 0 auto;
			top: 17px;
			opacity: 1;
			transform: scale(1);
			transition: transform .4s 0s, opacity .2s 0s;
	        @include breakpoint($bp-bobby) {
	        	border-top: 14px solid transparent;
				border-bottom: 14px solid transparent;
				border-left: 23px solid $play-triangle-color;
	          	top: 26px;
	        }
		}
		&.to-circle {
			.arrow {
				transition: transform .3s .1s, opacity .3s .2s;
				transform: scale(0);
				opacity: 0;
			}
		}
	}
}