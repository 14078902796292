input[type="text"], input[type="email"], textarea {
	background: $white;
	height: 45px;
	padding: 10px 20px;
	border-radius: 0;
	@include transition(background 0.25s ease);
	float: left;
	width: 100%;
	outline: none;
	border: 2px solid $white;
	color: $body;
	font-size: 20px;
	font-family: $primary-font;
	@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
	&:focus {
		border: 2px solid $cyan;
	}
	&::-webkit-input-placeholder {
	  color: $grey;
	}
	&::-moz-placeholder {
	  color: $grey;
	}
	&:-ms-input-placeholder {
	  color: $grey;
	}
	&:-moz-placeholder {
	  color: $grey;
	}
}

input[type="submit"] {
	&.btn {
		float: left;
		width: 100%;
	}
}

button {
	&.btn {
		float: left;
		width: 100%;
	}
}

body .gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
	max-width: 100%!important;
	width: 100%!important;
}

.gform_confirmation_message {
	color: $white!important;
	text-align: center!important;
	font-size: 23px!important;
}

.content {
	.gform_wrapper {
		margin-top: 35px;
		.gform_body {
			width: 100%!important;
		}

		.ginput_container {
			margin-top: 0!important;
		}
		.gfield_label {
			margin-top: 0!important;
    		font-family: $headline-font; 
			color: $headlineColor;
			font-weight: 600;
			font-size: 16px;
			line-height: 130%;
			margin: 0 0 .5em;
			display: none;
			@include breakpoint($bp-charlie) {
				font-size: 18px;
			}
			@include breakpoint($bp-bobby) {
				font-size: 20px;
			}
		}
		.validation_error {
			color: $white;
		    font-size: 14px;
		    font-weight: 700;
		    font-family: $headline-font;
		    margin-bottom: 15px;
		    border: 2px solid #FF3232;
		    background: #FF3232;
		    padding: 15px;
		    clear: both;
		    width: 100%;
		    text-align: center;
		    line-height: 135%;
		}
		li.gfield.gfield_error, .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
			background: transparent;
			border-top: none;
    		border-bottom: none;
    		.gfield_label, .gfield_required {
    			color: $body;
    		}
    		.gfield_label {
    			margin-top: 0!important;
    			font-family: $headline-font; 
				color: $headlineColor;
				font-weight: 700;
				font-size: 13px;
				line-height: 130%;
				margin: 0 0 .5em;
				@include breakpoint($bp-charlie) {
					font-size: 18px;
				}
				@include breakpoint($bp-bobby) {
					font-size: 20px;
				}
    		}
    		.gfield_description.validation_message {
    			margin-top: 0;
    			padding-top: 0;
    			color: $primary;
    			font-family: $headline-font;
    			font-weight: 700;
    			font-size: 13px;
    			line-height: 120%;
    		}
    		input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .gform_wrapper li.gfield_error textarea {
    			border: 2px solid $pink!important;
    		}
		}
		#gform_ajax_spinner_1 {
			margin: 15px auto;
    		float: none;
    		display: table;
    		max-width: 25px!important;
    		max-height: 25px!important
		}
		ul {
			&.gform_fields {
				li {
					&.gfield {
						//padding-right: 0px;
						float: left;
						width: 100%;
						max-width: 100%;
						margin: 0 0 20px;
						&.radio .ginput_container_radio {
							border: 2px solid #fff;
							background: #fff;
							padding: 5px 10px!important;
							font-size: 12px!important;
						}
						.ginput_recaptcha {
							text-align: center;
							div {
								margin: 0 auto !important;
								@include breakpoint($bp-charlie-sm) {
									padding-left: 4px !important;
								}
							}
						}
					}
					input[type="text"], input[type="email"], textarea {
						border: 2px solid #fff;
						padding: 5px 10px!important;
						font-family: $headline-font!important;
						font-size: 14px!important;
						font-weight: 700!important;
						&:focus {
							border: 2px solid $primary;
						}
					}
				}
				.gfield_radio {
					margin: 0px;
					label {
						font-size: 12px;
						margin: 5px 0 0 4px;
					}
				}
			}
		}
		.gform_footer {
			margin: 0px;
			padding: 0px;
			text-align: center;
			input[type="submit"] {
				display: inline-block;
				color: $charcoal;
				width: auto;
				@include hover-fade();
				cursor: pointer;
				background: $light-blue;
				border: 2px solid $light-blue;
				padding: 18px 15px 16px;
				border-radius: 50px;
				line-height: 119% !important;
				// min-width: 300px;
				max-width: 350px;
				width: 100%;
				font-size: 13px;
				text-transform: uppercase;
				letter-spacing: 0.08em;
				font-weight: 700;
				font-family: $headline-font;
				text-align: center;
				cursor:pointer;
				&:hover {
					background: $charcoal;
					border: 2px solid $charcoal;
					color: $light-blue;
				}
			}
		}
	}
}