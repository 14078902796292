.work-wrapper {
	width: 100%;
	background: $bg-light;
	float: left;
	padding: $mobile-section-padding 0 calc( #{$mobile-section-padding} - 25px );
	@include breakpoint($bp-charlie) {
		padding: 100px 0;
	}	
}
.work-page {	
	.sub-nav {
		padding: 0;
	}
	.page-title {
		font-family: $h2Font; 
		font-size: $h2Mobile;
		line-height: 135%;
		margin: 0 0 .5em;
		letter-spacing: -0.01em;
		@include breakpoint($bp-charlie) {
			font-size: $h2Tablet;
		}
		@include breakpoint($bp-bobby) {
			font-size: $h2;
		}
	}
	.content {
		.col-md-10 {
			margin: 0 auto;
			float: none;
		}
	}
	.intro {
		float: left;
		width: 100%;
		padding: 65px 0 65px;
		@include breakpoint($bp-charlie) {
			padding: 80px 0 100px;
		}
		.container {
			// max-width: 800px;
			// width: 100%;
			h5 {
				text-align: center;
				margin: 0;
				font-size: 23px;
				max-width: 700px;
				margin: 0 auto;
				@include breakpoint($bp-charlie) {
					font-size: 25px;
				}
				@include breakpoint($bp-bobby) {
					font-size: 28px;
				}
			}
			hr {
				margin: 30px auto;
				width: 38px;
				background: $hrColorShort;
				height: 2px;
			}
			.description {
				margin: 0 auto;
				max-width: 660px;
				width: 100%;
				p {
					text-align: center;
					font-size: 18px;
					@include breakpoint($bp-bobby) {
						font-size: 21px;
					}
					
					&:last-of-type {
						margin: 0;
					}
				}
			}
		}
	}

	.biz-wrapper {
		margin-bottom: $mobile-section-padding;
		@include breakpoint($bp-charlie) {
			margin-bottom: 80px;
		}

		h3 {
			text-align: center;
			margin-bottom: 1.5em;
		}
	}

	.biz-item-wrap {
		padding-left: 10px;
		padding-right: 10px;
	}

	.biz-item {
		background: $white;
		min-height: 245px;
		position: relative;
		margin-bottom: 1em;
		@include breakpoint($bp-delta) {
			margin-bottom: 0;
			min-height: 285px;
		}

		.description p {
			font-size: 17px;
			margin-bottom: 0.5em;
		}
		.biz-item-content {
			padding: 25px;
			@include vert-center;
			position: absolute;
		}
	}

	.work-modules {
		float: left;
		width: 100%;
		overflow: hidden;

		.single-module {
			height: auto;
			background: transparent;
			width: 100%;
			display: block;
			position: relative;
			float: left;
			margin-bottom: 25px;
			@include breakpoint($bp-bobby) {
				
			}
			&:nth-of-type(2n) {
				.container {
					.icon-wrapper {
						float: right;
					}
				}
			}
			.container {
				//background: $white;
				min-height: 350px;
				@include breakpoint($bp-delta) {
					min-height: 400px;
				}				
				.icon-wrapper {
					width: 100%;
					min-height: 250px;
					height: 100%;
					position: relative;
					float: left;
					background: $pastel-orange;
					@include breakpoint($bp-charlie) {
						width: 38%;
						min-height: 350px;
					}
					@include breakpoint($bp-delta) {
						width: 38%;
						min-height: 400px;
					}
				}

				.icon {
					width: 100px;
					position: absolute;
					margin: 0 auto;
					@include center;
				}	
			}
			&.purple {
				.container {
					.icon-wrapper {
						background: $lavender;
					}
					.work-type-content {
						.read-more {
							.btn {
								border: 2px solid $purple;
								//color: $purple;
								&:hover {
									background: $purple;
								}
							}
						}
					}
				}
			}
			&.pink {
				.container {
					.icon-wrapper {
						background: $pastel-pink;
					}
					.work-type-content {
						.read-more {
							.btn {
								border: 2px solid $pink;
								//color: $pink;
								&:hover {
									background: $pink;
								}
							}
						}
					}
				}
			}
			&.teal {
				.container {
					.icon-wrapper {
						background: $pastel-teal;
					}
					.work-type-content {
						.read-more {
							.btn {
								border: 2px solid $teal;
								//color: $teal;
								&:hover {
									background: $teal;
								}
							}
						}
					}
				}
			}
			.container {
				position: relative;
				height: auto;
				overflow: hidden;
				//padding: 50px 35px;
				@include breakpoint($bp-bobby) {
					//height: 100%;
					//padding: 50px 0;
				}
				.credit {
					position: absolute;
					bottom: 35px;
					left: 40px;
					display: none;
					@include breakpoint($bp-bobby) {
						display: block;
					}
				}

				.work-type-content {
					background: $white;
					width: 100%;
					float: left;
					height: 100%;
					min-height: 325px;
					position: relative;
					padding: 0;
					@include breakpoint($bp-charlie) {
						width: 62%;
						min-height: 350px;
					}
					@include breakpoint($bp-bobby) {
						// width: 62%;
						min-height: 400px;
					}
					.content {																		
						padding: 50px 30px;
						text-align: center;
						@include breakpoint($bp-charlie) {
							text-align: left;
						}
						@include breakpoint($bp-delta) {
							padding: 0 100px;							
							@include vert-center;
							position: absolute;
						}
					}

					h6 {
						color: $orange;
						margin-bottom: 20px;
						text-align: center;
						@include breakpoint($bp-bobby) {
							text-align: left;
						}
					}
					h1 {
						color: $white;
						margin-bottom: 20px;
						text-align: center;
						@include breakpoint($bp-bobby) {
							text-align: left;
						}
					}
					.description {
						margin-bottom: 1.5em;
						p {
							//color: $white;
							//line-height: 165%;
							text-align: center;
							@include breakpoint($bp-charlie) {
								text-align: left;
							}
							&:last-of-type {
								margin: 0;
							}
						}
					}
					.read-more {
						overflow: hidden;
						text-align: center;
						@include breakpoint($bp-bobby) {
							text-align: left;
						}
						.btn {
							display: inline-block;
							//max-width: 100%;
							min-width: auto;
							border: 2px solid $orange;
							color: $charcoal;
							padding: 18px 31px 16px;							
							@include breakpoint($bp-charlie) {
								min-width: 300px;
								max-width: 325px;
								float: left;
							}
							&:hover {
								background: $orange;
								color: $white;
							}
						}
					}
				}
				.right-partners {
					max-width: 465px;
					width: 100%;
					position: relative;
					top: auto;
					@include transform(translateY(0));
					right: auto;
					margin: 50px auto 0;
					@include breakpoint($bp-bobby) {
						margin-top: 0;
						max-width: 465px;
						width: 100%;
						position: absolute;
						top: 50%;
						@include transform(translateY(-50%));
						right: 40px;
					}
					h3, h5 {
						font-size: 26px;
						line-height: 140%;
						color: $white;
						text-align: center;
						margin-bottom: 40px;
						font-family: $primary-font;
						font-weight: 400;
					}
					h6 {
						text-align: center;
						color: $grey;
						margin-bottom: 20px;
					}
					.logos {
						float: left;
						width: 100%;
						text-align: center;
						.single-logo {
							display: inline-block;
							width: calc(95%/3);
							height: 50px;
							padding: 10px 0;
							img {
								max-width: 50%;
								max-height: 50px;
								filter: brightness(0) invert(1);
								@include breakpoint($bp-bobby) {
									max-width: 75%;
									max-height: 65px;
								}
							}
						}
					}
				}
				.video {
					width: 100%;
					max-width: 400px;
					height: 320px;
					position: relative;
					right: auto;
					bottom: auto;
					cursor: pointer;
					background-size: cover!important;
					margin: 50px auto 0;
					@include breakpoint($bp-charlie) {
						height: 360px;
					}
					@include breakpoint($bp-bobby) {
						margin-top: 0;
						max-width: 100%;
						width: 480px;
						height: 550px;
						position: absolute;
						right: 0;
						bottom: -25px;
					}
					@include breakpoint(1200px) {
						width: 670px;
						height: 630px;
					}
					.full-link {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 10;
						outline: none;
					}
					.play-button {
						width: 65px;
						height: 65px;
						position: absolute;
						top: 50%;
						@include transform(translateY(-50%));
						left: 0;
						right: 0;
						margin: 0 auto;
						svg {
							width: 100%;
							// .st1 {
							// 	fill: $primary;
							// }
							// .st0 {
							// 	fill: #000;
							// }
						}
					}
				}
				.quote {
					max-width: 465px;
					width: 100%;
					position: relative;
					top: auto;
					@include transform(translateY(0));
					right: auto;
					padding: 13px;
					margin: 50px auto 0;
					@include breakpoint($bp-bobby) {
						margin-top: 0;
						max-width: 465px;
						width: 100%;
						position: absolute;
						top: 50%;
						@include transform(translateY(-50%));
						right: 40px;
					}
					.image {
						width: 220px;
						height: 220px;
						background-position: 50% 50%;
						background-size: cover!important;
						border: 8px solid $white;
						margin: 0 auto 30px;
					}
					p {
						text-align: center;
						color: $white;
						font-size: 18px;
						line-height: 150%;
						margin-bottom: 25px;
						@include breakpoint($bp-charlie) {
							font-size: 21px;
						}
						@include breakpoint($bp-bobby) {
							//font-size: 23px;
						}
					}
					h6 {
						text-align: center;
						color: $light-grey;
					}
				}
			}
		}
		// .strip {
		// 	height: 47px;
		// 	width: 100%;
		// 	@include breakpoint($bp-bobby) {
		// 		height: 77px;
		// 	}
		// 	background-position: top center;
		// 	background-size: cover!important;
		// 	background: url('img/tent-pattern-border-gold.png');
		// 	&.purple {
		// 		background: url('img/tent-pattern-border-purple.png');
		// 	}
		// 	&.red {
		// 		background: url('img/tent-pattern-border-red.png');
		// 	}
		// }
	}
}

.single-post.work {
	.additional-resources {
		float: left;
		width: 100%;
		background: $charcoal;
		position: relative;
		padding: 0 35px;
		.single-grid p {
			font-size: 15px;
			line-height: 145%;
			&.date {
				font-size: 13px;
				font-style: italic;
			}
		}
		.background {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2;
			width: 100%;
			height: 100%;
			opacity: 0.35;
			background-size: cover!important;
		}
		.container {
			max-width: 650px;
			width: 100%;
			//padding: 45px 0;
			position: relative;
			z-index: 5;
			.credit {
				text-align: right;
				padding: 0 35px;
				width: 98%;
				position: absolute;
				bottom: 26px;
			}
			@include breakpoint($bp-bobby) {
				padding: 80px 0;
				max-width: calc(1110px + 160px);
			}
			.more {
				display: table;
				width: 100%;
				margin-top: 10px;
				text-align: center;
				a {
					svg {
						width: 12px;
						height: 12px;
						margin-left: 4px;
						transform: translateY(-1px);
					}
				}
				.btn {
					max-width: 250px;
					width: 100%;
					display: inline-block;
					color: $white;
					float: none;
					&:hover {
						color: $btn-text-hover-color;
					}
				}
			}
			.single-grid {
				width: 100%;
			    margin: 0 auto 20px;
			    padding: 30px 0 50px;
			    @include hover-fade();
			    height: auto;
			    position: relative;
			    overflow: hidden;
			    &:last-of-type {
			    	&:after {
			    		opacity: 0;
			    		display: none!important;
			    	}
			    }
			    @include breakpoint($bp-bobby) {
			    	float: none;
					width: 100%;
					height: auto;
					max-width: 624px;
					overflow: visible;
			    }
			    .meta, h3, h5, p, .more {
			    	text-align: center;
			    }
			    .more a {
			    	margin: 0 8px;
			    }
			    &:after {
				 	width: 100%;
			    	@include breakpoint($bp-bobby) {
			    		width: 100%;
			    	}
			    }
			    .overlay-share {
			    	right: 0;
			    }
			    &.big-grid {
			    	width: 100%;
				    margin: 0 0 20px;
				    padding: 20px 0 40px;
				    @include hover-fade();
				    height: auto;
				    position: relative;
				    overflow: hidden;
				    @include breakpoint($bp-bobby) {
				    	float: left;
				    	padding: 10px 4%;
						width: calc(96%/2);
						min-height: 350px;
						overflow: visible;
				    }
				    &:nth-of-type(3), &:nth-of-type(4) {
				    	&:after {
				    		display: none;
				    	}
				    }
				    &:after {
				    	width: 100%;
				    	@include breakpoint($bp-bobby) {
				    		width: 84%;
				    	}
				    }
				    .overlay-share {
				    	right: 7%;
				    }
				    .meta, h3, h5, p, .more {
			 	   		text-align: left;
				    }
				    .more a {
				    	margin-left: 0;
				    	margin-right: 30px;
				    }
			    }
			    @include breakpoint($bp-bobby) {
				    &:hover {
				    	.overlay-share {
				    		opacity: 1;
				    	}
				    }
				}
			    &:after {
			    	content: '';
			    	position: absolute;
			    	width: 100%;
			    	height: 2px;
			    	background: $light-grey;
			    	bottom: 0;
			    	left: 0;
			    	right: 0;
			    	margin: 0 auto;
			    	@include hover-fade();
			    }
			    .overlay-share {
			    	width: 110px;
			    	overflow: hidden;
			    	position: absolute;
			    	bottom: 10px;
			    	@include hover-fade();
			    	opacity: 0;
			    	small {
			    		text-transform: uppercase;
				    	font-size: 11px;
				    	color: $grey;
				    	letter-spacing: 0.08em;
				    	text-align: right;
				    	padding-right: 8px;
				    	padding-top: 5px;
			    	}
			    	.social {
			    		float: left;
			    		width: 100%;
			    		margin: 0;
			    		padding: 0;
			    		ul {
			    			margin: 0;
			    			padding: 0;
			    			float: left;
			    			width: 100%;
			    			li {
			    				width: calc(100%/3);
			    				float: left;
			    				a {
			    					padding: 0;
			    					text-align: center;
			    					float: left;
			    					width: 100%;
			    					color: $light-blue;
			    					&:hover {
			    						color: $orange;
			    					}
			    				}
			    			}
			    		}
			    	}
			    	small {
			    		float: left;
			    		width: 100%;
			    	}
			    }
			    .meta {
			    	margin-bottom: 15px;
			    	span {
			    		text-transform: uppercase;
			    		color: $white;
			    		font-family: $headline-font;
			    		font-size: 11px;
			    		font-weight: 700;
			    		letter-spacing: 0.08em;
			    		margin-right: 15px;
			    	}
			    }
			    h3, h5 {
			    	margin-bottom: 0.35em;
			    	color: $white;
			    }
			    p {
			    	margin-bottom: 0;
			    	color: $white;
			    }
			    .more {
			    	display: block;
			    	margin-right: 30px;
			    	a {
			    		display: inline-block;
			    		font-family: $headline-font;
			    		font-weight: 700;
			    		font-size: 14px;
			    		color: $light-blue;
			    		&:hover {
			    			color: $teal;
			    		}
			    	}
			    }
			}
		}
	}
}