// utilities
@import "utilities/mixins";
@import "utilities/variables";

// base
@import "base/sanitize";
@import "base/base";
@import "base/bootstrap";

// elements
@import "elements/hamburgers/settings";
@import "elements/hamburgers/hamburgers";
@import "elements/font-awesome";
@import "elements/buttons";
@import "elements/forms";
@import "elements/social";
@import "elements/popup";
@import "elements/overlays";
@import "elements/fancybox";
@import "elements/animate";
@import "elements/slick-slider";
@import "elements/logo";
@import "elements/scrollbars";

//plugins
@import "plugins/magnific";
@import "plugins/select"; 

// global
@import "global/typography";
@import "global/header";
@import "global/footer";
@import "global/sidebar";
//@import "global/typography";
@import "global/layout";
@import "global/topper";
@import "global/modules";
@import "global/impact-stories";
@import "global/alert-bars/footer";

// pages
@import "pages/home";
@import "pages/landing";
@import "pages/issues";
@import "pages/news";
@import "pages/webinars";


@import "pages/event";
@import "pages/list";
@import "pages/grid";
@import "pages/become-partner";
@import "pages/media";
@import "pages/partners";
@import "pages/about";
@import "pages/resources";
@import "pages/template-initiative";
@import "pages/template-preferred-partner";
@import "pages/our-work";
@import "pages/member-portal";
@import "pages/video-module";
//@import "pages/quiz";

// Sass to JS, used for Style Guide.
@import "pages/style-guide";