.page-template-template-preferred-partners-page {

  .module {

    // Add padding to modules for spacing instead of margin.  Margin leaves a white
    // gap between sections (this doesn't work for sections with a grey bg.
    // We also exclude the intro module (which will always be first) from these styles
      &:not(:nth-of-type(1)) {
        padding:50px 0;
        margin-top: unset;
      }


    &:nth-of-type(2) {
      margin-top: 40px;
      padding-top:100px;
      @include breakpoint($bp-charlie) {
        margin-top: 55px;
      }
      @include breakpoint($bp-bobby) {
        margin-top: 60px;
      }
    }

    &.last-module {
      padding-bottom: 100px;
    }

  }

}