.home-work + .video-module-layout {
	padding: 0;
	@include breakpoint($bp-charlie) {
		padding: 120px 0 45px;
	}
}

.home-stories + .video-module-layout {
	padding: $mobile-section-padding 0;
	@include breakpoint($bp-charlie) {
		padding: 10px 0 120px;
	}
}

.our-impact + .video-module-layout {
	@include breakpoint($bp-charlie) {
		padding: 120px 0 0;
	}
}

.impact-grid + .video-module-layout {
	@include breakpoint($bp-charlie) {
		padding: 120px 0 0;
	}
}

.partners-layout + .video-module-layout {
	@include breakpoint($bp-charlie) {
		padding: 0 0 120px;
	}
}

.module.intro + .video-module-layout {
	background-color: $white;
	padding: unset;
}

.video-module-layout {
	display: flex;
	background:$pale-grey;
	padding: 0 0 $mobile-section-padding;
	float: none !important;
	width: 100%;
	@include breakpoint($bp-charlie) {
		padding: 120px 0;
	}
	.container {
		background-color: #fff;
		padding-top: 2.5rem;
		padding-bottom: 2.5rem;
		max-width: calc(1110px + 160px);
		width: 100%;
		display: flex;
		align-items: center;

		@media (max-width: 768px) {
			display: block;
		}

		.vid-border {
			position: relative;
			width: 100%;
		    margin: 2.75rem;
		    
		    @media only screen and (max-width: 800px) and (min-width: 600px ) {
	    		margin: 2rem;
	    		width: auto;
	    	}

	    	@include breakpoint(599px, max) {
	    		margin: 1.5rem;
	    		width: auto;
	    	}

			.tri-one {
				width: 0;
				height: 0;
				border-style: solid;
				border-color: transparent transparent transparent $orange;
				position: absolute;
		    	border-width: 150px 0 150px 300px;
	    		top: -50px;
	    	    right: -45px;

	    	    &.w-text {
    	    		border-width: 100px 0 100px 200px;
    		    	top: -30px;
    	    	    right: -25px;
	    	    }

			    @media only screen and (max-width: 800px) and (min-width: 600px ) {
    	    		border-width: 100px 0 100px 200px;
    		    	top: -30px;
    	    	    right: -25px;

    	    	    &.w-text {
		    	    	border-width: 50px 0 50px 100px;
		        		top: -20px;
	        		    right: -15px;
    	    	    }
    	    	}

	    	    @include breakpoint(599px, max) {
	    	    	border-width: 50px 0 50px 100px;
	        		top: -20px;
        		    right: -15px;

        		    &.w-text {
		    	    	border-width: 50px 0 50px 100px;
		        		top: -20px;
	        		    right: -15px;
        		    }
	    	    }
			}

			.tri-two {
				width: 0;
				height: 0;
				border-style: solid;
				border-color: transparent $pink transparent transparent;
				position: absolute;
        	    border-width: 150px 300px 150px 0;
        	    bottom: -50px;
        	    left: -50px;

        	    &.w-text {
					border-width: 100px 200px 100px 0;
    	    		bottom: -30px;
    	    		left: -25px;
        	    }

	    	   @media only screen and (max-width: 800px) and (min-width: 600px ) {
					border-width: 100px 200px 100px 0;
    	    		bottom: -30px;
    	    		left: -25px;

    	    		&.w-text {
		    	    	border-width: 50px 100px 50px 0;
		        		bottom: -20px;
	        		    left: -15px;
    	    		}
    	    	}

	    	    @include breakpoint(599px, max) {
	    	    	border-width: 50px 100px 50px 0;
	        		bottom: -20px;
        		    left: -15px;

        		    &.w-text {
		    	    	border-width: 50px 100px 50px 0;
		        		bottom: -20px;
	        		    left: -15px;
        		    }
	    	    }
			}
		}

		.col-xs-12 .vid-border {
			width: auto;
		}

	    video {
			width: 100%    !important;
			height: auto   !important;
			position: relative;
	    }

		.embed-container {
			position: relative;
			padding-bottom: 56.25%;
			height: 0; overflow: hidden;
			max-width: 100%;

			iframe, object, embed {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

		.wrapper {
			position: relative;
			margin: 0 auto;
			h6 {
				color: $middle-grey;
				margin-bottom: 10px;
			}
			h2 {
				color: $charcoal;
				margin-bottom: 20px;
			}
			.description {
				margin-bottom: 35px;
				p {
					color: #444444;
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
			.btn {
				display: inline-block;
				min-width: unset !important;
				&:hover {
					background: $teal;
				}
			}
		}
	}
}

body.home {

	.video-module-layout {
		background-color: $white;
		padding: unset;
	}
}