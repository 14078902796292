.grid-page {
	overflow: hidden;
	.introduction {
		width: 100%;
		display: table;
		padding: 75px 0;
		padding-top: 0;

		.container {
			max-width: 660px;
			margin: 0 auto;
		}
		h2 {
			text-align: left;
		}
		p {
			&:last-of-type {
				margin: 0;
			}
		}
	}
	.content {
		text-align: center;
		padding: 45px 0;
		padding-top: 0;
		@include breakpoint($bp-bobby) {
			padding: 85px 0 50px;
			padding-top: 0;
		}
	}
	.section-break {
	    height: 2px;
	    background: #00C7C4;
	    margin: 0 auto;

	    width: 80%;
	    max-width: 512px;
	    @include breakpoint($bp-bobby) {
	    	max-width: 1080px;
	    }
	}
	.emeritus_header {
		margin-top: 6rem;
	    margin-bottom: 4rem;
	    color: $middle-grey;
	    font-size: 22px;
	    @include breakpoint($bp-charlie) {
	    	font-size: 24px
	    }
	    @include breakpoint($bp-bobby) {
	    	font-size: 31px;
	    }
	}
	.people {
		max-width: 640px;
		margin: 0 auto;
		margin-bottom: 6rem;
		box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
		position: relative;
		width: 100%;
		display: inline-block;
		padding:0;
		@include breakpoint($bp-bobby) {
			max-width: 1350px;
			padding: 0;
			box-shadow: 0px 8px 60px 0px rgba(0, 0, 0, 0.15);
		}
		.featured-person {
			float: left;
			width: 100%;
			height: auto;
			position: relative;
			overflow: hidden;
			margin-bottom: 25px;
			@include breakpoint($bp-bobby) {
				height: 630px;
				margin: 0;
			}
			a.partnerOverlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 5;
				outline: none;
			}
			&:hover {
				.content {
					background: $primary;
					.wrapper .name {
						color: $charcoal;
					}
				}
				.image:before {
					border-right: 30px solid $primary; 
				}
				.social-feed {
					a {
						color: $charcoal;
					}
				}
			}
			.social-feed {
				position: relative;
				margin: 0 auto;
				text-align: center;
				@include breakpoint($bp-bobby) {
					position: absolute;
					bottom: 75px;
					left: 0;
					right: 0;
				}
				a {
					color: $primary;
					font-size: 24px;
					margin: 0 10px;
					position: relative;
					z-index: 6;
					@include breakpoint($bp-bobby) {
						font-size: 30px;
					}
					&:hover {
						color: $white;
					}
				}
			}
			.image {
				float: left;
				width: 100%;
				height: 350px;
				background-position: top center;
				background-repeat: no-repeat;
				background-size: cover!important;
				position: relative;
				@include breakpoint($bp-charlie) {
					height: 400px;
				}
				@include breakpoint($bp-bobby) {
					float: left;
					width: 50%;
					height: 100%;
				}
				@include breakpoint($bp-bobby) {
					&:before {
						content: '';
						position: absolute;
						right: 0;
						top: 50%;
						@include transform(translateY(-50%));
						width: 0; 
						height: 0; 
						border-top: 24px solid transparent;
						border-bottom: 24px solid transparent; 
						@include hover-fade();
						border-right: 30px solid $pale-grey; 
					}
				}
			}
			.content {
				width: 100%;
				@include hover-fade();
				height: auto;
				position: relative;
				background: $pale-grey;
				@include breakpoint($bp-bobby) {
					height: 100%;
					width: 50%;
				}
				&.right {
					float: right;
				}
				.wrapper {
					padding: 0 20px 20px;
					position: relative;
					width: 100%;
					margin: 0 auto;
					@include breakpoint($bp-bobby) {
						top: 50%;
						padding: 25px 95px;
						@include transform(translateY(-50%));
						left: 0;
						right: 0;
					}
					.quote {
						font-size: 17px;
						line-height: 150%;
						color: $charcoal;
						text-align: center;
						//font-style: italic;
						margin: 0;
						@include breakpoint($bp-bobby) {
							font-size: 24px;
						}
					}
					.name {
						margin-top: 25px;
						text-align: center;
						text-transform: uppercase;
						font-family:  $headline-font;
						color: $charcoal;
						letter-spacing: 0.350em;
						font-size: 13px;
						font-weight: 700;
						@include hover-fade();
						span {
							color: $charcoal;
							text-transform: none;
							letter-spacing: 0;
							font-size: 18px;
						}
					}
				}
			}
		}
		.grid {
			float: left;
			width: 100%;
			margin-bottom: 0;
			.single {
				float: left;
				width: 100%;
				height: auto;
				position: relative;
				@include breakpoint($bp-bobby) {
					height: 350px;
					width: 50%;
				}
				a.partnerOverlay {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 5;
					outline: none;
				}
				&:hover {
					.content {
						background: $primary;
						&:before {
							opacity: 0;
						}
						.wrapper .name {
							color: $charcoal;
						}
					}
					.image:before {
						border-right: 25px solid $primary; 
					}
					.social-feed {
						a {
							color: $charcoal;
						}
					}
				}
				.social-feed {
					position: relative;
					margin: 0 auto;
					text-align: center;
					@include breakpoint($bp-bobby) {
						position: absolute;
						bottom: 35px;
						left: 0;
						right: 0;
					}
					a {
						color: $primary;
						font-size: 20px;
						margin: 0 7px;
						position: relative;
						z-index: 6;
						&:hover {
							color: $white;
						}
					}
				}
				.image {
					float: left;
					width: 100%;
					height: 350px;
					background-position: top center;
					background-repeat: no-repeat;
					background-size: cover!important;
					position: relative;
					@include breakpoint($bp-charlie) {
						height: 400px;
					}
					@include breakpoint($bp-bobby) {
						float: left;
						width: 50%;
						height: 100%;
					}
					@include breakpoint($bp-bobby) {
						&:before {
							content: '';
							position: absolute;
							right: 0;
							top: 50%;
							@include transform(translateY(-50%));
							width: 0; 
							height: 0; 
							border-top: 20px solid transparent;
							border-bottom: 20px solid transparent; 
							border-right: 25px solid $white; 
							@include hover-fade();
						}
					}
				}
				.content {
					width: 100%;
					height: auto;
					position: relative;
					background: $white;
					@include hover-fade();
					@include breakpoint($bp-bobby) {
						width: 50%;
						height: 100%;
					}
					&:before {
						position: absolute;
						top:0;
						content: '';
						width: 100%;
						left: 0;
						height: 30%;
						background: -moz-linear-gradient(top, rgba(233,233,233,1) 0%, rgba(125,185,232,0) 100%);
						background: -webkit-linear-gradient(top, rgba(233,233,233,1) 0%,rgba(125,185,232,0) 100%);
						background: linear-gradient(to bottom, rgba(233,233,233,1) 0%,rgba(125,185,232,0) 100%);
						@include hover-fade();
					}
					.wrapper {
						padding: 30px;
						position: relative;
						width: 100%;
						margin: 0 auto;
						@include breakpoint($bp-bobby) {
							position: absolute;
							top: 50%;
							@include transform(translateY(-50%));
							left: 0;
							right: 0;
						}
						.name {
							
							text-align: center;
							text-transform: uppercase;
							font-family:  $headline-font;
							color: $middle-grey;
							letter-spacing: 0.08em;
							font-size: 13px;
							line-height: 165%;
							font-weight: 700;
							@include breakpoint($bp-bobby) {
								margin-top: 25px;
							}
							span {
								color: $charcoal;
								text-transform: none;
								letter-spacing: 0;
								font-size: 18px;
								display: block;
								margin-bottom: 0.5em;
								@include hover-fade();
							}
						}
					}
				}
				&.right {
					.image {
						float: right;
						&:before {
							@include transform(translateY(-50%) rotate(180deg));
							right: auto;
							left: 0;
						}
					}
				}
			}
		}

		&.emeritus {
			width: 80%;
			max-width: 512px;
			@include breakpoint($bp-bobby) {
				max-width: 1080px;
			}

			.grid {

				.single {
					@include breakpoint($bp-bobby) {
						height: 270px;
					}

					.image:before {
						display: none; 
					}

					&:hover {
						.content {
							background: $white;
							&:before {
								opacity: 1;
							}
							.wrapper .name {
								color: $middle-grey;
							}
						}
						.image:before {
							display: none; 
						}
					}
				}
			}
		}
	}
	
	.module-wrap {
		float: left;
		width: 100%;
		margin-top: 75px;
	}
}

.impact-grid {
	width: 100%;
	display: table;
	margin-top: 0;
	padding: $mobile-section-padding 0 0;
	position: relative;
	text-align: center;
	@include breakpoint($bp-bobby) {
		margin-top: -25px;
		padding:  0 0 65px;
	}
	.single-impact {
		max-width: 450px;
		width: 100%;
		background: $pastel-orange;
		box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.15);
		z-index: 5;
		margin-left: auto;
		margin-right: $single-work-margin;
		margin-bottom: 25px;
		position: relative;
		overflow: hidden;
		text-align: center;
		border-bottom: 4px solid $orange;
		transition: all 0.35s;
		
		float: none;
		display: inline-block;
		@include breakpoint($bp-bravo) {
			width: calc( (100% - ( #{$single-work-margin}) ) / 2);
			max-width: 100%;
		}
		@media only screen and (max-width: $bp-bobby) and (min-width: $bp-bravo ) {
			&:nth-child(2n) {
				margin-right: 0;
			}
		}
		@include breakpoint($bp-bobby) {
			width: calc( (100% - ( #{$single-work-margin} * 3) ) / 4);
			max-width: 100%;
			height: 270px;
			margin-bottom: 0;
			&:hover {
				margin-top: -15px;
				.wrapper {
					bottom: auto;
					top: 50%;
					padding: 25px 30px;
					@include transform(translateY(-50%));
					p {
						opacity: 1;
						height: auto;
					}
				}
				.icon svg {
					animation: fadeOutZoom 0.25s forwards;
				}
			}
		}
		&:nth-child(2) {
			background: $lavender;
			border-color: $purple;
			.icon {
				svg {
					fill: $purple !important;
				}
			}
			.wrapper {
				.number {
					color: $purple;
				}
			}
		}
		&:nth-child(3) {
			background: $pastel-teal;
			border-color: $teal;
			.icon {
				svg {
					fill: $teal !important;
				}
			}
			.wrapper {
				.number {
					color: $teal;
				}
			}
		}
		&:nth-child(4) {
			background: $pastel-pink;
			border-color: $pink;
			margin-right: 0;
			.icon {
				svg {
					fill: $pink !important;
				}
			}
			.wrapper {
				.number {
					color: $pink;
				}
			}
		}
		a {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 5;
		}
		.icon {
			position: relative;
			margin: 40px auto 0;
			width: 85px;
			height: 100px;
			display: inline-block;
			@include breakpoint($bp-bobby) {
				display: block;
				margin: 25px auto 15px;
				position: absolute;
				top: 50%;
				right: 0;
				left: 0;
				height: 140px;
				margin: 0 auto;
				@include transform(translateY(-50%));
			}
			svg {
				opacity: 1;
				fill: $orange !important;
				#shadow {
					fill: $white !important;
				}
			}
		}
		.alphanumeric-wrapper {
			padding: 10px !important; // overrides other wrapper padding.
		}
		.wrapper {
			width: 100%;
			position: relative;
			margin: 0 auto;
			text-align: center;
			//padding: 35px;
			padding: 25px 30px 35px;
			@include hover-fade();
			@include breakpoint($bp-bobby) {
				position: absolute;
				padding: 25px 30px 40px;
				//top: 50%;
				//@include transform(translateY(-50%));
				left: 0;
				right: 0;
				bottom: 0;
				//padding: 0;
			}
			.number.highlighted-word {
				position: relative;
				top: 0;
				display: table-cell;
				font-size: 35px;
				height: 135px;
				vertical-align: middle;

			}
			.number {
				font-size: 55px;
				font-family: $headline-font;
				letter-spacing: -0.01em;
				color: $orange;
				margin: 0;
				line-height: 100%;
				font-weight: 700;
				@include breakpoint($bp-bobby) {
					font-size: 75px;
				}
			}
			.title, .title p {
				font-size: 17px;
				font-family: $headline-font;
				letter-spacing: -0.01em;
				color: $charcoal;
				margin: 10px 0px;
				font-weight: 700;
				text-align: center;
				@include breakpoint($bp-bobby) {
					font-size: 18px;
				}
			}
			.description {
				font-size: 15px;
				color: $charcoal;
				margin: 15px 0 0 0;
						
				line-height: 135%;
				text-align: center;
				padding: 0;
				margin: 0;
				opacity: 1;
				@include hover-fade();
				@include breakpoint($bp-bobby) {
					height: 0;
					opacity: 0;
				}
			}
		}
	}
}