.webinars.resources-page .grid-res {
  .container {
    max-width: 930px;
    display: flex;
    flex-flow: row wrap;
    //padding: 0;
    @include breakpoint($bp-charlie) {
      padding: 0;
    }
  }

  .single-grid {
    width: 100%;
    padding: 0;
    float: none;
    height: auto;

    @include breakpoint($bp-charlie) {
      width: calc(50% - 36px);

      &:nth-child(2n+1) {
        margin-right: 70px;
      }
    }



    img {
      width: 100%;
      height: auto;
      max-height: 229px;
      object-fit: cover;
    }

    .post-details {
      padding: 35px 0;

      .post-meta,
      .post-meta a { //making these styles global
        // font-family: $headline-font;
        // text-transform: uppercase;
        // color: #999;
        // font-size: 14px;
        // font-weight: 700;
        // margin-bottom: 20px;
      }

      h5{
        //line-height: 130%;
      }
      .read-more {
        font-size: 15px;
        margin-bottom: 0;
        a {
          &:hover {
            color: $teal;
          }
          svg {
            margin-left: 5px;
          }
        }
      }

      .category {
        text-transform: uppercase;
        //padding-right: 0.5em;
      }
    }

    &:after {
      display: none;
    }

  }
}