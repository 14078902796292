.all-events {
	float: left;
	width: 100%;
	margin-bottom: 50px;
	.col-md-9 {
		margin: 0 auto;
		display: table;
		float: none;
	}
}

.event-info {
	float: left;
	width: 100%;
	.map {
		width: 100%;
		height: 400px;
		margin: 20px 0;
	}
	.map img {
	   max-width: inherit !important;
	}
}
.event-topper {
	h2 {
		text-align: center;
	}
	.meta {
		color: $purple;
		text-align: center;
	}

	.short-description {
		font-size: 23px;
		text-align: center;
		line-height: 34px;
	}
}

hr.short {
	width: 36px;
	height: 2px;
	background: $secondary;
}

.top-link {
	text-align: center;
	margin-bottom: 35px;
	margin-top: -15px;
	a {
		float: none;
		display: inline-block;
	}
}

.bottom-link {
	text-align: center;
	margin-top: 35px;
	a {
		float: none;
		display: inline-block;
	}
}

.module {
	&.full-content {
		.col-md-10 {
			float: none;
			margin: 0 auto;
		}
		.event-info {
			.col-md-8 {
				float: left;
				margin: 0px;
			}
		}
	}
}

.page-template-template-events {
	.top-introduction {
		padding-bottom: 10px;
		h3, p {
			text-align: center;
		}
	}

	.intro {
		float: left;
		width: 100%;
		margin-top: 55px;
		margin-bottom: 5px;
		.container {
			max-width: 930px;
			width: 100%;
			margin: 0 auto;
			float: none;
			text-align: center;
			p {
				font-size: 23px;
				letter-spacing: -0.01em;
				color: #000000;
				text-align: center;
				&:last-of-type {
					margin: 0;
				}
			}
			hr {
				width: 36px;
				height: 2px;
				background: $primary;
			}
		}
	}	
}

.event-info-block {
	float: left;
	width: 100%;
	margin: 0 0 20px;
}

.event-set {
	float: left;
	width: 100%;
	margin: 0 0 40px;
	@include breakpoint($bp-charlie) {
		margin: 0 0 75px;
	}
	h3 {
		text-align: center;
		margin-top: 0;
		margin-bottom: 30px;
		@include breakpoint($bp-charlie) {
			margin-bottom: 45px;
			margin-top: 0;
		}
	}
	.event-item {
		&:last-child {
			margin: 0px;
			padding: 0px;
			border-bottom: 0px;
		}
	}
}

.event-item {
	float: left;
	width: 100%;
	border-bottom: 2px solid $tertiary;
	padding: 0 0 40px;
	margin: 0 0 40px;
	.col-sm-8 {
		float: left;
		margin: 0px;
	}
	.col-sm-4 {
		float: right;
		img {
			width: 100%;
			height: auto;
		}
	}
}

.single-event {
	.full-content {
		margin-top: 30px;
	}
}

h3.event-title {
	margin-top: 0;
	margin-bottom: 5px;
	text-align: left!important;
	a {
		color: $body;
		&:hover {
			color: $primary;
		}
	}
}

.event-meta {
	float: left;
	width: 100%;
	margin: 0 0 10px;
	font-size: 17px;
	font-family: $headline-font;
	font-weight: 400;
}

.event-links {
	margin-top: 15px;
	overflow: hidden;
	@include breakpoint($bp-charlie) {
		margin-top: 25px;
	}
	a {
		font-family: $headline-font;
		font-weight: 600;
		font-size: 16px;
		margin-right: 35px;
		position: relative;
		padding-right: 15px;
		&.learn {
			&:after {
				right: 0;
				content: "\f105";
				position: absolute;
				font-size: 18px;
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				text-decoration: inherit;
				bottom: -4px;
			}
		}
	}
}

.event-grid-wrapper {
	padding: 0 24px;
}

.event-grid-wrapper,
.alm-reveal {
	display: flex;
	justify-content: space-between;
	flex-flow: row wrap;
	@include hover-fade;

	&:after {
		content: "";
		flex: auto;
	}

	.event {
		width: 100%;
		box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.25);
		padding: 30px 30px 50px 30px;
		margin: 0 .5% 1.5% .5%;
		position: relative;
		@include hover-fade;
		min-height: 225px;

		@include breakpoint($bp-bravo) {
			width: 49%;
		}

		@include breakpoint($bp-charlie) {
			width: 32%;
		}

		@include breakpoint($bp-delta) {
			width: 24%;
		}

		h6 {
			color: $purple;
			&.postponed {
				color: #ed5667;
			}
		}

		h5 a {
			color: $body;
		}

		p {
			margin: 0;
		}

		&:hover {
			background: $primary;

			a, p {
				color: $secondary;
			}
		}
	}
}

.load-more-events,
.alm-btn-wrap {
	width: 100%;
	text-align: center;
	padding: 50px 0;
	clear: both;

	a, button {
		display: inline-block;
		margin: 0 auto;
		color: #081216;
		width: auto;
		@include hover-fade();
		cursor: pointer;
		background: transparent;
		border: 2px solid $primary;
		padding: 17px 15px;
		border-radius: 50px;
		line-height: 120%;
		min-width: 300px;
		max-width: 350px;
		width: auto;
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 0.08em;
		font-weight: 700;
		font-family: $headline-font;
		text-align: center;
		&:hover {
			background: $primary;
			color: #081216;
		}

		&:disabled {
			display: none;
		}
	}
}

.go-back {
	clear: both;
	margin-bottom: 25px;
	text-align: center;

	a {
		color: $body;
		font-family: $headline-font;
	}
}