.articles {
	float: left;
	width: 100%;
}

.overlay-link {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.single-post {
	.featured-image, .featured-video {
		max-width: 100%;
		margin: 50px auto 0;
		margin-bottom: 0;
		position: relative;

		img {
			width: 100%;
			height: auto;
		}
		.caption {
			margin-top: 15px;
			p {
				text-align: center;
				font-size: 13px;
			}
		}
		iframe {
			width: 100%;
			height: 280px;
			@include breakpoint($bp-charlie) {
				height: 450px;
			}
			@include breakpoint($bp-delta) {
				height: 580px;
			}
		}
	}
	.featured-image {
		margin-top: 0;
	}
}

.post-feed {
	.content {
		background: #eeeeee;
		padding: 30px 20px;
		@include breakpoint($bp-charlie) {
			padding: 50px 40px;
		}
		.container {
			padding: 0;
		}
	}
	.featured-content {
		position: relative;
		a.full-link {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		.featured-image {
			width: 100%;
			background-size: cover!important;
			background-position: 50% 50%;
			height: 240px;
			@include breakpoint($bp-charlie) {
				height: 380px;
			}
			@include breakpoint($bp-bobby) {
				height: 520px;
			}
		}
		.featured-video {
			margin-bottom: 0;
			iframe {
				width: 100%;
				height: 240px;
				@include breakpoint($bp-charlie) {
					height: 380px;
				}
				@include breakpoint($bp-bobby) {
					height: 520px;
				}
			}
		}
	}
}

article {
	float: left;
	width: 100%;
	position: relative;
	background: #fff;
	@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
	margin-bottom: 25px;
	@include breakpoint($bp-charlie) {
		margin-bottom: 45px;
		&:hover {
			.overlay-share {
				opacity: 1;
			}
			.main-content {
				h3 {
					a {
						color: $primary;
					}
				}
				.read-more {
					a {
						color: $secondary;
					}
				}
			}
		}
	}
	.overlay-share {
		position: absolute;
		right: 0;
		bottom: 0;
		@include hover-fade();
		opacity: 0;
		z-index: 100;
		.social {
			margin: 0;
			float: right;
			display: none;
			height: 77px;
			background: transparent;
			padding: 25px;
			@include breakpoint($bp-bobby) {
				display: block;
			}
			span {
				margin: 4px 10px 0;
				font-family: $headline-font;
				color: #666666;
				text-transform: none;
				font-weight: 600;
				font-size: 14px;
				display: none;
			}
			ul {
				li {
					a {
						font-size: 18px;
						padding: 0px 8px;
						color: #666666;
						&:hover {
							color: $secondary;
						}
					}
				}
			}
		}
	}
	&:last-child {
		margin: 0px;
		border-bottom: 0px;
	}
	img {
		max-width: 100%;
		height: auto;
		margin: 0;
	}
	.main-content {
		width: 100%;
		float: left;
		padding:  25px 20px;
		position: relative;
		@include breakpoint($bp-charlie) {
			padding:  70px 40px;
		}
		.full-link {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 99;
		}
	}
	h3, .excerpt {
		display: table;
		width: 100%;
		margin: 0 auto 20px;
		max-width: 785px;
		text-align: center;
	}
	.excerpt p:last-of-type {
		margin-bottom: 0;
	}
	h3 {
		a {
			color: $body;
		}
	}
	.read-more {
		display: table;
		width: 100%;
		margin: 0 auto 10px;
		max-width: 785px;
		text-align: center;
		a {
			color: $cyan-alt;
			font-size: 16px;
			font-family: $headline-font;
			font-weight: 600;
			position: relative;
			padding-right: 15px;
			&:after {
				right: 0;
				content: "\f105";
				position: absolute;
				font-size: 18px;
				font-family: FontAwesome;
				font-style: normal;
				font-weight: normal;
				text-decoration: inherit;
				bottom: -4px;
			}
		}
	}
	.meta {
		display: table;
		width: 100%;
		margin: 0 auto 10px;
		max-width: 785px;
		text-align: center;
		span {
			margin: 0 20px;
			font-size: 15px;
			font-family: $headline-font;
			font-weight: 600;
			color: #999999;
			@include breakpoint($bp-charlie) {
				font-size: 17px;
			}
			a {
				color: #999999;
				&:hover {
					color: $secondary
				}
			}
		}
	}
	.btn {
		@include breakpoint($bp-charlie) {
			float: left;
		}
	}
}

.learn-more {
	width: 100%;
	background: $bg-light;
	padding: 50px 0 40px 0;
	float: left;
	@include hover-fade;

	@include breakpoint($bp-charlie) {
		padding: 100px 0 90px 0;
	}

	.inner-nav {
		margin: 0 auto;
		width: 98%;
		max-width: 1112px;

		@include breakpoint($bp-charlie) {
			display: flex;
			justify-content: space-between;

			.column {
				width: calc( 50% - 24px );
			}
		}

		.column {
			background: #fff;
			box-shadow: 0 8px 60px 0 rgba(0,0,0,.15);
			padding: 100px 35px 50px 35px;
			position: relative;
			margin-bottom: 10px;
			transition: .35s;

			.arrow {
				width: 31px;
				height: 30px;
				fill: $primary;
				stroke: $primary;
				stroke-width: 2px;
				position: absolute;
				top: 35px;
				right: 35px;
			}

			.tiny {
				margin-bottom: .35em;
				text-transform: uppercase;
				font-family: $headline-font;
				letter-spacing: .35em;
				color: $details;
				font-size: 13px;
				font-weight: 700;
			}

			&:hover {
				background: $lavender;

				.arrow {
					fill: $purple;
					stroke: $purple;
					transition: 0.35s;
				}
				p, h3 {
					color: $charcoal;
				}
				&.prev-post {
					background: $pastel-teal;
					.arrow {
						fill: $teal;
						stroke: $teal;
					}
				}
			}

			&.prev-post {
				text-align: right;

				p, h3 {
					text-align: right;
				}

				.arrow {
					left: 50px;
				}
			}

			&.next-post {
				.arrow {
					transform: rotateY(180deg);
				}
			}

			@include breakpoint($bp-charlie) {
				padding: 150px 55px 100px 55px;

				.arrow {
					width: 42px;
					height: 40px;
					top: 50px;
					right: 50px;
				}
			}
		}

	}
}

.news-feed.resources-page .grid-res {
	.container {
		max-width: 930px;
		display: flex;
		flex-flow: row wrap;
		//padding: 0;
		@include breakpoint($bp-charlie) {
			padding: 0;
		}
	}

	.single-grid {
		width: 100%;
		padding: 0;
		float: none;
		height: auto;

		@include breakpoint($bp-charlie) {
			width: calc(50% - 36px);

			&:nth-child(2n+1) {
				margin-right: 70px;
			}
		}



		img {
			width: 100%;
			height: auto;
		}

		.post-details {
			padding: 35px 0;

			.post-meta,
			.post-meta a { //making these styles global
				// font-family: $headline-font;
				// text-transform: uppercase;
				// color: #999;
				// font-size: 14px;
				// font-weight: 700;
				// margin-bottom: 20px;
			}

			h5{
				//line-height: 130%;
			}
			.read-more {
				font-size: 15px;
				margin-bottom: 0;
				a {
					&:hover {
						color: $teal;
					}
					svg {
						margin-left: 5px;
					}
				}
			}

			.category {
				text-transform: uppercase;
				//padding-right: 0.5em;
			}
		}

		&:after {
			display: none;
		}

	}
}