.styleGuide {
	.topper.short {
		height: auto;
		padding: 65px 0 55px 0;
		@include breakpoint($bp-charlie) {
			height: 250px;
		}
		@include breakpoint($bp-bobby) {
			height: 250px;
		}
	}
	.sub-nav {
		display: none!important;
	}

	.innerGuide {
		padding: 50px 30px 25px;
		@include breakpoint($bp-charlie) {
			padding: 100px 40px 25px;
		}
		.col-md-11 {
			float: none;
			margin: 0 auto;
		}
		.singleArea {
			margin-bottom: 50px;
			display: table;
			width: 100%;
			@include breakpoint($bp-charlie) {
				margin-bottom: 100px;
			}
		}
		.title {
			width: 100%;
			padding: 0;
			margin-bottom: 25px;
			border-bottom: 1px solid #cccccc;
			h5 {
				margin-bottom: 7px;
			}
		}
		.buttons-area {
			float: left;
			width: 100%;
			padding: 25px 0 25px;
			a {
				float: left;
				margin-bottom: 10px;
				@include breakpoint($bp-charlie) {
					margin-right: 10px;
				}
			}
		}
		.typography {
			float: left;
			width: 100%;
			padding: 25px 0 25px;
			.single {
				margin-bottom: 30px;
				&.less {
					margin-bottom: 22px;
				}
				&.body {
					p {
						max-width: 465px;
					}
				}
				.title-area {
					margin-bottom: 5px;
					p {
						line-height: 100%;
						margin-bottom: 1px;
						span.headline {
							text-decoration: underline;
						}
						&.faded {
							small {
								color: #999999;
							}
						}
					}
				}
			}
		}
		.logos {
			display: table;
			width: 100%;
			.logo-area {
				float: left;
				width: 100%;
				box-shadow: 2px 2px 2px 0 rgba(0,0,0,.15);
				border-radius: 3px;
				background:#fff;
				text-align: center;
				padding: 115px 25px;
				margin-bottom: 15px;
				svg {
					max-height: 75px;
					width: auto;
				}
				&.dark {
					background: #000;
					svg {
						.st0, .st1, .st2, .st3, .st4, .st5, .st6 {
							fill: #fff;
						}
					}
				}
			}
		}
		.colors {
			float: left;
			width: 100%;
			.single-color {
				float: left;
				margin-left: 2.5%;
				width: 18%;
				margin-bottom: 15px;
				&:first-of-type {
					margin-left: 0;
				}
				p.main {
					margin-bottom: 0;
					color: #999999;
					small {
						color: #999999;
					}
				}
				.box {
					width: 100%;
					height: 145px;
					box-shadow: 2px 2px 2px 0 rgba(0,0,0,.15);
					border-radius: 3px;
					margin-bottom: 15px;
					&.primary {
						background: $primary;
					}
					&.secondary {
						background: $secondary;
					}
					&.tertiary {
						background: $tertiary;
					}
					&.body {
						background: $body;
					}
					&.details {
						background: $details;
					}
				}
				.numbers {
					p {
						margin-bottom: 0;
						line-height: 110%;
						span.tt {
							color: #999999;
							width: 49px;
							display: inline-block;
						}
					}
				}
			}
		}
	}
}

#primaryColor {
	&:before {
		content: "#{$primary}";
	}
}

#secondaryColor {
	&:before {
		content: "#{$secondary}";
	}
}

#tertiaryColor {
	&:before {
		content: "#{$tertiary}";
	}
}

#bodyColor {
	&:before {
		content: "#{$body}";
	}
}

#detailsColor {
	&:before {
		content: "#{$details}";
	}
}

#h1superSize {
	&:before {
		content: "#{$h1super}";
	}
}
#h1superFont {
	&:before {
		content: "#{$h1superFont}";
	}
}
#h1superWeight {
	&:before {
		content: "#{$h1superWeight}";
	}
}

#h1Size {
	&:before {
		content: "#{$h1}";
	}
}
#h1Font {
	&:before {
		content: "#{$h1Font}";
	}
}
#h1Weight {
	&:before {
		content: "#{$h1Weight}";
	}
}

#h2Size {
	&:before {
		content: "#{$h2}";
	}
}
#h2Font {
	&:before {
		content: "#{$h2Font}";
	}
}
#h2Weight {
	&:before {
		content: "#{$h2Weight}";
	}
}

#h3Size {
	&:before {
		content: "#{$h3}";
	}
}
#h3Font {
	&:before {
		content: "#{$h3Font}";
	}
}
#h3Weight {
	&:before {
		content: "#{$h3Weight}";
	}
}

#h4Size {
	&:before {
		content: "#{$h4}";
	}
}
#h4Font {
	&:before {
		content: "#{$h4Font}";
	}
}
#h4Weight {
	&:before {
		content: "#{$h4Weight}";
	}
}

#h5Size {
	&:before {
		content: "#{$h5}";
	}
}
#h5Font {
	&:before {
		content: "#{$h5Font}";
	}
}
#h5Weight {
	&:before {
		content: "#{$h5Weight}";
	}
}

#h6Size {
	&:before {
		content: "#{$h6}";
	}
}
#h6Font {
	&:before {
		content: "#{$h6Font}";
	}
}
#h6Weight {
	&:before {
		content: "#{$h6Weight}";
	}
}

#pSize {
	&:before {
		content: "#{$p}";
	}
}
#pFont {
	&:before {
		content: "#{$pFont}";
	}
}
#pWeight {
	&:before {
		content: "#{$pWeight}";
	}
}