// new colors 
$light-blue		: #73CCE0;
$charcoal		: #0A2129;
$teal           : #00C7C4;
$navy			: #05426B;
$orange			: #F28C52;
$purple			: #786EBD;
$pink			: #FC7A99;
$lime           : #B8CF54;
$white          : #FFFFFF;
$pale-grey		: #EFEFEF;
$light-grey     : #E3E3E3;
$middle-grey    : #3D4D54;
$grey    		: #9EA6A8;
$pastel-orange  : #FAD1BA;
$lavender       : #E3DFFE;
$pastel-pink	: #FFD8E1;
$teal 			: #00C7C4;
$pastel-teal	: #C9F7F6;
$dark-teal      : #00A3A0; // accessible teal
$middle-purple  : #9E97D4; // for work topper icons 

// color re-mapping
$primary		: $light-blue;
$secondary		: $teal;
$tertiary		: $pastel-teal;
$body			: $middle-grey;
$bg-dark     	: $charcoal;
$bg-light		: $pale-grey;
$cyan-alt       : $dark-teal;
$sky-blue       : $light-blue;
$rich-black     : $charcoal;
$purple         : $purple;
$details		: $middle-grey;
$cyan           : $teal;
$footer			: $charcoal;
$tiffany        : $teal;
$body-link-color: $dark-teal;

// Header
$header-height		 :  44px;
$header-mobile-height:	60px;

// Navigation 
$navBackground	: $charcoal;
$navColor		: $charcoal;
$navColorHover	: $teal;
$dropDownBg		: $primary;
$dropDownColor	: $white;
$dropDownHover	: $body;
$socialColor	: $grey;
$socialHover	: $secondary;

// Default Box shadow. Set to zero if you want to remove shadow from buttons and boxes
$boxShadowTop	: 4px;
$boxShadowLeft	: 4px;
$boxShadowBlur	: 4px;
$boxShadowColor	: rgba(0,0,0,0.08);

// Headlines
$headlineColor  : $charcoal;
$hrColor		: $grey;
$hrColorShort	: $teal;
 
// Social Media
$facebook		: #344b81;
$twitter		: #00aced;
$email			: #cccccc;

// breakpoints
$bp-alpha		: 320px;
$bp-bravo		: 480px;
$bp-charlie-sm	: 700px;
$bp-charlie		: 768px;
$bp-george		: 900px;
$bp-delta		: 960px;
$bp-bobby		: 992px;
$bp-header      : 1100px;
$bp-nina        : 1180px;
$bp-echo		: 1250px;

// fonts
$primary-font	: 'aktiv-grotesk', 'sans-serif';
$headline-font	: 'aktiv-grotesk', 'sans-serif';

// Fonts available: 
// font-family: "aktiv-grotesk";
// regular = 400/italics
// bold = 700
// xbold = 800

// borders
$border-height  : 2px;
$border-color   : $grey;

// gutter
$mobile-site-gutter : 20px;

// padding between main sections
$mobile-section-padding: 65px;

// global play button styles 
$play-circle-color: $teal;
$play-triangle-color: $navy;
$play-circle-hover-color: $pink;
$play-triangle-hover-color: $charcoal;

// button module styles
$btn-text-color: $navy;
$btn-text-hover-color: $charcoal;

// global secondary nav button styles
$sec-text-color: $navy;
$sec-text-color-hover: $charcoal;
