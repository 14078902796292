/* Variables are only for the topper menus */

$solidBgColor	: $body;
$headlineColor	: #fff;
$paragraphColor	: #fff;

//Tall Topper Height
$tallDesktop	: 780px;
$tallTablet		: 540px;

//Short Topper Height
$shortDesktop	: 420px;
$shortTablet	: 420px;

// .home .topper {
// 	height: auto!important;
// 	max-height: 100%;
// 	min-height: 150px;
// 	padding: 0 0 0 0;
// 	@include transition(all 0.3s ease-in-out);
// 	margin-top: $header-mobile-height;
// 	@include breakpoint($bp-charlie) {
// 		height: calc(100vh - 72px)!important;
// 		max-height: 650px;
// 		min-height: 550px;
// 		margin-top: $header-height;
// 	}
// 	@include breakpoint($bp-bobby) {
// 		height: calc(100vh - 72px)!important;
// 		max-height: 850px;
// 		min-height: 720px;
// 		margin-top: $header-height;
// 	}
// }

.topper {
	float: left;
	width: 100%;
	background-size: cover !important;
	background-position: 50% 50%!important;
	position: relative;
	padding: 40px 0 40px;
	background-color: $solidBgColor;
	margin-top: 0;
	@include breakpoint($bp-header) {
		padding: 0 0 0 0;
		margin-top: $header-height;
	}

	&.home {
		// .container .col-sm-10 {
		// 	margin: 0 auto;
		// }
	}

	.overlay {
		@include breakpoint($bp-charlie) {
			opacity: 0 !important;
		}
		// @include breakpoint($bp-charlie, max) {
		//   opacity: 0.2 !important;
		// }
	}

	h1, h2, h3, h4, h5, h6 {
		color: #fff;
	}
	h6 {
		margin-bottom: 16px;
	}
	h1 {
		margin-bottom: 25px;
	}
	h1 {
		font-size: $h1Mobile;
		@include breakpoint($bp-bobby) {
			font-size: 35px;
		}
		@include breakpoint(1200px) {
			font-size: 40px;
		}
	}
	p {
		color: $paragraphColor;
	}

	h6 {
		background:transparent!important;
		&.orange {
			color: $orange;
		}
		&.purple {
			color: $purple;
		}
		&.lavender {
			color: $lavender;
		}
		&.red {
			color: $pink;
		}
		&.pink {
			color: $pink;
		}
		&.teal {
			color: $teal;
		}
	}
	.icon {
		max-width: 80px;
		width: 100%;
		margin: 0 auto 15px;
		@include breakpoint($bp-bobby) {
			max-width: 95px;
			margin: 0 auto 35px;
		}
		svg {
			width: 100%;
			.st1, .st0 {
				fill: $orange;
			}
		}
		&.second {
			svg .st1, svg .st0 {
				fill: $purple;
				//fill: $middle-purple;
			}
		}
		&.third {
			svg .st1, svg .st0 {
				fill: $teal;
				//fill: $purple;  change to purple once this page is set up
			}
		}
		&.fourth {
			svg .st1, svg .st0 {
				fill: $pink;
			}
		}
	}

	.container {
		height: 100%;
		padding: 0 20px;
		width: 100%;
		max-width: 1210px;
		margin: 0 auto;
		// set to 0 so it kicks in with animations. if animation is removed, remove this opacity
		opacity: 0;
		.credit {
			position: absolute;
			bottom: -65px;
			right: 23px;
		}
		.row {
			height: 100%;
			.center-bottom {
				position: relative;
				
				max-width: 650px;
				width: 100%;
				margin: 0 auto;
				padding: 0 10px;
				@include breakpoint($bp-bobby) {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
				}
				h6, h1, p {
					text-align: center;
					max-width: 100%;
					width: 100%;
				}
			}
		}
	}
	.donation {
        margin: 15px 0 0 0;
        @include breakpoint($bp-charlie) {
            margin: 20px 0 0 0;
        }
        .donation-note {
            color: white;
        }
        .col-lg-8 {
            margin: 0 auto;
            float: none;
        }
        ul {
            li {
                font-weight: 700;
            }
        }
    }
	.deck {
		line-height: 1.5;
		max-width: 550px;
		p {
			margin-bottom: 20px;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	&.has-image {
		.deck {
			font-family: $headline-font;
			font-size: 16px;
			color: #fff;
			margin-top: 20px;
		}
	}

	&.short {
		height: auto;
		padding: 200px 0 25px;
		position: relative;
		@include breakpoint($bp-charlie) {
			height: auto;
		}
		@include breakpoint($bp-bobby) {
			height: 680px;
			padding: 85px 0 60px;
		}
		// &:before {
		// 	content: '';
		// 	width: 100%;
		// 	height: 30%;
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	opacity: 0.8;
		// 	background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
		// 	background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
		// 	background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
		// 	z-index: 2;
		// }
		&:after {
			content: '';
			width: 100%;
			height: 50%;
			position: absolute;
			bottom: 0;
			left: 0;
			background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
			background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
			background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
			z-index: 1;
		}
		.container {
			z-index: 3;
			position: relative;
		}
		h1 {
			margin: 0;
			max-width: 100%;
			font-size: 24px;
			@include breakpoint($bp-charlie) {
				max-width: 80%;
				font-size: 30px;
			}
			@include breakpoint($bp-bobby) {
				max-width: 70%;
				font-size: 40px;
			}
		}
		h6 {
			margin-bottom: 20px;
			color: $primary;
			max-width: 100%;
			@include breakpoint($bp-bobby) {
				max-width: 70%;
			}
		}
	}
	&.split {
		height: calc(100vh - 44px);
		min-height: 300px;
		max-height: 350px;					
		position: relative;
		overflow: hidden;
		padding: $header-mobile-height 0 0;
		background-color: $solidBgColor;
		margin-top: 0;
		@include breakpoint($bp-charlie) {
			min-height: 550px;
			max-height: 600px;
		}
		@include breakpoint($bp-bobby) {
			min-height: 600px;
			max-height: 900px;
		}
		@include breakpoint($bp-header) {
			padding: 0;
			margin-top: $header-height;
		}
		.split-topper-image {
			display: none;				
			@include breakpoint($bp-charlie) {
				width: 50%;
				display: block;
				background-size: cover !important;
				background-position: 50% 50%!important;
				float: left;
				//width: 100%;
				height: 100%;
			}
		}
		.topper-content {
			width: 100%;
			height: 100%;
			float: left;
			position: relative;
			background: $bg-light;
			text-align: center;
			padding: 0 20px;
			@include breakpoint($bp-charlie) {
				width: 50%;
				padding: 20px;
			}
			h2 {
				color: $charcoal;
				margin-bottom: 0;
			}
			h6 {
				margin-bottom: 1.5em;
			}
		}
		.topper-content-inner {
			max-width: 475px;
			margin: 0 auto;
			@include vert-center;
		}
		.credit {
			position: absolute;
			right: 20px;
			bottom: 20px;
		}
	}
	&.tall {
		height: auto;
		position: relative;
		padding: 200px 0 25px;
		position: relative;

		.col-xs-12 {
		  float: none;
		}

		@include breakpoint($bp-bobby) {
			height: auto;
			padding: 100px 0 100px;
		}
		&:before {
			// content: '';
			// width: 100%;
			// height: 30%;
			// position: absolute;
			// top: 0;
			// left: 0;
			// opacity: 0.8;
			// background: -moz-linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
			// background: -webkit-linear-gradient(top, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
			// background: linear-gradient(to bottom, rgba(0,0,0,1) 0%,rgba(0,0,0,0) 100%);
			//z-index: 2;
		}
		&:after {
			content: '';
			width: 100%;
			height: 50%;
			position: absolute;
			bottom: 0;
			left: 0;
			background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
			background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
			background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
			z-index: 1;
		}
		@include breakpoint($bp-bobby) {
			height: calc(100vh - 44px);
			min-height: 600px;
			max-height: 900px;
		}
		.container {
			z-index: 3;
			position: relative;
		}
		h1 {
			margin: 0;
			max-width: 100%;
			@include breakpoint($bp-bobby) {
				max-width: 80%;
			}
		}
		h6 {
			margin-bottom: 20px;
			color: $primary;
			max-width: 100%;
			@include breakpoint($bp-bobby) {
				max-width: 80%;
			}
		}
	}
	&.home {
		padding: 200px 0 25px;
		height: auto;
		@include breakpoint($bp-charlie) {
			height: auto;
			padding: 250px 0 50px;
		}
		@include breakpoint($bp-bobby) {
			padding: 85px 0 100px;
			height: calc(100vh - 44px);
			min-height: 600px;
			max-height: 900px;
		}
		.container .col-sm-10 {
			margin: 0 auto;
		}
		.container.zindex {
			z-index: 9999!important;
		}
		h1 {
			max-width: 77%;
			span {
				color: $primary;
			}
		}
		&:after {
			opacity: 0.75;
		}
		.position-bottom {
			position: relative;
			@include breakpoint($bp-bobby) {
				position: absolute;
			}
			h1 {
				font-size: 22px;
				max-width: 100%;
				text-align: center;
				@include breakpoint($bp-bobby) {
					font-size: 35px;
					max-width: 70%;
					text-align: left;
				}
				@include breakpoint(1200px) {
					font-size: $h1super;
				}
			}
		}
	}
	.position-bottom {
		position: relative;
		@include breakpoint($bp-bobby) {
			position: absolute;
			left: 25px;
      		right: 25px;
			bottom:0;
      		width: auto;
		}
	    @include breakpoint($bp-echo) {
	      left: 0;
	      right: auto;
	      width: 100%;
	    }
	}
	.read-more {
		position: absolute;
		bottom: 10px;
		right: 20px;
		max-width: 47px;
		display: none;
		@include breakpoint($bp-bobby) {
			display: block;
		}
		@include breakpoint($bp-bobby) {
			max-width: 60px;
			bottom: 10px;
		}
		a {
			padding: 10px 10px 0 10px;
			float: left;
			width: 100%;
			height: auto;
			svg {
				position: relative;
				width: 100%;
				height: auto;
				@include hover-fade();
				opacity: 0.4;
				.st0 {
					fill:none;
					stroke:#fff;
					stroke-width:2;
					stroke-miterlimit:10;
					@include hover-fade();
				}
			}
			&:hover {
				svg {
					opacity: 1;
					.st0 {
						stroke: $primary;
					}
				}
			}
		}
	}
}

.topper-posts {
	.col-md-8 {
		margin: 0 auto;
		float: none;
	}
	.media-contact {
		text-align: center;
		p {
			font-size: 18px;
		}
		@include breakpoint($bp-charlie) {
			font-size: 21px;
		}
	}
	.logo {
		text-align: center;
		margin-bottom: 0px;
		img {
			width: 200px;
    		height: auto;
		}
	}
	&.no-topper {
		.col-md-8 {
			//display: table;
			margin: 0 auto 0 auto;
			padding-top: 100px;
			@include breakpoint($bp-charlie) {
				padding-top: 200px;
			}
			@include breakpoint($bp-delta) {
				padding: 255px 0 0 0;
			}
			.post-meta {
				text-transform: uppercase;
				text-align: center;
				font-size: 13px;
				letter-spacing: 0.1em;
				font-family: $headline-font;
				a {
					color: $purple;
				}
			}
		}
		h6, h1 {
			text-align: center;
		}
	}
	&.single-post {
		.post-meta {
			text-transform: uppercase;
			text-align: center;
			font-size: 13px;
			letter-spacing: 0.1em;
			font-family: $headline-font;
			a {
				color: $purple;
			}
		}
		h1 {
			text-align: center;
		}
		.videoWrapper {
			padding-bottom: 56%;
		}
      .article-link {
        text-align: center;
        font-weight: 700;
        font-size: 22px;
      }
	}
    //&.no-topper.single-post .col-md-8 {
    //  padding-top: 0;
    //}
	h6 {
		color: $purple;
		margin-bottom: 18px;
		font-weight: 700;
	}
	.page-meta {
		text-align: center;
		font-size: 14px;
		font-weight: 600;
		margin-bottom: 15px;
		color: $purple;
		a {
			color: $purple;
			&:hover {
				color: $secondary;
			}
		}
	}
	h1 {
		margin: 0 auto 20px auto;
		text-align: center;
		max-width: 700px;
	}
	.deck {
		margin: 0 auto;
		font-size: 16px;
		text-align: center;
		max-width: 100%;
		color: $charcoal;
		@include breakpoint(698px) {
			max-width: 80%;
		}
		@include breakpoint($bp-delta) {
			font-size: 20px;
			max-width: 80%;
		}
	}
	.post-info {
		text-align: center;
		font-size: 18px;
		margin-top: 15px;
	}
	.sub-nav {
		@include breakpoint($bp-delta) {
			margin-bottom: 25px;
		}
	}
}

// Topper Call To Action Area
.topper-cta {
	float: left;
	width: 100%;
	.btn {
		margin: 0 auto;
		display: table;
	}
	.play-row {
    	width: 100%;
    	height: 100px;
    	margin-top: 40px;
    	.play-button {
    		position: relative;
    		margin: 0 auto;
    		display: table;
    		float: none;
    		left: auto;
    		top: 0;
    		cursor: pointer;
    		width: 60px;
    		height: 60px;
    		transition: .3s;
    		.play-bg {
    			background: #fff;
    			width: 25px;
    			height: 25px;
    			top: 18px;
    			left: 20px;
    			position: absolute;
    			transition: .3s;
    		}
    		svg {
    			z-index: 9;
    			position: relative;
    		}
    		@include breakpoint($bp-charlie) {
    			margin: 10px auto 0;
    			top: 0;
    			width: 90px;
    			height: 90px;
    			.play-bg {
    				width: 40px;
    				height: 40px;
    				top: 23px;
    				left: 28px;
    			}
    		}
    	}
	}
	.video-link {
		position: relative;
	}
	.video-popup {
		width: 75px;
		height: 75px;
		background: $primary;
		@include box-shadow($boxShadowTop, $boxShadowLeft, $boxShadowBlur, $boxShadowColor);
		border-radius: 100%;
		display: table;
		margin: 0 auto;
		position: relative;
		outline: none;
		@include hover-fade();
		&:hover {
			background: $secondary;
		}
		span {
			width: 30px;
			height: 35px;
			position: absolute;
			left: 0;
			right: 0;
			top: 22px;
			margin: 0 auto;
			svg {
				margin-left: 5px;
				width: 100%;
				height: auto;
			}
		}
	}
	.topper-form {
			margin: 0 auto;
			display: table;
			float: none;
			width: 300px;
			padding: 0;
		form {
			.email {
				float: left;
				width: 100%;
				margin-bottom: 4px;
			}
			.zip {
				float: left;
				width: 100%;
				margin-bottom: 13px;
			}
			.submit {
				width: 100%;
				float: left;
				margin-bottom: 0;
			}
		}
	}
}

// Topper Video Background
.topper-video {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	z-index: -100;
	background-size: cover !important;
}

.fullscreen-bg__video {
	display: none;
	@include breakpoint($bp-charlie) {
  		display: block;
  		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

@media (min-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    width: 300%;
    left: -100%;
  }
}

// Topper Menu
.blog .sub-nav, .archive .sub-nav, .category .sub-nav {
	background: $pale-grey;
}

.sub-nav {
	float: left;
	width: 100%;
	padding: 10px 20px 0;
	@include breakpoint($bp-bobby) {
		padding: 20px 40px 0;
	}
	.container {
		padding: 0;
	}
	.topper-menu {
		float: left;
		width: 100%;
		display: block;
		overflow: hidden;
		@include breakpoint($bp-charlie) {
			display: table;
			margin: 0 auto;
			width: auto;
			float: none;
		}
		@include breakpoint($bp-bobby) {
			display: block;
			width: auto;
			float: none;
			margin: 0 auto;
			display: table;
		}
		&.news {
			display: table;
			margin: 0 auto;
			width: auto;
			float: none;
		}
	}
	nav {
		margin-top: 15px;
		margin-bottom: 15px;
		overflow: hidden;
		@include breakpoint($bp-charlie) {
			margin-top: 0;
			margin-bottom: 22px;
		}
		ul {
			margin: 0px;
			padding: 0px;
		    li {
		    	float: left;
		    	width: 100%;
		    	@include breakpoint($bp-charlie) {
					width: auto;
		    	}
		    	&.current-menu-item,
          .post-type-archive-resources &.menu-item-1491 {
		    		a {
		    			color: $charcoal;
		    			&:after {
		    				@include breakpoint($bp-charlie) {
			    				background: $teal;
			    				opacity: 1;
			    			}
		    			}
		    		}
		    	}
		    	&:first-of-type {
		    		a {
			    		@include breakpoint($bp-charlie) {
							margin-left: 0!important;
			    		}
			    	}
		    	}
		    	.sub-menu {
		    		display: none;
		    	}
		    	a {
		    		font-size: 14px;
		    		font-weight: 700;
		    		font-family: $headline-font;
		    		display: block;
		    		text-align: center;
		    		color: $middle-grey;
		    		position: relative;
		    		margin: 5px 15px;
		    		padding: 5px;
		    		text-transform: uppercase;
		    		letter-spacing: 0.08em;
		    		min-width: 38px; // min-width to match the subnav indicator to keep center alignment
		    		@include breakpoint($bp-charlie) {
						padding: 23px 0 12px 0;
		    			margin: 0 40px;
		    		}
		    		&:after {
		    			content: '';
		    			width: 38px;
		    			height: 2px;
		    			position: absolute;
		    			bottom: 0;
		    			left: 0;
		    			right: 0;
		    			margin: 0 auto;
		    			background: $teal;
		    			opacity: 0;
		    			@include hover-fade();
		    		}
		    		&:hover {
		    			color: $charcoal;
		    			&:after {
		    				@include breakpoint($bp-charlie) {
		    					opacity: 1;
		    				}
		    			}
		    		}
		    	}
		    }
		}
	}
}
