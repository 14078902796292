/*======================================
  Selectric
======================================*/
.selectric-wrapper {
  position: relative;
  cursor: pointer;
}

.selectric-responsive {
  width: 100%;
}

.selectric {
  border: 2px solid $teal;
  background: #fff;
  position: relative;
  border-radius: 0px;
  @include hover-fade();
  height: 62px;
}

.selectric .label {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 0 0;
  padding: 21px 50px 21px 20px;
  font-size: 13px;
  line-height: 18px;
  color: $charcoal;
  min-height: 18px;
  letter-spacing: 0.08em;
  font-family: $headline-font;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
}


.selectric .button {
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 30px;
    content: 'X';
    top: -25px;
    top: 50%;
    margin-top: -12px;
    right: 15px;
    color: $charcoal;
    font-size: 20px;
    @include breakpoint($bp-bobby) {
      margin-top: -14px;
    }
}

@media \0screen\,screen\9 {
  .selectric .button {
    color: $middle-grey;
    text-align: center;
    font: 20px/30px Lucida Sans Unicode, Arial Unicode MS, Arial;
  }
}

.selectric-focus .selectric {
  background: $teal;
}

.selectric-hover .selectric {
  background: $teal;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
}

.selectric-hover .selectric .button {
  color: $charcoal;
}

.selectric-hover .selectric .button:after {
  border-top-color: $grey;
}

.selectric-open {
  z-index: 9999;
}

.selectric-open .selectric {
  background: $teal;
  box-shadow: none!important;
}

.selectric-open .selectric-items {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
      -ms-transform: translateY(0);
       -o-transform: translateY(0);
          transform: translateY(0);
  -webkit-transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
          transition: .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  z-index: 1;
}

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
}

.selectric-hide-select select {
  position: absolute;
  left: -100%;
}

.selectric-hide-select.selectric-is-native {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.selectric-hide-select.selectric-is-native select {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  border: none;
  z-index: 1;
  box-sizing: border-box;
  opacity: 0;
}

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectric-items {
  visibility: hidden;
  opacity: 0;
  -webkit-transform: translateY(-20px);
      -ms-transform: translateY(-20px);
       -o-transform: translateY(-20px);
          transform: translateY(-20px);
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  z-index: -1;
  box-shadow: 0 4px 40px 0 rgba(0,0,0,.25);
  margin: 8px 0;
  padding: 10px 0;
  border-radius: 0;
}

.selectric-items .selectric-scroll {
  height: 100%;
  overflow: auto;
}

.selectric-items:before,
.selectric-items:after {
  content: "";
  position: absolute;
  top: -18px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-bottom: 14px solid #fff;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  margin-top: 3px;
}

.selectric-items:after {
  margin-top: 4px;
  border-bottom-color: #FFF;
}

.selectric-above .selectric-items:before,
.selectric-above .selectric-items:after {
  top: 100%;
  border-bottom-width: 0;
  border-top: 14px solid #fff;
  margin-top: 0;
}

.selectric-above .selectric-items:after {
  border-top-color: #FFF;
  margin-top: -1px;
}

.selectric-above .selectric-items {
  top: auto;
  bottom: 100%;
}

.selectric-items ul, .selectric-items li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;
}

.selectric-items ul {
  margin: 0!important;
  padding: 0!important;
  width: 100%!important;
  li {
    float: left;
    width: 100%!important;
    margin: 0!important;
    padding: 5px 15px;
  }
}

.selectric-items li {
  display: block;
  color: $charcoal;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-align: center;
  font-size: 13px;
  font-family: $headline-font;
  font-weight: 700;
  @include hover-fade();
  &:first-of-type {
    text-transform: uppercase;
    letter-spacing: 0.08em;
    color: $middle-grey!important;
    background: #fff!important;
    padding: 8px 15px 12px 15px;
  }
}

.selectric-items li.selected {
  background: $light-grey;
  color: $middle-grey;
}

.selectric-items li.highlighted {
  background: $primary;
  color: $charcoal;
}

.selectric-items li.selected:after {
  opacity: 1;
  right: 0;
}

.selectric-items li:hover {
  background: $pastel-pink;
}

.selectric-items .disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default !important;
  background: none !important;
  color: $grey !important;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.selectric-items .selectric-group .selectric-group-label {
  font-weight: bold;
  padding-left: 10px;
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background: none;
  color: $middle-grey;
}

.selectric-items .selectric-group.disabled li {
  filter: alpha(opacity=100);
  opacity: 1;
}

.selectric-items .selectric-group li {
  padding-left: 25px;
}