.page-template-template-initiative-page {
	.our-impact {
		float: left;
		width: 100%;
		padding: 85px 0;
		background-size: cover!important;
		background-position: 50% 50%!important;
		padding: $mobile-section-padding 0;
		background:$pale-grey;
		width: 100%;
		position: relative;
		overflow: hidden;
		margin-top: 4rem;
		@include breakpoint($bp-bobby) {
			padding: 130px 0 120px;
		}
		.credit {
			max-width: calc(1110px + 120px);
			width: 100%;
			left: 0;
			right: 0;
			margin: 0 auto;
			bottom: 120px;
			top: auto;
			z-index: 10;
			text-align: right;
			position: absolute;
			padding: 0 40px;
		}
		.container {
			max-width: calc(1110px + 160px);
			width: 100%;
			position: relative;
			z-index: 3;
			h4 {
				color: #fff;
				text-align: center;
				margin-bottom: 30px;
			}
			.description {
				p {
					&:last-of-type {
						margin: 0;
					}
				}
			}
		}
		.image {
			position: absolute;
			height: 120%;
			width: 985px;
			background-size: cover!important;
			background-position: -50px top;
			background-repeat: no-repeat;
			bottom: 0;
			right: auto;
			left: -340px;
			-webkit-clip-path: polygon(50% 0%, 0 100%, 100% 100%);
			clip-path: polygon(50% 0%, 0 100%, 100% 100%);
			display: none;
			@include breakpoint($bp-delta) {
				left: 50%;
			}
			@include breakpoint($bp-bobby) {
				display: block;
			}
			.overlay-n {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 60%;
				background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
				background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
				background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
				opacity: 0.65;
			}
		}
		.wrapper {
			max-width: 480px;
		}

	}
	.impact-grid {
		background-color: $pale-grey;
		float: left;
		width: 100%;
		position: relative;
		z-index: 50;
		padding: 50px 0 50px;

		@include breakpoint($bp-bobby) {
			margin-top: 0;
			padding: 0 0 0px;
		}
		.container {
			max-width: calc(1090px + 160px);
			width: 100%;
			position: relative;

			@include breakpoint($bp-bobby) {
				margin-top: -50px;
			}

			.single-impact {
				.wrapper {
					padding: 25px 30px 25px;
					@include breakpoint($bp-bobby) {
						top: 50%;
						bottom: auto;
						@include transform(translateY(-50%));
						padding: 0px;
					}
					.title {
						margin: 10px 0px 0px;
					}
				}
			}
		}
	}
	.partners-layout {
		background:$pale-grey;
		padding: 0 0 $mobile-section-padding;
		float: left;
		width: 100%;
		@include breakpoint($bp-charlie) {
			padding: 150px 0 120px 0;
		}
		.container {
			background-color: #fff;
			padding-top: 2.5rem;
			max-width: calc(1110px + 160px);
			width: 100%;
		}
		.intro {
			margin: 0 auto;
		    float: none;
		    position: relative;
		}
		.headline {
			margin-bottom: 36px;
			h4 {
				text-align: center;
				margin: 0;
			}
		}
		.filters {
			width: 100%;
			text-align: center;
			list-style: none;
			padding: 0;
			margin-top: 3rem;
			li {
				letter-spacing: .1em;
			    margin-bottom: 0;
			    color: $charcoal;
			    font-family: $headline-font;
				font-weight: 600;
				text-transform: uppercase;
				font-size: 12px;
				position: relative;
				display: inline-block;
				cursor: pointer;
				margin: 0 1.5rem;
				&.active:after {
					content:"";
					display: block;
					position: relative;
					margin: 15px auto;
				    width: 38px;
				    height: 2px;
				    background:$teal;
				}
			}
		}
		.show-all {
			float: left; 
			width: 100%;
			list-style: none;
			overflow: hidden;
			display: none;
			text-align: center;
			&.active {
				display: block;
			}
			.single-image {
				width: calc(100%/2)!important;
				height: 165px!important;
				position: relative;
				float: left;
				&.more {
					display: none;
				}
				@include breakpoint(648px) {
					width: calc(100%/3)!important;
				}
				@include breakpoint($bp-charlie) {
					width: calc(100%/5)!important;
				}
				a {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					outline: none;
					pointer-events: all;
					z-index: 99;
				}
				img {
					max-width: 100px;
					position: absolute;
					top: 50%;
					@include transform(translateY(-50%));
					left: 0;
					right: 0;
					margin: 0 auto;
					-webkit-filter: grayscale(100%);
	    			filter: grayscale(100%);
	    			transition: transform 0.3s ease, filter 0.25s;
	    			@include breakpoint($bp-charlie) {
	    				max-width: 51%;
	    				max-height: 95px;
	    			}
				}
				&:hover {
					img {
						-webkit-filter: grayscale(0);
	    				filter: grayscale(0);
						@include transform(translateY(-50%) scale(1.1));
					}
				}
			}
			.read-more {
				display: inline-block;
			    margin: 2rem 0 3rem;
			    width: 80%;
			    @include breakpoint($bp-charlie) {
			    	width: 300px;
			    }
			}
		}
	}
	.impact-grid + .partners-layout {
		@include breakpoint($bp-bobby) {
			margin-top: -227px;
			padding: 328px 0 58px;
		}
	}
	.impact-sourcing {
		background:$pale-grey;
		padding: 0 0 $mobile-section-padding;
		float: left;
		width: 100%;
		.container {
			padding: 0;
			padding-top: 2.5rem;
			max-width: calc(1110px + 160px);
			width: 100%;
		}
		.intro {
			margin: 0 auto;
		    float: none;
		    position: relative;
		    text-align: center;
		}
		.projects {
			margin-top: 4rem;
			.project {
				display: flex;
				flex-direction: column;
				margin-bottom: 3rem;
				.left {
					height: 300px;
					background-position: center;
				    background-size: cover;
				    background-repeat: no-repeat;
					width: 100%;
					display: flex;
				    flex-wrap: nowrap;
				    align-items: center;
				    justify-content: center;
				    padding: 0 2rem;
					&.teal {
						background-color: $teal;
					}
					&.purple {
						background-color: $purple;
					}
					&.orange {
						background-color: $orange;
					}
					&.pink {
						background-color: $pink;
					}
					img {
						width: 50%;
						padding: 2rem;
					}
				}
				.right {
					height: 300px;
					width: 100%;
					overflow: hidden;
					background-color: #fff;
					padding: 5rem 4rem;
					position: relative;
					.more {
						display: none;
						height: 5rem;
					    position: absolute;
					    bottom: 0;
					    left: 0;
					    width: 100%;
					    background-color: #fff;
					    padding: 1rem 4rem;
						.more-btn {
							display: inline-flex;
							cursor: pointer;
							.fa {
								font-size: 12px;
							    margin-left: 0.5rem;
							    align-self: center;
							}
						}
					}
				}
				@include breakpoint($bp-george) {
					flex-direction: row;
					.left, .right {
						width: 50%;
					}
				}
			}
		}
	}
}