//Variables. Needed for style-guide.

// H1 Super
$h1super 		:  		44px;
$h1superTablet 	:  		40px;
$h1superMobile	:  		32px;
$h1superWeight	:  		800;
$h1superFont	:		$headline-font;
// H1
$h1 			:  		40px;
$h1Tablet 		:  		32px;
$h1Mobile		:  		24px;
$h1Weight		:  		800;
$h1Font			:		$headline-font;
// H2
$h2 			:  		35px;
$h2Tablet 		:  		28px;
$h2Mobile		:  		26px;
$h2Weight		:  		800;
$h2Font			:		$headline-font;
// H3 Large
$h3large 			:  	28px;
$h3largeTablet 		:  	26px;
$h3largeMobile		:  	22px;
$h3largeWeight		:  	800;
$h3largeFont		:	$headline-font;
// H3
$h3 			:  		22px;
$h3Tablet 		:  		20px;
$h3Mobile		:  		19px;
$h3Weight		:  		800;
$h3Font			:		$headline-font;
// H4
$h4 			:  		20px;
$h4Tablet 		:  		18px;
$h4Mobile		:  		17px;
$h4Weight		:  		800;
$h4Font			:		$headline-font;
// H5
$h5 			:  		18px;
$h5Tablet 		:  		17px;
$h5Mobile		:  		16px;
$h5Weight		:  		800;
$h5Font			:		$headline-font;
// H6
$h6 			:  		13px;
$h6Tablet 		:  		13px;
$h6Mobile		:  		12px;
$h6Weight		:  		700;
$h6Font			:		$headline-font;
//Body
$p 				:  		18px;
$pTablet 		:  		16px;
$pMobile		:  		15px;
$pWeight		:  		400;
$pFont			:		$primary-font;

h1, .h1 {
	font-family: $h1superFont; 
	color: $headlineColor;
	font-weight: $h1superWeight;
	font-size: $h1Mobile;  // mobile size
	line-height: 122%;
	margin: 0 0 .5em;
	letter-spacing: -0.01em;
	@include breakpoint($bp-charlie) { 
		font-size: $h1Tablet; // tablet size
	}
	@include breakpoint($bp-bobby) { 
		font-size: $h1; //desktop size
	}
	&.super {
		font-size: $h1superMobile;
		@include breakpoint($bp-charlie) { 
			font-size: $h1superTablet;
		}
		@include breakpoint($bp-bobby) { 
			font-size: $h1super;
		}
	}
	i, em, strong {
		font-weight: 600!important;
		font-style: normal!important;
	}
}

h2, .h2 {
	font-family: $h2Font; 
	color: $headlineColor;
	font-weight: $h2Weight;
	font-size: $h2Mobile;
	line-height: 135%;
	margin: 0 0 .5em;
	letter-spacing: -0.01em;
	@include breakpoint($bp-charlie) {
		font-size: $h2Tablet;
	}
	@include breakpoint($bp-bobby) {
		font-size: $h2;
	}
	i, em, strong {
		font-weight: $h2Weight!important;
		font-style: normal!important;
	}
}

h3, .h3 {
	font-family: $h3Font; 
	color: $headlineColor;
	font-weight: $h3Weight;
	font-size: $h3Mobile;
	line-height: 130%;
	margin: 0 0 .5em;
	letter-spacing: -0.01em;
	@include breakpoint($bp-charlie) {
		font-size: $h3Tablet;
	}
	@include breakpoint($bp-bobby) {
		font-size: $h3;
	}
	&.large {
		font-size: $h3largeMobile;
		@include breakpoint($bp-charlie) { 
			font-size: $h3largeTablet;
		}
		@include breakpoint($bp-bobby) { 
			font-size: $h3large;
		}
	}
	i, em, strong {
		font-weight: $h3Weight!important;
		font-style: normal!important;
	}
}

h4, .h4 {
	font-family: $h4Font; 
	color: $headlineColor;
	font-weight: $h4Weight;
	font-size: $h4Mobile;
	line-height: 150%;
	// letter-spacing: 0.5em;
	// text-transform: uppercase;
	margin: 0 0 .5em;
	@include breakpoint($bp-charlie) {
		font-size: $h4Tablet;
	}
	@include breakpoint($bp-bobby) {
		font-size: $h4;
	}
	i, em, strong {
		font-weight: $h4Weight!important;
		font-style: normal!important;
	}
}

h5, .h5 {
	font-family: $h5Font; 
	color: $headlineColor;
	font-weight: $h5Weight;
	font-size: $h5Mobile;
	line-height: 140%;
	margin: 0 0 .5em;
	@include breakpoint($bp-charlie) {
		font-size: $h5Tablet;
	}
	@include breakpoint($bp-bobby) {
		font-size: $h5;
	}
	i, em, strong {
		font-weight: $h5Weight!important;
		font-style: normal!important;
	}
}

h6, .h6 {
	font-family: $h6Font; 
	color: $headlineColor;
	font-weight: $h6Weight;
	font-size: $h6Mobile;
	line-height: 140%;
	margin: 0 0 .5em;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	@include breakpoint($bp-charlie) {
		font-size: $h6Tablet;
	}
	@include breakpoint($bp-bobby) {
		font-size: $h6;
	}
	i, em, strong {
		font-weight: $h6Weight!important;
		font-style: normal!important;
	}
}

a {
	color: $body-link-color;
	&:hover {
		color: $teal;
	}
}

p {
	font-family: $primary-font;
	font-weight: $pWeight;
	font-size: $pMobile;
	line-height: 155%;
	margin: 0 0 20px;
	letter-spacing: 0;
	color: $body;
	&.caption {
		font-size: 14px;
		@include breakpoint($bp-charlie) {
			font-size: $pMobile;
		}
	}
	&.small {
		font-family: $headline-font;
		font-weight: 600;
		text-transform: uppercase;
		font-size: 12px;
	}
	&.teaser {
		font-size: 14px;
		@include breakpoint($bp-charlie) {
			font-size: 16px;
		}
		@include breakpoint($bp-charlie) {
			font-size: $pTablet;
		}
	}
	@include breakpoint($bp-charlie) {
		font-size: $pTablet;
		margin: 0 0 25px;
	}
	@include breakpoint($bp-bobby) {
		font-size: $p;
		margin: 0 0 25px;
	}
}

body {
	font-family: $pFont;
	font-weight: $pWeight;
	font-size: $pMobile;
	line-height: 150%;
	letter-spacing: 0;
	color: $body;
	@include breakpoint($bp-charlie) {
		font-size: $pTablet;
	}
	@include breakpoint($bp-bobby) {
		font-size: $p;
	}
}

.content ul, .content ol {
	font-family: $primary-font;
	margin-left: 15px;
	padding-left: 15px;
	font-size: $pMobile;
	line-height: 154%;
	margin-bottom: 40px;
	font-family: $primary-font;
	font-weight: $pWeight;
	color: $body;
	@include breakpoint($bp-charlie) {
		font-size: $pTablet;
		line-height: 30px;
	 	margin-bottom: 30px;
	 	margin-left: 0;
		padding-left: 18px;
	}
	@include breakpoint($bp-bobby) {
		font-size: $p;
	}
	ul, ol {
		margin: 5px;
	}
	ul li {
		list-style: disc;
	}
}

.full-content ul {
	font-family: $primary-font;
	margin-left: 0;
	padding-left: 16px;
	font-size: $pMobile;
	line-height: 150%;
	margin-bottom: 40px;
	font-family: $primary-font;
	font-weight: $pWeight;
	color: $body;
	@include breakpoint($bp-charlie) {
		font-size: $pTablet;
		line-height: 30px;
	 	margin-bottom: 30px;
		padding-left: 24px;
	}
	@include breakpoint($bp-bobby) {
		font-size: $p;
	}
	li {
		list-style: none;
		position: relative;
		margin-bottom: 0.5em;
		&:before {
			content: '';
			position: absolute;
			left: -18px;
			top: 8px;
			width: 12px;
			height: 12px;
			border-radius: 100%;
			border: 2px solid $purple;
			@include breakpoint($bp-charlie) {
				left: -24px;
			}
		}
	}
}

.content .module a {
	font-weight: 700;
}

small {
	font-weight: 700;
	font-size: 13px;
	line-height: 120%;
	text-transform: uppercase;
	color: #999999;
	font-family: $headline-font;
	letter-spacing: 0.1em;
}

.post-meta,
.meta {
	font-family: $headline-font;
	text-transform: uppercase;
	color: $middle-grey;
	font-size: 12px;
	font-weight: 700;
	margin-bottom: 20px;
	line-height: 1.45;
	letter-spacing: 0.1em;
	@include breakpoint($bp-delta) {
		font-size: 13px;
	}
	.category {
		a {
			color: $navy !important;
			text-decoration: underline;
			&:hover {
				color: $dark-teal !important;
			}
		}
	}
	.pipe {
		color: $grey;
	}
}

.read-more { 
	margin: 0;
	font-weight: 700;
	font-size: 16px;
	@include breakpoint($bp-charlie) {
		font-size: 17px;
	}

	a{
		font-family: $headline-font;
		color: $dark-teal;
		font-weight: bold;
		&:hover {
			color: $teal;
		}

		svg {
			width: 12px;
			height: 12px;
			margin-left: 6px;
			transform: translateY(-1px);
		}
	}
}