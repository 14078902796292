.logo {
	.triangle {
		fill: $light-blue
	}
	.logo-text {
		fill: $charcoal;
		.st0 {
			fill: $charcoal !important;
		}
	}
}