.about-page {
	overflow: hidden;
	.home-about {	
		padding: $mobile-section-padding 0;	
		@include breakpoint($bp-delta) {			
			padding: 150px 0;
		}
		
		.image {
			background-position: 0 0;
			@include breakpoint($bp-delta) {
				right: -350px;
			}
			@include breakpoint($bp-header) {
				right: -250px;
			}
			@include breakpoint($bp-echo) {
				right: -150px;
			}			
		}
		.wrapper {
			padding-top: $mobile-section-padding;
			position: relative;			
			@include breakpoint($bp-delta) {
				padding-top: 125px;
			}
			@include breakpoint($bp-charlie) {
				padding-right: 25px;
			}
		}
		.container {
			// @include vert-center;
			@include breakpoint($bp-echo) {
				padding: 0;
			}
		}
	}

	.founder,
	.founder-image,
	.founder-content,
	.founder-content-wrap {
		height: 100%;
		position: relative;
		@include breakpoint($bp-bravo) {
			min-height: 450px;
		}
		@include breakpoint($bp-george) {
			min-height: 590px;
		}
	}

	.founder {		
		width: 100%;
		float: left;
		text-align: center;
		// @include breakpoint($bp-bravo) {
		// 	min-height: 450px;
		// }
		// @include breakpoint($bp-george) {
		// 	min-height: 590px;
		// }
		@include rgba-background($pastel-teal, 0.7);
		.founder-image {
			height: 200px;
			max-width: 100%;
			background-size: cover !important;
			background-position: 50% 50%;
			//position: absolute;
			left: 0;
			margin: 0;
			@include breakpoint($bp-bravo) {
				width: 45%;
				float: left;
				height: 100%;
			}
			@include breakpoint($bp-george) {
				width: 50%;
				
			}
		}
		.founder-content-wrap {
			width: 100%;
			padding: 0 $mobile-site-gutter;
			@include breakpoint($bp-bravo) {
				width: 55%;
				float: left;
			}
			@include breakpoint($bp-george) {
				width: 50%;				
				padding: 0 25px;
				//@include vert-center;
			}
			@include breakpoint($bp-delta) {
				padding: 0;
			}
		}

		.founder-content {
			max-width: 430px;
			margin: 0 auto;
			height: 100%;
			padding: $mobile-section-padding 0;
			@include breakpoint($bp-george) {
				padding: 0;
			}
			//@include vert-center();
		}
		.founder-content-inner {
			@include breakpoint($bp-george) {
				@include vert-center;
				position: absolute;
			}
		}
		.read-more {
			.btn {
				margin: 0 auto;
			}
		}
	}

	.callouts {
		clear: both;
		@include breakpoint($bp-george) {
			padding: 70px 0;	
		}		
		.callout {
			float: left;
			width: 100%;
			position: relative;
			padding: 50px 0;
			text-align: center;			
			border-bottom: 2px solid $border-color;
			min-height: 300px;
			@include breakpoint($bp-george) {
				width: 50%;
				border-bottom: 0;
				//padding: 50px 0;
				min-height: 400px;
			}
			.read-more {
				.btn {
					margin: 0 auto;
				}
			}
			&:nth-child(2n) {
				border-bottom: 0;
				@include breakpoint($bp-george) {
					border-left: 2px solid $border-color;
					.callout-content {
						padding: 0 49px;
					}
				}
			}
			.callout-content {
				padding: 0;				
				width: 100%;
				@include breakpoint($bp-bravo) {
					padding: 0 35px;
				}
				@include breakpoint($bp-george) {
					max-width: 560px;
					padding: 0 50px;
					@include center;
					position: absolute;
				}
			}
		}
	}

	.work {
		background: #eeeeee;
		float: left;
		width: 100%;
		padding: 65px 0;
		text-align: center; 
		@include breakpoint($bp-charlie) {
			padding: 85px 0;
		}
		@include breakpoint($bp-bobby) {
			padding: 135px 0;
		}
		.container {
			max-width: calc(1100px + 160px);
			width: 100%;
		}

		.intro {
			max-width: 650px;
			width: 100%;
			margin: 0 auto 80px;
			h3 {
				text-align: center;
				margin-bottom: 25px;
			}
			.description {
				margin: 0;
				text-align: center;
				p {
					font-size: 18px;
					line-height: 155%;
					color: $body;
					text-align: center;
					@include breakpoint($bp-charlie) {
						font-size: 20px;
					}
					&:last-of-type {
						margin: 0;
					}
				}
			}
		}

		.read-more {
			clear: both;
			.btn {
				margin: 0 auto;
			}
		}

		.icon-grid {
			$grid-margin: 2.5%;
			float: left;
			width: 100%;
			margin-bottom: 50px;
			.single-item {
				display: inline-block;
				width: 100%;
				margin: 0 0 55px;
				position: relative;
				&:last-of-type {
					margin-bottom: 0;
				}
				@include breakpoint($bp-bravo) {
					width: calc((100% - ( #{$grid-margin} * 4))/2);
					float: left;
					margin: 0 $grid-margin 3em;
				}
				@include breakpoint($bp-bobby) {
					width: calc((100% - ( #{$grid-margin} * 8))/4);	
					margin-bottom: 0;				
					// margin: 0 $grid-margin;
				}
				&:hover {
					h5 {
						color: $teal;
					}
					&:first-of-type {
						h5 {
							color: $orange;
						}
					}
					&:nth-of-type(2) {
						h5 {
							color: $purple;
						}
					}
					&:last-of-type {
						h5 {
							color: $pink;
						}
					}
				}
				a {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
				h5 {
					text-align: center;
					font-size: 16px;
					@include hover-fade();
				}
				.icon {
					height: 98px;
					width: 100%;
					margin-bottom: 25px;
					text-align: center;
					@include breakpoint($bp-bobby) {
						margin-bottom: 30px;
					}
					svg {
						height: 105px;
						width: auto;
					}
				}
			}
		}
	}

	.wrapper2 {
		position: relative;
		width: 100%;
		float: left;
		margin-top: 0;
		overflow: hidden;
    	.slide {
    		float: left;
		    width: 100%;
		    height: auto;
		    position: relative;
		    height: 100%;
    	}
    	.progress {
    		position: fixed;
    		top: 75px;
    		left: 0;
    		width: 0%;
    		height: 3px;
    		background: $primary;
    		z-index: 5;
    	}
	}
	// .home-about {
	// 	height: calc(100vh - 75px);
	// 	padding: 0;
	// 	.container {
	// 		position: relative;
	// 		height: 100%;
	// 		padding-top: 400px;
	// 	}
	// 	.wrapper {
	// 		position: relative;
	// 		left: auto;
	// 		top: auto;
	// 		@include transform(translateY(0));
	// 		margin-bottom: 300px;
	// 		&:last-of-type {
	// 			margin-bottom: 200px;
	// 		}
	// 	}
	// 	.background {
	// 		float: left;
	// 		width: 100%;
	// 		position: absolute;
	// 		top: 0;
	// 		left: 0;
	// 		background: #0b181e;
	// 		height: 100%;
	// 	}
	// 	.image {
	// 		max-height: calc(100vh - 100px);
	// 		.inner-img {
	// 			width: 100%;
	// 			height: 100%;
	// 			background-size: cover!important;
	// 			position: absolute;
	// 			top: 0;
	// 			left: 0;
	// 			opacity: 0;
	// 			transform: scale(1.10);
	// 			&.image_01 {
	// 				opacity: 1;
	// 				transform: scale(1);
	// 			}
	// 		}
	// 	}
	// }
	.scroller-wrap {
		width: 100%!important;
		position: relative;
		float: left;
		.impact-stories {
			display: block;
			float: left;
			width: 100%;
		}
		.nav-wrap {
			position: absolute;
			top: 420px;
			left: 0;
			width: 100%;
			z-index: 1000!important;
			padding: 0 20px;
			margin: 0;
			display: table;
			text-align: center;
			@include breakpoint($bp-bobby) {
				position: absolute;
				bottom: 50px;
				left: 0;
				top: auto;
				width: 50%;
				z-index: 99;
				padding: 0 20px;
			}
			.arrow {
				width: 21px;
				display: block;
				padding: 0;
				cursor: pointer;
				float: left;
				&.next {
					float: right;
				}
				@include breakpoint($bp-bobby) {
					float: none;
					padding: 0 25px;
					display: inline-block;
					&.next {
						float: none;
					}
				}
				svg {
					width: 21px;
					.st0{
						@include hover-fade();
						fill:none;
						stroke:$body;
						stroke-width:2;
						stroke-miterlimit:10;
					}
				}
				&:hover svg .st0 {
					stroke: $secondary;
				}
			}
			ul {
				display: inline-block;
				margin: 0;
				padding: 0 12px 0 28px;
			}
			li {
				margin: 0;
				padding: 0 15px;
				float: none;
				list-style: none;
				cursor: pointer;
				width: auto!important;
				font-family: $headline-font;
				font-size: 11px;
				font-weight: 700;
				color: #444444;
				letter-spacing: 0.08em;
				text-transform: uppercase;
				@include hover-fade();
				display: none;
				@include breakpoint($bp-bobby) {
					display: inline-block;
					padding: 0 28px;
				}
				&:hover, &.active {
					color: $secondary;
				}
			}
		}
	}
	.impact-slider {
		position: relative;
		overflow: hidden;
		width: 100%!important;
		height: auto;
		@include breakpoint($bp-bobby) {
			height: 620px;
		}
		.slick-arrow {
			display: none!important;
		}
		.single {
			float: left;
			width: 100%;
			height: auto;
			@include breakpoint($bp-bobby) {
				height: 620px;
			}
			position: relative;
			//z-index: 50!important;
		}
		.slick-list {
			overflow: hidden!important;
		}
		.single-story {
			position: relative;
			float: left;
			width: 100%;
			height: auto;
			@include breakpoint($bp-bobby) {
				height: 620px;
			}
			.image {
				top: 0!important;
			}
			.wrapper {
				padding: 0;
				height: 100%;
				padding-top: 25px;
				background: $white;
				@include breakpoint($bp-bobby) {
					padding-top: 55px;
				}
				.inner-wrap {
					position: relative;
					top: auto;
					@include transform(translateY(0));
					left: auto;
					right: auto;
					img {
						max-height: 95px;
						max-width: 190px;
					}
				}
			}
		}
	}
}

section {
  text-align: center;
  color: #EFEFEF;
}

.blue {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px;
}

.blue {
	background-color: #3883d8;
}
.red {
	background-color: #cf3535;
}
.orange {
	background-color: #ea6300;
}