/* Global Styles for Slick Slider */
.slick-dots {
	position: absolute;
	bottom: -45px;
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: right;
	li {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 20px;
		margin: 0 12px;
		padding: 0;
		cursor: pointer;
		&:last-of-type {
			margin-right: 0;
		}
		button {
			font-size: 0;
			line-height: 0;
			display: block;
			width: 20px;
			height: 20px;
			padding: 5px;
			cursor: pointer;
			color: transparent;
			border: 0;
			outline: none;
			background: transparent;
			&:before {
				background: $grey;
				border-radius: 100%;
				line-height: 20px;
				position: absolute;
				top: 0;
				left: 0;
				width: 12px;
				height: 12px;
				content: '';
				text-align: center;
				opacity: .7;
			}
		}
	}
	.slick-active {
		button {
			&::before {
				opacity: 1;
				background: $light-blue;
			}
		}
	}
}