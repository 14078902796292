$single-work-margin: 15px;

@keyframes fadeOutZoom {
  0% {
  	transform: scale(1);
  	opacity: 0.44;
  }
  100% {
    transform: scale(0.75);
  	opacity: 0;
  }
}

@keyframes fadeInZoom {
  0% {
    transform: scale(0.75);
  	opacity: 0;
  }
  100% {
    transform: scale(1);
  	opacity: 0.44;
  }
}
// GLOBAL HOME
.home {
	#container-wrap {
		overflow-x: hidden;
	}
	.container {
		@include breakpoint($bp-charlie) {
			max-width: calc(1110px + 160px);
			width: 100%;
		}
	}
	.slick-dots {
		@include breakpoint( $bp-charlie, max ) {
			text-align: center;
		}
	}
}

/* ~~~ ABOUT SECTION ~~~ */
.home-about {
	padding: $mobile-section-padding 0;
	background:$pale-grey;
	//display: table; 
	width: 100%;
	position: relative;
	overflow: hidden;
	@include breakpoint($bp-bobby) {
		padding: 190px 0;
	}
	// .container {
	// 	max-width: calc(1110px + 160px);
	// 	width: 100%;
	// }
	.image {
		position: absolute;
		height: 100%;
		width: 985px;
		background-size: cover!important;
		background-position: -50px top;
		background-repeat: no-repeat;
		bottom: 0;
		right: -340px;
		-webkit-clip-path: polygon(50% 0%, 0 100%, 100% 100%);
		clip-path: polygon(50% 0%, 0 100%, 100% 100%);
		display: none;
		@include breakpoint($bp-delta) {
			right: -350px;
		}
		@include breakpoint($bp-bobby) {
			display: block;
		}
		@include breakpoint($bp-header) {
			right: -250px;
		}
		@include breakpoint($bp-echo) {
			right: -100px;
		}
		.overlay-n {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 60%;
			background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
			background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
			background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
			opacity: 0.65;
		}
	}
	.credit {
		position: absolute;
		bottom: 60px;
		left: 0;
		right: 0;
		margin: 0 auto;
		text-align: right;
		max-width: calc(1110px + 130px);
		padding: 0 35px;
		width: 100%;
		color: rgba(246,246,247,.7)!important;
	}
	.wrapper {
		max-width: 480px;
		margin: 0 auto;
		text-align: center;
		@media only screen and (max-width: $bp-header) and (min-width: $bp-delta ) {
			max-width: 450px;
		}
		@include breakpoint($bp-bobby) {
			margin: 0;
			text-align: left;			
		}
		h6 {
			color: $middle-grey;
			margin-bottom: 10px;
		}
		h2 {
			color: $charcoal;
			margin-bottom: 20px;
			font-size: 25px;
			@include breakpoint($bp-bobby) {
				//margin-bottom: 35px;
				font-size: $h2;
			}
		}
		.description {
			margin-bottom: 35px;
			p {
				//color: #aaaaaa;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
		.btn {
			color: $navy;
			border: 2px solid $teal;
			display: inline-block;
			&:hover {
				color: $charcoal;
				background: $teal;
			}
		}
	}
}

/* ~~~ WORK SECTION ~~~ */
.home-work {
	//display: table;
	width: 100%;
	//padding: 0 0 80px 0;
	background: #eee;
	position: relative;
	z-index: 80;
	//overflow-y: hidden!important;
	background: $white;
	@include breakpoint($bp-bobby) {
		//padding: 0 0 135px 0;
	}
	@include breakpoint(1145px) {
		//padding: 0 0 200px 0;
	}
	.top-fade {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 70%;
		background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
		background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
		background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
		opacity: 1;
		z-index: 3;
		display: none;
	}
	// .container {
	// 	@include breakpoint($bp-charlie) {
	// 		max-width: calc(1110px + 160px);
	// 		width: 100%;
	// 	}
	// }
	.impact-grid {
		.single-impact {

			
		}
	}
}

/* ~~~ PARTNERSHIPS SECTION ~~~ */
.home-partnerships {
	height: auto;
	position: relative;
	padding: $mobile-section-padding 0;
	opacity: 1;
	overflow: hidden;
	@include breakpoint(967px) {
		//opacity: 0;
	}
	@include breakpoint($bp-bobby) {
		//height: 700px;
		padding: 105px 0 155px;
	}
	.innerlogos {
		// position: absolute;
		// right: 0;
		//top: 0;
		//height: 730px;
		float: left;
		width: 100%;
		//background-image: url('img/map.svg');
		//background-repeat: no-repeat!important;
		//background-position: top left;
		//background-size: 100%;
		@include transform(scale(0.85));
		// display: none;
		@include breakpoint($bp-bobby) {
			display: block;
			width: 60%;
			padding-left: 80px;
		}
		.slick-dots {
			// resets scaling, only logos should scale
			@include breakpoint($bp-charlie, max) {
				@include transform(scale(1.16));
			}			
		}
		@include breakpoint(1145px) {
			@include transform(scale(1));
			right: -415px;
		}
		.dot {
			position: absolute;
			top: 0;
			left: 0;
			.inner-dot {
				width: 65px;
				height: 65px;
				background: rgba($purple, 0.4);
				border-radius: 100%;
				position: relative;
				cursor: pointer;
				z-index: 2;
				transition: background 0.1s;
				&:before {
					content: '';
					position: absolute;
					top: 50%;
					left: 0;
					right: 0;
					margin: 0 auto;
					@include transform(translateY(-50%));
					width: 20px;
					height: 20px;
					background: $purple;
					border-radius: 100%; 
					z-index: 1;
				}
				&:after {
					content: '';
					position: absolute;
					top: 50%;
					left: 0;
					right: 0;
					margin: 0 auto;
					@include transform(translateY(-50%) scale(0.7));
					width: 20px;
					height: 20px;
					background: #f4a74e;
					border-radius: 100%; 
					z-index: 2;
					opacity: 0;
					transition: opacity .35s,transform .25s;
				}
				&:hover {
					background: rgba(#f4a74e, 0.35);
					&:after {
						opacity: 1;
						@include transform(translateY(-50%) scale(1.2));
					}
				}
			}
			&.north_america {
				top: 358px;
				left: 260px;
			}
			&.latin_america {
				top: 557px;
				left: 372px;
			}

			&.western_eu {
				top: 320px;
				left: 506px;
			}
			&.asia {
				top: 393px;
				left: 706px;
			}
			&.saharan {
				top: 493px;
				left: 621px;
			}
			&.middle {
				top: 385px;
				left: 603px;
			}
			&.middle, &.asia, &.western_eu, &.saharan {
				.info-card {
					left: auto;
					right: -40px;
				}
			}
			.info-card {
				width: 300px;
				background: #fff;
				box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.25);
				position: absolute;
				left: -29px;
				top: 25px;
				z-index: 10;
				visibility: hidden;
				opacity: 0;
				transition: visibility 0s, top 0.13s linear, opacity 0.15s linear;
				i {
					font-size: 14px;
					color: #fff;
					position: absolute;
					right: 3px;
					top: 3px;
					z-index: 10;
					padding: 5px;
					cursor: pointer;
					@include hover-fade();
					&:hover {
						color: #fff;
						opacity: 0.75;
					}
				}
				.logo {
					width: 100%;
					height: 180px;
					background-position: 50% 50%;
					background-size: cover!important;
					position: relative;
					&:before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 1;
						background:#000;
						opacity: 0.4;
					}
					img {
						max-height: 120px;
						max-width: 135px;
						position: absolute;
						z-index: 2;
						top: 50%;
						@include transform(translateY(-50%));
						left: 0;
						right: 0;
						margin: 0 auto;
						filter: brightness(0) invert(1);
						&.no-filter {
							filter: brightness(1) invert(0);
						}
					}
				}
				.text {
					padding: 30px;
					h5 {
						text-align: center;
						font-size: 20px;
						line-height: 120%;
					}
					p {
						text-align: center;
						font-size: 14px;
						line-height: 150%;
						color: #444444;
						&:last-of-type {
							margin-bottom: 0;
						}
					}
					small {
						float: left;
					    width: 100%;
					    padding: 20px 0 27px;
					    letter-spacing: 0.08em;
					    text-align: center;
					}
				}
			}
			&.active {
				.inner-dot {
					background: rgba(#f4a74e, 0.35);
					&:after {
						opacity: 1;
						@include transform(translateY(-50%) scale(1.2));
					}
				}
				.info-card {
					top: 50px;
					visibility: visible;
					opacity: 1;
				}
			}
		}
	}
	.wrapper {
		width: 100%;
		//padding-top: 20px;
		// max-width: 40%;
		float: left;
		position: relative;
		z-index: 6;
		margin: 0 auto;
		text-align: center;
		@include breakpoint($bp-bravo) {
			width: 100%;
			max-width: 480px;
			margin: 0 auto;
			position: relative;
			float: none;
		}
		@include breakpoint($bp-bobby) {
			//padding-top: 275px;
			margin: 0;
			text-align: left;
			width: 40%;
			float: left;
		}
		h6 {
			color: $middle-grey;
			margin-bottom: 10px;
		}
		h2 {
			color: $charcoal;
			margin-bottom: 20px;
		}
		.description {
			margin-bottom: 35px;
			p {
				color: #444444;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
		.btn {
			// color: $charcoal;
			// border: 2px solid #0b181e;
			display: inline-block;
			&:hover {
				//color: #fff;
				background: $teal;
			}
		}
	}
}

.home-partners-scroller {
	//background: #eeeeee;
	//padding: 50px 15px;
	float: left;
	width: 100%;
	@include breakpoint($bp-charlie) {
		//padding: 65px 15px;
	}
	@include breakpoint($bp-bobby) {
		//padding: 120px 20px;
	}
	// .container {
	// 	max-width: calc(1110px + 160px);
	// 	width: 100%;
	// 	position: relative;
	// }
	#home-scroll {
		float: left;
		width: 100%;
		list-style: none;
		position: relative;
		outline: none;
		.slick-arrow {
			position: absolute;
			top: 50%;
			@include transform(translateY(-50%) rotate(180deg));
			left: -30px;
			width: 23px;
			height: 23px;
			text-indent: -9000px;
			color: transparent;
			cursor: pointer;
			background: url('img/arrow.svg');
			background-size: 100%!important;
			background-repeat: no-repeat;
			@include hover-fade();
			display: none;
			opacity: 0;
			@include breakpoint($bp-charlie) {
				display: block;
				opacity: 1;
			}
			&.slick-next {
				right: -30px;
				left: auto;
				@include transform(translateY(-50%) rotate(0deg));
			}
		}
		.slick-list {
			overflow: hidden!important;
		}
		.single-slide {
			float: left;
			width: 100%;
			position: relative;
			@include breakpoint($bp-bobby) {
				right: -23px; // false align w slick dots
			}
		}
		.single-image {
			width: calc(100%/2)!important;
			height: 125px!important;
			position: relative;
			float: left;
			margin: 10px 0;
			@include breakpoint($bp-charlie) {
				height: 125px!important;
				width: calc(100%/4)!important;
			}
			a {
				&:hover {
					img {
						transition: 0.35s;
						-webkit-filter: none;
    					filter: none;
					}					
				}
			}
			img {
				max-width: 51%;
				max-height: 72%;
				position: absolute;
				top: 50%;
				@include transform(translateY(-50%));
				left: 0;
				right: 0;
				margin: 0 auto;
				-webkit-filter: grayscale(100%);
    			filter: grayscale(100%);
			}
		}
	}
}

// /* ~~~ IMPACT STORIES ~~~ */
.home-stories {
	margin-top: 0;
	.container {
		padding: 0;
		@include breakpoint($bp-bobby) {
			//padding: 0 40px;
		}
		@include breakpoint($bp-echo) {
			max-width: 90%; 
		}
	}
	.slick-dots {
		@include breakpoint($bp-bravo) {
			margin-bottom: -20px;
		}
		@include breakpoint($bp-nina) {
			margin-bottom: 0;
		}
	}
	.inner-wrap {
		@include breakpoint($bp-bravo) {
			max-width: 550px !important;
		}
		@include breakpoint($bp-delta) {
			max-width: 430px !important;
		}
	}
	.single-story {
		.image {
			@include breakpoint($bp-bravo) {
				max-width: 650px;
			}
			@include breakpoint($bp-delta) {
				max-width: 100% !important;
			}
		}
	}
}

/* ~~~ NEWS SECTION ~~~ */
.home,.page-template-template-initiative-page {
	.module.news-feed {
		.news-items {
			.news-item {
				text-align: left;
				height: auto; 
				position :relative;
				@include breakpoint($bp-bobby) {
					height: 100%; 
				}
				&:last-of-type {
					margin-right: 0;
				}
				a {
					position: relative;

				}
				h3 {
					text-align: left;
					@media only screen and (max-width: $bp-delta) and (min-width: $bp-bravo ) {
						font-size: 17px;
					}
					.highlight {
						@include underline($middle-purple, 2px, 1.15s);
						&:hover {
							background-size: 100% 100%;
						}	
					}
					a {
						&:hover {
							color: $charcoal;
						}
					}
				}

				.news-content {
					position: relative;					
					@include breakpoint($bp-bravo) {
						min-height: 240px;
					}
					@include breakpoint($bp-delta) {
						min-height: 280px;
					}
				}
				.text-container {
					position: relative;
				}
				.read-more {
					position: absolute;
					bottom: 20px;
					right: 0;
				}
			}
		}
	}
}