.container {
    margin: 0 auto;
    padding: 0 $mobile-site-gutter;
    // max-width: 100%;
    width: 100%;
    @include breakpoint($bp-charlie) {
        padding: 0 40px;
        max-width: 1145px;
    }
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

main {
    float: left;
    width: 100%;
}

hr {
    width: 100%;
    height: 2px;
    background: $hrColor;
    border-radius: 0px;
    margin: 25px auto 25px auto;
    @include breakpoint($bp-charlie) {
        margin: 30px auto 30px auto;
    }
    @include breakpoint($bp-bobby) {
        margin: 40px auto 40px auto;
    }
    &.divider {
         margin: 35px auto 0px auto;
        @include breakpoint($bp-charlie) {
            margin: 55px auto 0px auto;
        }
        @include breakpoint($bp-bobby) {
            margin: 60px auto 0px auto;
        }
    }
    &.long {
        width: 100%;
        height: 2px;
        max-width: 100%;
        margin: 50px auto 0 auto;
        background: #eaeaea!important;
        @include breakpoint($bp-charlie) {
            margin: 0;
        }
    }
}

.issues-page {
    hr {
        &.divider {
            margin: 40px auto 0px auto;
            @include breakpoint($bp-charlie) {
                margin: 55px auto 0px auto;
            }
            @include breakpoint($bp-bobby) {
                margin: 75px auto 0px auto;
            } 
        }
    }
}

.content {
    float: left;
    width: 100%;
    .col-md-8 {
        margin: 0 auto;
        display: table;
        float: none;
    }
    .bottom-share {
        padding: 25px 0;
        width: 100%;
        float: left;
        text-align: center;
        display: block;
        @include breakpoint($bp-bobby) {
            display: none;
        }
        .social {
            display: table;
            float: none;
            margin: 0 auto;
            span {
                display: none;
            }
            ul {
                li {
                    a {
                        color: $primary;
                        &:hover {
                            color: $secondary;
                        }
                        i {
                            font-size: 23px;
                            @include breakpoint($bp-charlie) {
                                font-size: 29px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.upnext-wrapper {
    padding: 45px 0;
    background: #eeeeee;
    display: table;
    width: 100%;
    overflow: hidden;
    @include breakpoint($bp-charlie) {
        padding: 75px 0;
    }
    @include breakpoint($bp-bobby) {
        padding: 108px 0;
    }
    .upnext {
        margin-top: 0!important;
        margin-bottom: 0;
        height: auto;
        position: relative;
        padding: 50px 0 85px;
        @include hover-fade();
        margin: 0 auto;
        width: 100%;
        display: table;
        z-index: 25;
        max-width: 1170px;
        background: #fff;
        border-radius: 0;
        box-shadow: 0px 8px 60px 0px rgba(0, 0, 0, 0.15);
		&:not(.can-hover) {
			padding-bottom: 50px;
		}
        &.can-hover:hover {
            background-color: $light-blue;
            background-color: $pastel-teal;
            h1 {
                color: $charcoal;
            }
            h6 {
                color: $charcoal;
            }
            .arrow svg .st0 {
                stroke: $teal;
            }
        }
        h1 {
            text-align: left;
            color: $charcoal;
            margin: 0;
            @include hover-fade();
        }
        h6 {
            text-align: left;
            color: $middle-grey;
            margin-bottom: 20px;
            @include hover-fade();
        }
        @include breakpoint($bp-charlie) { 
            padding: 50px 0;
            height: 280px;
            padding: 0;
        }
        @include breakpoint($bp-bobby) { 
            padding: 150px 0;
            height: 350px;
            padding: 0;
        }
        & > a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10;
        }
        .container {
            max-width: 100%;
            width: 100%;
            padding: 0 50px;
            @include breakpoint($bp-charlie) { 
                max-width: 90%;
                position: absolute;
                top: 50%;
                max-width: 90%;
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
                left: 0;
                padding: 0 100px;
            }
            @include breakpoint($bp-delta) { 
                max-width: 90%;
                padding: 0 135px;
            }
        }
        .arrow {
            width: 35px;
            position: absolute;
            top: auto;
            margin: 0 auto;
            right: 25px;
            top: 35px;
            @include transform(translateY(0));
            right: 20px;
            svg {
                .st0{
                    fill:none;
                    @include hover-fade();
                    stroke: $primary;
                    stroke-width:2;
                    stroke-miterlimit:10;
                }
            }
            @include breakpoint($bp-charlie) {
                width: 45px;
                right: 45px;
                margin: 0;
                left: auto;
                bottom: auto;
                top: 45px;
           }
        }
    }
}

.vertical-centered {
    float: left;
    width: 100%;
    text-align: center;
    @include breakpoint($bp-charlie) {
        @include vertical-align();
    }
}

.video-cover {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-size: cover !important;
    z-index: 2;
    cursor: pointer;
}
.videoWrapper {
    position: relative;
    padding-bottom: 35.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    .play-button {
        width: 100%;
        height: 100%;
        position: relative;
        top: 0;
        left: 0;
        cursor: pointer;
        .play-bg {
            background: #fff;
            width: 28px;
            height: 28px;
            top: 50%;
            left: 50%;
            margin: -14px 0 0 -14px;
            position: absolute;
            @include hover-fade();
            @include breakpoint($bp-charlie) {
                width: 44px;
                height: 44px;
                margin: -22px 0 0 -22px;
            }
        }
        svg {
            width: 60px;
            height: 60px;
            position: absolute;
            top: 50%;
            @include transform(translateY(-50%));
            left: 0;
            right: 0;
            margin: 0 auto;
            transition: .3s;
            @include breakpoint($bp-charlie) {
                width: 90px;
                height: 90px;
            }
        }
        &:hover {
            svg {
                @include transform(translateY(-50%) scale(1.12));
            }
        }
    }
}
.videoWrapper iframe,
.videoWrapper .wp-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.page-id-1350, .page-id-1780 {
    .module.last-module {
        margin-bottom: 45px;
    }
    .module.accordion-m .accordion dt a h3 {
        margin: 0;
        padding: 5px 0;
        line-height: 140%;
        font-size: 20px;
        @include breakpoint($bp-charlie, max) {
            max-width: 80%;
        }
    }
}

.upnext__multi-links {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: flex-start;
	margin-top: 10px;

	@include breakpoint($bp-bobby) {
		margin-top: 30px;
	}
}

.upnext__multi-link__item {
	width: 100%;

	@include breakpoint($bp-bobby + 80px) {
		max-width: 350px;
	}
}

.upnext__link {
	font-family: $headline-font;
	font-size: 13px;
	color: $teal;
	display: inline-block;
	padding: 5px 0;
    font-weight: 700;
    &:hover {
        color: $purple;
    }

	@include breakpoint($bp-charlie) {
		font-size: 15px;
	}

	@include breakpoint($bp-bobby) {
		padding: 15px 0;
	}

	i {
		margin-left: 5px;
	}

	.upnext__multi-link__item:nth-child(1) &,
	.upnext__multi-link__item:nth-child(2) & {
		@include breakpoint($bp-bobby + 80px) {
			border-bottom: 2px solid $grey;
		}
	}
}

#password-content {
    background: #fff;
    min-height: 100vh;
    display: flex;
    align-items: center;

    .password-form {
        margin: 0 auto;
        text-align: center;

        input[type="password"] {
            border: 1px solid $details;
            padding: 4px 8px;
        }

        .btn {
            float: none;
            margin: 15px auto;
        }
    }


    .logo {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        svg {
            width: 65px;
            height: 86px;
            fill: $primary;
            @include hover-fade;

            &:hover {
                transform: scale(1.1);
            }

            .st0 {
                fill: $body;
            }
        }
    }

}