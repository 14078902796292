
.module-stories {
	background: $pale-grey;
	padding: 75px 0 110px;
	margin-top: 60px;
	@include breakpoint($bp-charlie, max) {
		padding: $mobile-section-padding 0;
	}
	.container {
		padding: 0;
		@include breakpoint($bp-charlie) {
			max-width: calc(1110px + 160px);
			width: 100%;
		}
		@include breakpoint($bp-bobby) {
			//padding: 0 40px;
		}
		@include breakpoint($bp-echo) {
			max-width: 90%; 
			//padding: 0;
		}
	}
}

.impact-stories {
	float: left;
	width: 100%;
	display: table;
	background: $pale-grey;
	//padding: 75px 0;
	@include breakpoint($bp-charlie, max) {
		background: $white;
	}
	.slick-dots {
		display: block !important;
		@include breakpoint($bp-charlie) {
			width: 100%;
			margin: 0 auto;
			//padding: 0 40px;
			position: relative;
			top: 25px;
		}
	}
	.single-story {
		@include breakpoint($bp-charlie, max) {
			background: $white;
		}
		//background: $white;
		float: left;
		width: 100%;
		position: relative;
		z-index: 85;
		.image {
			width: 100%;
			max-width: 450px;
			height: 350px;
			position: relative;
			margin: 0 auto 30px;
			background-position: top center;
			background-size: cover!important;
			//box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.15);
			a.full-link {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 10;
				outline: none;
			}
			.video-button,
			.play-button {
				width: 85px;
				height: 85px;
				position: absolute;
				top: 50%;
				@include transform(translateY(-50%));
				margin: 0 auto;
				left: 0;
				right: 0;
				//z-index:999; this is breaking the play functionality for some reason
				svg {
					width: 100%;
					@include hover-fade();
					.st1 {
						fill: $primary;
						@include hover-fade();
					}
					.st0 {
						fill: $primary;
						@include hover-fade();
					}
				}
			}
			@include breakpoint($bp-charlie) {
				width: 95%;
				max-width: 450px;
				height: 450px;
			}
			@include breakpoint($bp-bobby) {
				width: 50%;
				height: 100%;
				max-width: 100%;
				position: absolute;
				left: 0;
				//top: -40px;
				margin: 0;
			}

			@include breakpoint($bp-charlie) {
				&:after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: $middle-grey;
					opacity: 0.05;
					z-index: 1;
				}
			}			

			&.quote {
				height: auto;
				//background: $pale-grey;
				@include breakpoint($bp-charlie) {
					width: 95%;
					max-width: 550px;
					height: auto;
					box-shadow: none!important;
					background: $pale-grey;
				}
				@include breakpoint($bp-bobby) {
					background: $navy;
					max-width: 450px;
					box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.15);
					width: 50%;
					height: 100%;
					max-width: 100%;
					position: absolute;
					left: 0;
					top: -40px;
					margin: 0;
				}
				.inner {
					padding: 25px;
					position: relative;
					margin: 0 auto;
					max-width: 550px;
					width: 100%;
					@include breakpoint($bp-bobby) {
						max-width: 520px;
						position: absolute;
						top: 50%;
						@include transform(translateY(-50%));
						left: 0;
						right: 0;
					}
					.quote-t {
						font-family: $primary-font;
						font-weight: 400;
						//font-style: italic;
						font-size: 17px;
						line-height: 150%;
						color: $charcoal;
						text-align: center;
						@include breakpoint($bp-charlie) {
							font-size: 21px;
						}
						@include breakpoint($bp-bobby) {
							color: $white;
						}
					}
					.cite {
						margin-top: 25px;
						margin: 0;
						font-family: $headline-font;
						font-weight: 700;
						color: $charcoal;
						font-size: 16px;
						line-height: 150%;
						text-align: center;
						@include breakpoint($bp-bobby) {
							color: $pastel-teal;
						}
					}
				}
			}
		}
		&.image-right {
			.wrapper {
				@include breakpoint($bp-bobby) {
					float: left!important;
				}
			}
			.image {
				@include breakpoint($bp-bobby) {
					left: auto;
					right: 0;
				}
			}
		}
		.wrapper {
			padding: 150px 0;
			margin: 0 auto;
			text-align: center;
			img {
				max-width: 245px;
			    max-height: 120px;
				margin-bottom: 30px;
			}
			.text-under,
			.text-above {
				//margin-top: 25px;
				p {
					margin: 0;
					small {
						color: $middle-grey;
					}
				}
			}
			.text-under {
				margin-top: 25px;
			}
			.text-above {
				margin-top: 0;
				margin-bottom: 1em;
			}
		}
		&.has-image {
			height: auto;
			padding: 50px 20px;
			@include breakpoint($bp-charlie) {
				padding: 50px 0;
			}
			@include breakpoint($bp-bobby) {
				height: 610px;
				padding: 0;
			}
			.wrapper {
				margin: 15px auto 0;
				padding: 0;
				position: relative;
				height: auto;
				@include breakpoint($bp-bobby) {
					width: 50%;
					float: right;
					margin: 0;
					height: 100%;
				}
				.inner-wrap {
					height: auto;
					position: relative;
					width: 100%;
					max-width: 430px;
					margin: 0 auto;
					padding: 0 $mobile-site-gutter;
					@include breakpoint($bp-bobby) {
						position: absolute;
						@include transform(translateY(-50%));
						left: 0;
						right: 0;
						padding: 0;
						top: 50% !important;
						transform: translateY(-50%) !important;
					}
				}
				.description {
					p {
						font-family: $primary-font;
						font-weight: 400;
						//font-size: 16px;
						line-height: 155%;
						color: $charcoal;
						@include breakpoint($bp-delta) {
							font-size: 21px;
						}
						&:last-of-type {
							margin-bottom: 0;
						}
					}
				}
			}
		}
		&.quote {
			background: #eeeeee;
			.wrapper {
				max-width: 660px;
				width: 100%;
				padding: 50px 20px;
				@include breakpoint($bp-charlie) {
					padding: 50px;
				}
				@include breakpoint($bp-bobby) {
					padding: 150px 0;
				}
			}
			.description {
				p {
					//font-family: $primary-font;
					//font-weight: 400;
					font-style: italic;
					font-size: 19px;
					line-height: 150%;
					@include breakpoint($bp-charlie) {
						font-size: 23px;
					}
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
			.citation {
				margin-top: 25px;
				p {
					margin: 0;
					font-family: $headline-font;
					font-weight: 700;
					color: $charcoal;
					font-size: 16;
					line-height: 150%;
				}
			}
		}
	}
}

.module.scroller-wrap {
	margin-top: 0;
	.impact-stories {
		background: $white;
		@include breakpoint($bp-bobby) {
			height: 600px; 
		}
		.single-story {
			padding: 0 0 50px;
			@include breakpoint($bp-bobby) {
				height: 600px;
				padding: 50px 0; 
			}
		}
	}
	.wrapper {
		margin-top: 0 !important;
		padding-top: 0 !important;
	}
}