// .top-bar {
// 	width: 100%;
// 	height: 55px;
// 	background-size: cover!important;
// 	background-image: url('img/tent-pattern-border-blue-small.png');
// 	background-repeat: none;
// 	background-position: 0px -1px;
// 	@include breakpoint($bp-charlie) {
// 		height: 75px;
// 		background-image: url('img/tent-pattern-border-blue.png');
// 	}
// }

.bottom-cta {
	float: left;
	width: 100%;
	background-color: $light-blue;
	position: relative;
	color: white;
	@include breakpoint($bp-delta) {
		min-height: 600px;
	}
	.bottom-cta-image {
		@include breakpoint($bp-charlie) {
			width: 50%;
			float: left;
		}
		.image {
			display: none;
			@include breakpoint($bp-charlie) {
				display: block;
				width: 50%;
				height: 100%;
				max-width: 100%;
				background-size: cover !important;
				position: absolute;
				left: 0;
				margin: 0;
			}
		}
	}
	.wrapper {
		width: 100%;
		@include breakpoint($bp-charlie) {
			width: 50%;
			float: right;	
		}
	}
	// .top-bar {
	// 	position: absolute;
	// 	top: 0;
	// 	left: 0;
	// }
	.inner {
		text-align: center;
		padding: 50px 20px;
		position: relative;
		top: auto;
		max-width: 360px;
		margin: 0 auto;
		@include transform(translateY(0));
		right: auto;
		left: auto;
		@include breakpoint($bp-charlie) {
			//text-align: left;
		}
		@include breakpoint($bp-delta) {
			padding: 100px 0;
		}
		h2 {
			//text-align: center;
			color: $charcoal;
			margin-bottom: 0.25em;
			@include breakpoint($bp-bravo) {
			}
			@include breakpoint($bp-charlie) {
				margin-bottom: 0.5em;
			}
		}
		svg {
			width: 50px;
			display: block;
			margin: 0 auto 40px;
			@include breakpoint($bp-bobby) {
				width: 80px;
			}
			.st0 {
				fill: $white;
			}
		}
	}
	.deck {
		margin-bottom: 25px;
		position: relative;
		p {
			color: $charcoal;
			font-size: 16px;
			line-height: 155%;
			//text-align: center;
			margin: 0;
			@include breakpoint($bp-bravo) {
				font-size: 18px;
			}
			@include breakpoint($bp-delta) {
				font-size: 21px;
			}
		}
	}
	.container, .row {
		height: 100%;
	}
	.bottom-cta-form {
		margin: 0 auto;
		display: table;
		float: none;
		//max-width: 573px;
		padding: 0;
		.form-wrapper {
			max-width: 300px;
			width: 100%;
			margin: 0 auto;
			display: table;
		}
		.btn {
			display: table;
			float: none;
			max-width: 380px;
			margin: 0 auto;
			border-color: $pastel-teal;
		}
		form {
			.email {
				float: left;
				width: 100%;
				margin-bottom: 4px;
			}
			.zip {
				float: left;
				width: 100%;
				margin-bottom: 13px;
			}
			.submit {
				width: 100%;
				float: left;
				margin-bottom: 0;
			}
		}
	}
}

footer {
	float: left;
	width: 100%;
	padding: 50px 0;
	color: $charcoal;
	background: #fff;
	@include breakpoint($bp-delta) {
		padding: 100px 0;
	}
	.container {
		max-width: calc(1110px + 160px);
		width: 100%;
	}
	a {
		//color: $charcoal;
		&:hover {
			color: $secondary;
		}
	}
	&.submenu {
		padding: 40px 0 100px;
		@include breakpoint($bp-delta) {
			padding: 40px 0;
		}
	}
	.logo-footer {
		text-align: center;
		margin-bottom: 40px;
		@include breakpoint($bp-delta) {
			//margin-bottom: 80px;
			text-align: left;
			margin-bottom: 0;
		}
		svg {
			width: 45px;
			display: inline-block;
			@include transition(all 0.3s ease-in-out);
			.st1 {
				fill: #43fffd;
			}
			.st0 {
				fill: $charcoal; 
			}
			&:hover {
				@include transform(scale(1.1));
			}
		}
	}
	.col-sm-6 {
		text-align: center;
		@include breakpoint($bp-delta) {
			margin: 0 auto;
			display: table;
			float: none;
		}
	}
	nav {
		&.primary {
			float: left;
			width: 100%;
			display: block;
			margin-bottom: 25px;
			@include breakpoint($bp-delta) {
				margin-bottom: 50px;
			}
			@include breakpoint($bp-bobby) {
				margin-bottom: 85px;
			}
			ul {
				margin: 0;
				display: table;
				float: none;
				width: 100%;
				@include breakpoint($bp-bravo) {
					max-width: 550px;
					margin: 0 auto 25px;
				}
				@include breakpoint($bp-delta) {
					max-width: 100%;
					margin: 0 0 25px;
				}
				li {
					text-align: center;
					font-family: $headline-font;
					font-weight: 700;
					padding: 10px 0;
					//margin-right: 20px;
					@include breakpoint($bp-bravo) {
						margin-right: 20px;
						&:last-child {
							margin-right: 0;
						}
					}
					@include breakpoint($bp-delta) {
						font-weight: 700;
						padding-top: 0;
						padding-bottom: 0;
						margin: 0 35px 0 0;
					}
					&.home {
						display: block;
					}
					a {
						font-size: 12px;
						letter-spacing: 0.08em;
						color: $charcoal;
						text-transform: uppercase;
						font-weight: 700;
						@include breakpoint($bp-delta) {
							font-size: 12px;
						}
						@include breakpoint($bp-bobby) {
							font-size: 12px;
						}
					}
				}
			}
		}
	}
	.social {
		margin-top: 0!important;
		margin-bottom: 40px;
		@include breakpoint($bp-delta) {
			margin-top: 0;
			margin-bottom: 80px;
		}
		&.links {
			margin-top: 0;
			float: left;
			width: 100%;
			ul {
				display: table;
				float: none;
				margin: 0 auto;
				@include breakpoint($bp-delta) {
					float: right;
					width: auto;
					margin: 0;
					li {
						&:last-child a {
							padding-right: 0;
						}
					}
				}
				li {
					a {
						color: $navy;
						&:hover {
							color: $pink;
						}
						i {							
							font-size: 22px;
							@include breakpoint($bp-delta) {
								font-size: 18px;
							}
						}
					}
				}
			}
		}
	}
}

.footer-meta {
	float: left;
	width: 100%;
	.copyrights {
		margin: 0 0 0.25em 0;
		text-align: center;
		float: left;
		width: 100%;
		font-family: $primary-font;
		font-weight: 400;
		@include breakpoint($bp-delta) {
			text-align: right;
		}
		span {
			margin: 0;
		}
		a {
			color: $charcoal;
			text-decoration: underline;
			margin-left: 10px;
			&:hover {
				color: $teal;
			}
		}
	}
	span {
		color: $charcoal;
		font-family: $primary-font;
		font-weight: 400;
		float: left;
		width: 100%;
		display: block;
		font-size: 12px;
		&.site-by {
			margin-top: 10px;
			text-align: center;
			font-weight: 400;
			color: #999999;
			margin-top: 0;
			@include breakpoint($bp-delta) {
				text-align: right;
			}
			a {
				color: $grey;
				&:hover {
					color: $secondary;
				}
			}
		}
	}
}