.media-page {
	.featured-content {
		padding-bottom: 60px;
		@include breakpoint($bp-bobby) {
			padding-bottom: 120px;
		}
	}
	.inquiries {
		float: left;
		width: 100%;
		padding: 85px 0;
		@include breakpoint($bp-charlie) {
			padding: 120px 0;
		}
		@include breakpoint($bp-bobby) {
			padding: 160px 0;
		}
		.container {
			max-width: 680px;
			width: 100%;
		}
		h2 {
			text-align: center;
			margin-bottom: 25px;
		}
		.info {
			margin-bottom: 45px;
			p {
				margin: 0;
				font-size: 23px;
				text-align: center;
				line-height: 165%;
				a {
					color: $secondary;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
		.all-buttons {
			width: 100%;
			display: table;
			text-align: center;
			margin-bottom: 45px;
			.btn {
				display: inline-block;
				margin: 0 10px 10px;
				min-width: 270px;
				max-width: 100%;
				&:hover {
					border: 2px solid $secondary;
					background: $secondary;
					color: #fff;
				}
				&:first-of-type {
					background: $primary;
					&:hover {
						border: 2px solid $secondary;
						background: $secondary;
						color: #fff;
					}
				}
			}
		}
		.social {
			float: left;
			width: 100%;
			text-align: center;
			ul {
				float: none;
				width: auto;
				display: inline-block;
				li {
					display: inline-block;
					a {
						padding: 0 12px;
						font-size: 26px;
						@include breakpoint($bp-charlie) {
							font-size: 32px;
							padding: 0 18px;
						}
					}
				}
			}
		}
	}
	.media {
		float: left;
		width: 100%;
		padding-top: 0;
    position: relative;

    &:before {
      content: '';
      background-color: $primary;
      width: 36px;
      height: 2px;
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);

      @include breakpoint($bp-bobby) {
        top: -40px;
      }
    }

		.container {
			max-width: (1090px + 160px);
			width: 100%;
			h3 {
				margin-bottom: 30px;
				text-align: center;
				@include breakpoint($bp-charlie) {
					margin-bottom: 50px;
					text-align: center;
				}
			}
		}
		.media-grid {
			float: left;
			width: 100%;
			overflow: hidden;
			.single-item {
				float: left;
				width: 100%;
				height: 350px;
				background-size: cover!important;
				background-position: 50% 50%!important;
				position: relative;
				@include hover-fade();
				overflow: hidden;
				@include breakpoint(700px) {
					width: calc(100%/2);
					height: 440px;
				}
				@include breakpoint($bp-delta) {
					width: calc(100%/3);
				}
				a.full-link {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 999;
				}
				.cd-modal-action {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 9999!important;
				}
				a.btn {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 10;
					border-radius: 0;
					padding: 0;
					margin: 0;
					border: none!important;
				}
				&:after {
					position: absolute;
					content: '';
					bottom: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
					background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
					background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,1) 100%);
					opacity: 0.75;
					z-index: 2;
					@include hover-fade();
				}
				&:hover {
					&:after {
						height: 500%;
						opacity: 0.70;
					}
					.video-overlay, .picture-overlay {
						.info {
							visibility: visible;
							opacity: 1;
							max-height: 200px;
						}
					}
				}
				.video-overlay {
					position: absolute;
					top: 50%;
					@include transform(translateY(-50%));
					left: 0;
					right: 0;
					margin: 0 auto;
					z-index: 5;
					@include hover-fade();
					max-width: 335px;
					width: 100%;
					padding: 15px;
					.info {
						@include hover-fade();
						visibility: hidden;
						max-height: 0;
						opacity: 0;
					}
					h5 {
						margin-top: 25px;
						text-align: center;
						margin-bottom: 0;
						color: #fff;
						@include hover-fade();
					}
					p {
						text-align: center;
						text-align: center;
						margin-top: 15px;
						margin-bottom: 0;
						font-size: 18px;
						color: #ffffff;
						@include hover-fade();
					}
				}
				.picture-overlay {
					position: absolute;
					top: 50%;
					@include transform(translateY(-50%));
					left: 0;
					right: 0;
					margin: 0 auto;
					z-index: 5;
					@include hover-fade();
					max-width: 335px;
					width: 100%;
					padding: 15px;
					.info {
						@include hover-fade();
						max-height: 0;
						opacity: 0;
					}
					h5 {
						margin-top: 25px;
						text-align: center;
						margin-bottom: 0;
						color: #fff;
						@include hover-fade();
					}
					p {
						text-align: center;
						text-align: center;
						margin-top: 15px;
						margin-bottom: 0;
						font-size: 18px;
						color: #ffffff;
						@include hover-fade();
					}
				}
				.video-button {
					position: relative;
					width: 75px;
					height: 75px;
					cursor: pointer;
					margin: 0 auto;
					@include hover-fade();

          &:not(.download) {
            background-color: #000;
            border-radius: 100%;
            box-shadow: inset 0 0 2px $primary;
          }

					svg {
						width: 75px;
						height: 75px;
						@include hover-fade();
						.st0 {
							fill: $primary;
						}
					}
				}
			}
		}
	}
	.news {
		float: left;
		width: 100%;
		padding: 60px 0;
		@include breakpoint($bp-bobby) {
			padding: 120px 0;
		}
		.container {
			max-width: 1000px;
			width: 100%;
			margin: 0 auto;

			.headline {
				h3 {
					margin: 0;
          text-align: center;
				}
			}

      .article-filters {
        text-align: center;
        margin: 20px 0 40px;
      }

      .article-filter {
        background-color: transparent;
        margin: 0 15px;
        padding: 18px 20px;
        border: none;
        outline: none;
        position: relative;
        text-transform: uppercase;
        font-family: $headline-font;
        font-weight: 800;
        font-size: 14px;
        letter-spacing: 0.07em;
        color: #999;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:after {
          content: '';
          background-color: $primary;
          width: 36px;
          height: 2px;
          position: absolute;
          bottom: 0;
          left: 50%;
          opacity: 0;
          transform-origin: center;
          transform: translateX(-50%) scale3d(0, 1, 1);
          transition: all 0.2s ease-in-out;
        }

        &:hover,
        &.is-active {
          color: $charcoal;
        }

        &:hover:after,
        &.is-active:after {
          opacity: 1;
          transform: translateX(-50%) scale3d(1, 1, 1);
        }
      }

      .articles-more {
        background-color: $primary;
        float: none;
        margin: 0 auto;

        &:hover {
          background-color: $charcoal;
          border-color: $charcoal;
          color: $primary;
        }
      }

      .news-articles {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 40px;
      }

      .single-entry {
        float: none;
        width: 100%;
        padding: 30px 10px;
        border-bottom: 2px solid #cccccc;
        position: relative;

        @include breakpoint($bp-bobby) {
          width: 46%;
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          transform-origin: center;
          transform: scaleX(1.05);
          transition: all 0.3s ease-in-out;

          @include breakpoint($bp-bobby) {
            transform: scaleX(1.1);
          }
        }

        &:hover {
          z-index: 2;
        }

        &:hover:after {
          background-color: #fff;
          top: -2px;
          bottom: -2px;
          box-shadow: 6px 6px 60px 0 rgba(0, 0, 0, 0.15);
        }

        .single-entry__link {
          position: absolute;
          z-index: 3;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          cursor: pointer;
        }

        .single-entry__op-ed {
          position: absolute;
          bottom: 0;
          right: 0;
          transform-origin: bottom left;
          transform: rotate(-90deg) translate(-10px, 120px);
          font-family: $headline-font;
          font-weight: 700;
          font-size: 11px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: $charcoal;

          @include breakpoint($bp-bobby) {
            transform: rotate(-90deg) translate(-10px, 128px);
          }

          svg {
            fill: $primary;
            width: 16px;
            height: 14px;
            margin-right: 3px;
            transform: translateY(-2px);
          }
        }

        .single-entry__content {
          position: relative;
          z-index: 2;
        }

        &.addBg {
          border-bottom: 0;

          &:after {
            background-color: #eee;
            top: -2px;
            bottom: 6px;
          }

          .meta {
            color: #000;

            span.author {
              color: #989898;
            }
          }
        }

        .meta {
          margin-bottom: 8px;
          font-family: $headline-font;
          font-weight: 500;
          font-size: 14px;
          color: #999999;
          span.publisher {
            margin-right: 15px;
            text-transform: uppercase;
            margin-left: 0;
            display: inline-block;
            margin-bottom: 10px;
          }
          span.author {
            display: block;
            font-size: 13px;
          }
        }
        h5 {
          margin-bottom: 25px;
          margin-right: 25px;
        }
        .read-more {
          a {
            font-family: $headline-font;
            font-size: 14px;
            font-weight: 700;
            color: #4bc8e0;

            &:hover {
              color: $secondary;
            }

            svg {
              width: 13px;
              height: 13px;
              margin-left: 5px;
              transform: translateY(-1px);
            }
          }
        }
      }
			.accordion {
			   dt, dd {
			      padding: 0;
			      &:last-of-type {
			      }
			      a {
			        display: block;
			        color: black;
			        font-weight: bold;
			      }
			   }
			  dd {
			  		overflow: hidden;
			  		width: 100%;
			  		padding-bottom: 10px;
			  }
			  dt {
			  	border-top: 2px solid #cccccc;
			  	position: relative;
			  	.icon {
			  		position: absolute;
			  		width: 40px;
			  		right: 0;
			  		top: 37px;
			  		svg {
			  			width: 100%;
			  			.st0{fill:#43FFFD;}
			  			.st1{fill:#0B181E;}
			  			.plus {
			  				@include transition(all 0.2s ease);
			  			}
			  		}
			  	}
			  	a {
			  		padding: 35px 0;
			  		&.active {
			  			.icon svg .plus {
			  				opacity: 0;
			  			}
			  		}
			  		h4 {
			  			max-width: 85%;
			  			margin: 0;
			  			padding: 0;
			  		}
			  	}
			  }
			}
		}
	}
}
